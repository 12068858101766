import { createRouter, createWebHistory } from "vue-router";
// import { auth } from "@/firebaseConfig";
import SignUp from "../views/app/SignUp.vue";
import VerifyEmail from "../views/app/VerifyEmail.vue";
import ConfirmEmail from "../views/app/ConfirmEmail.vue";
import Login from "../views/app/Login.vue";
import Forgot from "../views/app/Forgot.vue";
import LinkSent from "../views/app/LinkSent.vue";
import UserManage from "../views/app/UserManage.vue";
import AddUser from "../views/app/AddUser.vue";
import Welcome from "../views/app/Welcome.vue";
import AccountInfo from "../views/app/AccountInfo.vue";
import Bulk from "../views/app/Bulk.vue";
import UploadExisting from "../views/app/UploadExisting.vue";
import Admin from "../views/app/Admin";
import Purchase from "../views/app/Purchase";
import Export from "../views/app/Export";
import Card from "../views/app/Card";
// import Subscriptionagreement from "../views/app/Subscriptionagreement";
// import Subscriptionagreementpopup from "../views/app/Subscriptionagreementpopup";
import FAQs from "../views/app/FAQs.vue";
import Terms from "../views/app/Terms.vue";
import Plans from "../views/app/Plans";
import RefundPolicy from "../views/app/RefundPolicy";
import PrivacyPolicy from "../views/app/PrivacyPolicy";
import Contact from "../views/app/Contact";
import GetApi from "../views/app/GetApi";
import TroubleShooting from "../views/app/TroubleShooting";
import ApiDoc from "../views/app/ApiDoc";
// import Updatecard from "../views/app/Updatecard.vue";
import Fileinfo from "../views/app/Fileinfo.vue";

import AppFrontend from "../views/app/AppFrontend"
import ResetPassword from "../views/app/ResetPassword"




const requireAuth = (to, from, next) => {
  // let user = auth.currentUser;
  let localUser = JSON.parse(localStorage.getItem('lls-userinfo'));
  if (localUser?.token) {
    next();
  } else if (localUser?.admin_token) {
    next({ name: "Admin" })
  } else {
    next({ name: "Login" });
  }

};

const checkAuthed = (to, from, next) => {
  let localUser = JSON.parse(localStorage.getItem('lls-userinfo'));
  if (localUser?.token) {
    next({ name: "AccountInfo" });
  } else if (localUser?.admin_token) {
    next({ name: "Admin" })
  } else {
    next();
  }
};

const adminAuthed = (to, from, next) => {
  let localUser = JSON.parse(localStorage.getItem('lls-userinfo'));
  if (localUser?.admin_token) {
    next();
  } else {
    next('/');
  }
};

const routes = [

  {
    path:"/",
    name: "AppFrontend",
    component: AppFrontend,
    children: [
      {
        path: '',
        redirect: Welcome,
        name:"Welcome"
      },
      {
        path: "/login",
        name: "Login",
        component: Login,
        beforeEnter: checkAuthed,
      },
      {
        path: "/forgot",
        name: "Forgot",
        component: Forgot,
        beforeEnter: checkAuthed,
      },

      {
        path: "/resetPassword",
        name: "ResetPassword",
        component: ResetPassword,
        beforeEnter: checkAuthed,
      },
    
      {
        path: "/linkSent",
        name: "LinkSent",
        component: LinkSent,
        beforeEnter: checkAuthed,
      },
      {
        path: "/signup",
        name: "SignUp",
        component: SignUp,
        beforeEnter: checkAuthed,
      },
      {
        path: "/verifyEmail",
        name: "VerifyEmail",
        component: VerifyEmail,
        beforeEnter: checkAuthed,
      },
      {
        path: "/confirmEmail",
        name: "ConfirmEmail",
        component: ConfirmEmail,
        beforeEnter: checkAuthed,
      },
      {
        path: "/",
        name: "Welcome",
        component: Welcome,
        // beforeEnter: requireAuth,
      },
      {
        path: "/home",
        name: "Home",
        component: Welcome,
        // beforeEnter: requireAuth,
      },
    
      {
        path: "/users",
        name: "Users",
        component: UserManage,
        beforeEnter: requireAuth,
      },
      {
        path: "/add",
        name: "AddUser",
        component: AddUser,
        beforeEnter: requireAuth,
      },
      {
        path: "/edit/:userId",
        name: "EditUser",
        component: AddUser,
        beforeEnter: requireAuth,
      },
      {
        path: "/accountInfo",
        name: "AccountInfo",
        component: AccountInfo,
        beforeEnter: requireAuth,
      },
      {
        path: "/bulk",
        name: "Bulk",
        component: Bulk,
        beforeEnter: requireAuth,
      },
      {
        path: "/faqs",
        name: "FAQs",
        component: FAQs,
        // beforeEnter: requireAuth,
      },
      {
        path: "/terms",
        name: "Terms",
        component: Terms,
        // beforeEnter: requireAuth,
      },
    
      //admin routes
      {
        path: "/admin",
        name: "Admin",
        component: Admin,
        beforeEnter: adminAuthed,
      },
      {
        path: "/purchase",
        name: "Purchase",
        component: Purchase,
        beforeEnter: requireAuth,
      },
    
      {
        path: "/export",
        name: "Export",
        component: Export,
        beforeEnter: requireAuth,
      },

      
      {
        path: "/uploadexisting",
        name: "UploadExisting",
        component: UploadExisting,
        beforeEnter: requireAuth,
      },
    
      {
        path: "/card",
        name: "Card",
        component: Card,
        beforeEnter: requireAuth,
      },
    
      // {
      //   path: "/mysubscription",
      //   name: "Subscriptionagreement",
      //   component: Subscriptionagreement,
      //   beforeEnter: requireAuth,
      // },
      // {
      //   path: "/mysubscriptionpopup",
      //   name: "Subscriptionagreementpopup",
      //   component: Subscriptionagreementpopup,
      //   beforeEnter: requireAuth,
      // },
       {
        path: "/plans",
        name: "Plans",
        component: Plans,
      },
      {
        path: "/RefundPolicy",
        name: "RefundPolicy",
        component: RefundPolicy,
      },
      {
        path: "/privacypolicy",
        name: "PrivacyPolicy",
        component: PrivacyPolicy,
      },
    
      {
        path: "/contact",
        name: "contact",
        component: Contact,
      },
      {
        path: "/apidoc",
        name: "apidoc",
        component: ApiDoc,
        beforeEnter: requireAuth,
      },
      {
        path: "/getapi",
        name: "getapi",
        component: GetApi,
        beforeEnter: requireAuth,
      },
      {
        path: "/troubleshooting",
        name: "troubleshooting",
        component: TroubleShooting,
      },
      // {
      //   path: "/updatecard",
      //   name: "Updatecard",
      //   component: Updatecard,
      // },
      {
        path: "/fileinfo",
        name: "Fileinfo",
        component: Fileinfo,
      }
    ]
  },
 
];


const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
