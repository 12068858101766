<template>
  <div class="mt-0 main-content-pricing col-lg-12">
    <div class="pricingHeader col-lg-12 mb-3 mt-2">
      <picture>
        <source media="(min-width:768px)" class="w-100" srcset="@/assets/img/lls-pricing-option-bg.png">
        <img class="w-100" src="@/assets/img/lls-pricing-option-mobile-bg.png" alt="Flowers">
      </picture>
    </div>
    <div class="pricingBody col-lg-12">
      <div class="content ">
        <div class="payAsYouGoWrapper col-lg-12   ">
          <div class="payAsYouGo   d-lg-flex d-block justify-content-evenly">
            <div :class="i == 0 ? 'payElite' : i == 1 ? 'payPower' : 'payStandard'" class="  col-lg-4 mx-auto"
              v-for="items, i in postPaidPlans" :key="i">
              <div class="header">
                <p class="headerMain">{{ items.plan_name }}</p>
                <p class="headerSub">{{ items.plan_subtitle }}</p>
              </div>
              <div class="price">
                <p class="priceMain">
                  ${{ formatCredits(items.plan_amount) }}
                </p>
                <p class="priceSub">
                  {{ items.percent_off }}
                </p>
              </div>
              <p class="credits">{{ items.plan_credits_text }}</p>

              <button class="buyNow col-lg-12" @click="postPaidSelectedPlanIndex = `post${i}`; buyPlan(items)"
                :class="userInfo?.user_plan?.plan_reference == items.plan_reference ? 'current-plan-btn' : ''"
                :disabled="isPaymentProcessing || userInfo?.user_plan?.plan_reference == items.plan_reference">
                <span v-if="isPaymentProcessing && postPaidSelectedPlanIndex == `post${i}`">
                  <i class="fa fa-circle-o-notch fa-spin"></i>
                </span>
                <span v-else>
                  {{ userInfo?.user_plan?.plan_reference == items.plan_reference ? 'Current Plan' : 'Select Plan' }}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="miscDetailsWrapper col-lg-12  row justify-content-evenly">
        <div class="flatRate mb-3 col-lg-3">
          <div class="header">
            <div class="headerIcon">
              <img src='@/assets/img/moneySign.svg'>
            </div>
            <p class="headerTitle">
              No-Nonsense Pricing
            </p>
          </div>
          <p class="body">
            1 credit equals 1 phone number scrubbed. Save money by buying in bulk. Save time AND money by integrating with
            your CRM through Pay as you Go.
          </p>
        </div>
        <div class="flatRate mb-3 col-lg-3">
          <div class="header">
            <div class="headerIcon">
              <img src='@/assets/img/fastIcon.svg'>
            </div>
            <p class="headerTitle">
              Fast
            </p>
          </div>
          <p class="body">
            No waiting, get your results in seconds! Files are stored in your dashboard so you can access them anytime.
          </p>
        </div>
        <div class="flatRate mb-3 col-lg-3">
          <div class="header">
            <div class="headerIcon">
              <img src='@/assets/img/easyIcon.svg'>
            </div>
            <p class="headerTitle">
              Easy to Use
            </p>
          </div>
          <p class="body">
            Check a single number or upload a CSV file. We handle it through our smart CSV upload tool.
          </p>
        </div>
      </div>
    </div>
    <div class="pricingGrid">
    </div>
  </div>
  <div class="mt-0 main-content-how col-lg-12 row g-0 h-100" id="how">
    <div class="content col-lg-6 px-lg-5 px-2">
      <h1>How it works</h1>
      <p>Landline Scrubber helps take your business communication to the next level by sorting landline, mobile, and DNC
        numbers. Just upload your list and you’ll have your instant results returned in a CSV file ready to download.</p>
    </div>
    <div class="video col-lg-6">
      <!-- <img class="playIcon d-none" src='@/assets/img/playIcon.svg'> -->
      <img class="videoPlaceholder w-100 h-100" src='@/assets/img/video-placeholder.png'>
    </div>
  </div>
  <div class="mt-0 main-content-faq col-lg-12">
    <div class="contentWrapper ">
      <h1 class="mb-5">FAQ</h1>
      <p class="faqContent text-start col-lg-6 pb-5">
        Q: How accurate is the data? <br>
        A: Phone numbers are constantly changing so no tool can be 100% accurate, but we update our data regularly.
        <br><br>
        Q: Is there a specific format the CSV file needs to be in? <br>
        A: As long as you have the phone number included you can upload your file. Be sure to map the phone number column
        correctly during your upload.<br><br>
        Q: Is there a minimum purchase amount? <br>
        A: The minimum amount you can spend in one transaction is $5 which will provide roughly 2,850 credits.<br>
      </p>
    </div>
  </div>
  <section class="payment-confirm-modal">
    <button type="button" class="btn btn-primary d-none" data-bs-toggle="modal" id="openPaymentModal"
      data-bs-target="#exampleModal">
      modal
    </button>
    <div class="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl ">
        <div class="modal-content">
          <div class="modal-header border-0">
            <div class="modal-title " id="exampleModalLabel ">Payment Confirmation Alert
            </div>
            <div type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></div>
          </div>
          <div class="modal-body pt-0">
            <section class="pb-4  px-lg-4 px-2 ">
              <div class=" container-fluid  px-lg-0 mt-3  ">
                <div class="container-wrapper  notice-wrapper  py-2 px-3" style="min-height:unset;">
                  <div class="row justify-content-between align-items-center">
                    <div class="col-lg-12 col-12 ">
                      <div class="textwelcome   d-flex align-items-center g-0 pt-4 pb-4">
                        <div class="col-md-6 text-start mb-md-0 mb-3">
                          Are you sure you want to buy {{ selectedBuyPlan?.plan_name + ' ' +
                            selectedBuyPlan?.plan_subtitle }} ?
                        </div>
                        <div class="col-md-6 mb-md-0 mb-3">
                          <div class="form-group text-end">
                            <label class="d-inline-block me-2">Select Amount:</label>
                            <select class="form-select w-auto d-inline-block" style="min-width: 100px;"
                              v-model="selectedAmount" aria-label="Default select example">
                              <option selected value="">Select</option>
                              <template v-if="selectedBuyPlan?.plan_reference == '79541679412216'">
                                <option value="83">$83</option>
                                <option value="158">$158</option>
                                <option value="744">$744</option>
                                <option value="1400">$1400</option>
                              </template>
                              <template v-else>
                                <option value="5">$5</option>
                                <option value="10">$10</option>
                                <option value="50">$50</option>
                              </template>
                            </select>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div class="  text-end mt-4">
                <button type="button" id="closePaymentModal"
                  class="col-lg-2 addCreditBtn getplanbtn current-plan-btn me-3" data-bs-dismiss="modal"
                  :disabled="isPaymentProcessing">Cancel</button>
                <button type="button" class=" col-lg-2 addCreditBtn getplanbtn" data-bs-dismiss="modal"
                  :disabled="selectedAmount == '' || isPaymentProcessing">
                  <span v-if="selectedAmount == ''">Confirm</span>
                  <span v-else
                    @click="$router.push({ name: 'Card', query: { returnUrl: 'Plans', reference_code: selectedBuyPlan?.plan_reference, selectedAmount: selectedAmount } })">
                    Confirm
                  </span>
                </button>
              </div>

            </section>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="payment-confirm-modal">
    <button type="button" class="btn btn-primary d-none" data-bs-toggle="modal" id="openAccountModal"
      data-bs-target="#accountModal">
      modal
    </button>
    <div class="modal fade" id="accountModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl ">
        <div class="modal-content">
          <div class="modal-header border-0">
            <div class="modal-title " id="exampleModalLabel ">Account Confirmation Alert
            </div>
            <div type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></div>
          </div>
          <div class="modal-body pt-0">
            <section class="pb-4  px-lg-4 px-2 ">
              <div class=" container-fluid  px-lg-0 mt-3  ">
                <div class="container-wrapper  notice-wrapper  py-2 px-3" style="min-height:unset;">
                  <div class="row justify-content-between align-items-center">
                    <div class="col-lg-12 col-12 ">
                      <div class="textwelcome   d-flex align-items-center g-0 pt-4 pb-4">
                        <div class="col-12 text-start mb-md-0 mb-3">
                          please activate your account first
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div class="  text-end mt-4">
                <button type="button" id="closeAccountModal"
                  class="col-lg-2 addCreditBtn getplanbtn current-plan-btn me-3" data-bs-dismiss="modal"
                  :disabled="isPlanProcessing">Cancel</button>
                <button type="button" class=" col-lg-2 addCreditBtn getplanbtn" @click="pausePlan()"
                  :disabled="isPlanProcessing">
                  <span v-if="isPlanProcessing">
                    <i class="fa fa-circle-o-notch fa-spin"></i>
                  </span>
                  <span v-else>
                    Confirm
                  </span>
                </button>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Api from "@/Api";
export default {
  name: "Plans",

  props: ['userInformation'],
  emits: ['userInformation'],
  components: {
  },
  setup() {

  },
  data() {
    return {
      postPaidPlans: [],
      selectedBuyPlan: null,
      selectedAmount: '',
      postPaidSelectedPlanIndex: '',
      isPaymentProcessing: null,
      isPlanProcessing: false,
      payment_method_exists: false,
      payment_customer_exists: false,
      userInfo: null
    };
  },
  mounted() {
    this.userInfo = Api.getUserSession()
    Api.getPlans().then((res) => {
      // console.log(res.data)
      this.postPaidPlans = res.data.plans.filter((el) => el.plan_type == 'postpaid')
    })
  },
  methods: {
    formatCredits(number) {
      if (number && Number.isInteger(number)) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return number
      }
    },
    async buyPlan(item) {
      if (this.userInfo?.user_plan?.plan_reference == item.plan_reference) {
        return
      }
      this.payment_customer_exists = await Api.payment_customer_exists()
      this.payment_method_exists = await Api.payment_method_exists()

      this.userInfo = await Api.getUserSession()
      if (!this.userInfo) {
        this.$router.push({ name: 'SignUp' })
      } else if (this.userInfo?.user_plan_status !== '' && this.userInfo?.user_plan_status == 'paused') {
        document.getElementById('openAccountModal').click()
      }

      else if (this.payment_customer_exists) {
        if (!this.payment_method_exists) {
          if (item.plan_reference == '79541679412215' && item.plan_reference == '79541679412216') {
            this.selectedBuyPlan = item
            document.getElementById('openPaymentModal').click()
          } else {
            this.$router.push({ name: 'Card', query: { returnUrl: this.$route.name, reference_code: item.plan_reference } })
          }
        } else {
          this.$router.push({ name: 'Purchase', query: { plan: item.plan_reference } })
        }


      } else if (item.plan_reference == '79541679412215' && item.plan_reference == '79541679412216') {
        this.selectedBuyPlan = item
        document.getElementById('openPaymentModal').click()
      } else {
        this.$router.push({ name: 'Card', query: { returnUrl: this.$route.name, reference_code: item.plan_reference } })
      }
    },
    pausePlan() {
      this.isPlanProcessing = true;
      let obj = {
        status: this.userInfo?.user_plan?.plan_status == 'active' ? 'paused' : 'active'
      }
      Api.updatePurchaseStatus(obj).then((res) => {
        // console.log(res)
        this.$swal({
          title: 'Success',
          text: res.data.message,
          icon: 'success',
          showCancelButton: false,
          cancelButtonText: 'Cancel',
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn bg-gradient-success',
            cancelButton: 'btn bg-gradient-danger',
          }
        })
        Api.getUserInfo().then(async (res) => {
          if (res?.data?.auth_token) {
            await Api.saveUserSession(res?.data)
            this.userInfo = await Api.getUserSession();
            this.$emit('userInformation', this.userInfo)
          }
        }).catch((err) => {
          console.log('Error: ', err)
        })
      }).catch((err) => {
        console.log(err)
      }).finally(() => {
        this.isPlanProcessing = false
        setTimeout(() => {
          document.getElementById('closeAccountModal').click()


        }, 500);

      })
    },

    buyStandardAndPower() {
      let obj = {
        reference_code: this.selectedBuyPlan.plan_reference,
        amount: this.selectedAmount,
        currency: "usd",
      }
      this.isPaymentProcessing = true
      Api.buyPlan(obj).then((res) => {
        this.$swal({
          title: res.data.message,
          text: 'Your payment was successfull',
          icon: 'success',
          showCancelButton: false,
          cancelButtonText: 'Cancel',
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn bg-gradient-success',
            cancelButton: 'btn bg-gradient-danger',
          },
          buttonsStyling: false,
        })
        Api.getUserInfo().then(async (res) => {
          if (res?.data?.auth_token) {
            await Api.saveUserSession(res?.data)
            this.userInfo = await Api.getUserSession()
          }
        }).catch((err) => {
          console.log('Error: ', err)
        })
      }).catch((err) => {
        console.log('err', err)
      }).finally(() => {
        this.isPaymentProcessing = false;
        setTimeout(() => {

          document.getElementById('closePaymentModal').click()
        }, 500);
      })
    },
    buyPlanApi(obj) {
      this.isPaymentProcessing = true
      Api.buyPlan(obj).then((res) => {
        Api.getUserInfo().then(async (res) => {
          if (res?.data?.auth_token) {
            await Api.saveUserSession(res?.data)
            this.userInfo = await Api.getUserSession()
          }
        }).catch((err) => {
          console.log('Error: ', err)
        })
        this.$swal({
          title: res.data.message,
          text: 'Your payment was successfull',
          icon: 'success',
          showCancelButton: false,
          cancelButtonText: 'Cancel',
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn bg-gradient-success',
            cancelButton: 'btn bg-gradient-danger',
          },
          buttonsStyling: false,
        })
      }).catch((err) => {
        console.log(err)
      }).finally(() => {
        this.isPaymentProcessing = false
      })
    },
  }
};
</script>
<style>
@media only screen and (max-width:575px) {
  .pricingGrid {}

}
</style>

<style lang="less" scoped>
@import '@/assets/css/plans.less';
</style>