<template>
    <main class="text-start paymentbill-wrapper">
        <section class="pt-3 pb-lg-4 pb-3 px-sm-5 px-0">
            <div class="welcome-section container-fluid px-0">
                <div>
                    <div class="welcome-title my-3">Contact Us</div>

                </div>
            </div>
        </section>
        <section class="pb-5 px-sm-5 px-0">
            <div class=" container-fluid  px-0   ">
                <div class="container-wrapper  notice-wrapper">
                    <div class="row justify-content-between align-items-center">
                        <div class="col-lg-12 col-12 ">
                            <div class="textwelcome">
                                <span class="fw-bold">Note:</span>
                                Info: Use your Account email if you already have.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="pb-5 px-sm-5 px-0">
            <div class="container-fluid Billing-overview container-wrapper  border-top-primary">
                <form class="row justify-content-between payment-form" @submit.prevent="">
                    <div class="col-12 mb-4">
                        <div class="section-heading">Enter Contact Info</div>
                    </div>
                    <div class="col-lg-12 pe-lg-3 mb-4">
                        <div class="form-group Billing-overview-input position-relative">
                            <label class="label">Name</label>
                            <input type="text" placeholder="Enter Full Name" class="form-control rounded-0 px-3"
                                aria-describedby="inputGroupPrepend" required v-model="name" />
                            <span class="text-sm text-danger" v-if="showError && errors['name']">{{
                                errors['name'] }}</span>
                        </div>
                    </div>
                    <div class="col-lg-12 pe-lg-3 mb-4">
                        <div class="form-group Billing-overview-input position-relative">
                            <label class="label">Email</label>
                            <input type="email" placeholder="Email" class="form-control rounded-0 px-3"
                                aria-describedby="inputGroupPrepend" required v-model="email" />
                            <span class="text-sm text-danger" v-if="showError && errors['email']">{{
                                errors['email'] }}</span>
                        </div>
                    </div>
                    <div class="col-lg-12 pe-lg-3 mb-4">
                        <div class="form-group Billing-overview-input position-relative">
                            <label class="label">Subject</label>
                            <input type="text" placeholder="General Query" class="form-control rounded-0 px-3"
                                aria-describedby="inputGroupPrepend" required v-model="subject" />
                            <span class="text-sm text-danger" v-if="showError && errors['subject']">{{
                                errors['subject'] }}</span>
                        </div>
                    </div>
                    <div class="col-lg-12 pe-lg-3 mb-4">
                        <div class="form-group Billing-overview-input position-relative">
                            <label class="label">Message</label>
                            <textarea class="form-control px-3" id="textAreaExample1" rows="4" v-model="message"
                                placeholder="General Message"></textarea>
                            <span class="text-sm text-danger" v-if="showError && errors['message']">{{
                                errors['message'] }}</span>
                        </div>
                    </div>
                    <div class="mb-3">
                        <vue-recaptcha theme="light" size="normal" :tabindex="0" @widgetId="recaptchaWidget = $event"
                            @verify="callbackVerify($event)" @expired="callbackExpired()" @fail="callbackFail()" />
                        <span class="text-sm text-danger" v-if="showError && errors['isRecaptchaFullfill']">{{
                            errors['isRecaptchaFullfill'] }}</span>
                    </div>
                    <div class="col-lg-12 pe-lg-4 mb-5">
                        <div class="form-group">
                            <button type="submit" class="btn payment-btn mb-0 w-100" @click="sendMail()">
                                <span v-if="isProcessing">Sending... <i class="fa fa-circle-o-notch fa-spin"></i></span>
                                <span v-else>Send Message</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    </main>
</template>    
<script>
import getCountries from "@/composables/getCountries"
import { ref } from "vue";
import { db } from "@/firebaseConfig";
import moment from "moment";
import { VueRecaptcha, useRecaptcha } from "vue3-recaptcha-v2";
import EmailTemplate from "@/components/EmailTemplate"
import storage from "@/Storage";
export default {
    name: "Contact",
    props: ['userInformation'],
    emits: ['userInformation'],
    data() {
        return {
            name: '',
            email: '',
            subject: '',
            message: '',
            isFormUploading: false,
            formResponse: null,
            isProcessing: false,
            token: storage('token'),
            showError: false,
            errors: {}
        }
    },
    components: {
        VueRecaptcha
    },
    setup() {
        const country = ref('');
        // const { user } = getUser()
        const { countryListAlpha } = getCountries()
        // Reset Recaptcha
        const { resetRecaptcha } = useRecaptcha();
        const recaptchaWidget = ref(null);
        const isRecaptchaFullfill = ref(false)
        const callbackVerify = () => {
            isRecaptchaFullfill.value = true
            // console.log(response);
        };
        const callbackExpired = () => {
            isRecaptchaFullfill.value = false
        };
        const callbackFail = () => {
            // console.log("fail");
        };
        // Reset Recaptcha action
        const actionReset = () => {
            isRecaptchaFullfill.value = false
            resetRecaptcha(recaptchaWidget.value);
        };
        return {
            country,
            countryListAlpha,
            recaptchaWidget,
            isRecaptchaFullfill,
            callbackExpired,
            callbackVerify,
            callbackFail,
            actionReset,
        }
    },
    methods: {
        checkFormValid() {
            
            this.errors = {}
            if (this.name == '') {
                this.errors['name'] = "Name is required"
            }
            if (this.email == '') {
                this.errors['email'] = "Email is required"
            }
            if (this.subject == '') {
                this.errors['subject'] = "Subject is required"
            }
            if (this.message == '') {
                this.errors['message'] = "Message is required"
            }
            if (this.isRecaptchaFullfill == false) {
                this.errors['isRecaptchaFullfill'] = "Recaptcha is required"
            }
            return true
        },
        recaptchaCallback() {
            this.isRecaptchaFullfill = true;
        },
        async sendMail() {
            this.showError = true;
            this.checkFormValid()
            if (Object.keys(this.errors).length > 0) {
                return
            }
            this.isProcessing = true;
            let userAgent = window.navigator.userAgent
            let platform = window.navigator.platform
            let macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
            let windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
            let iosPlatforms = ['iPhone', 'iPad', 'iPod']
            let os = null;
            let browser = null;

            if (macosPlatforms.indexOf(platform) !== -1) {
                os = 'Mac OS';
            } else if (iosPlatforms.indexOf(platform) !== -1) {
                os = 'iOS';
            } else if (windowsPlatforms.indexOf(platform) !== -1) {
                os = 'Windows';
            } else if (/Android/.test(userAgent)) {
                os = 'Android';
            } else if (!os && /Linux/.test(platform)) {
                os = 'Linux';
            }

            if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
                browser = 'Opera';
            } else if (navigator.userAgent.indexOf("Edg") != -1) {
                browser = 'Edge';
            } else if (navigator.userAgent.indexOf("Chrome") != -1) {
                browser = 'Chrome';
            } else if (navigator.userAgent.indexOf("Safari") != -1) {
                browser = 'Safari';
            } else if (navigator.userAgent.indexOf("Firefox") != -1) {
                browser = 'Firefox';
            } else if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true)) {
                browser = 'IE';
            } else {
                browser = 'unknown';
            }

            let template = ` 
                <div style="padding-bottom: 20px; font-family: Arial, Helvetica, sans-serif; font-size: 15px">
                  <div style="padding-bottom: 20px;">
                    <table style="width:100%;  padding-bottom: 20px;">
                      <tbody>
                        <tr >
                          <td style="font-weight: bold;padding-bottom: 20px;" colspan="2">${this.subject} </td>                        
                        </tr>
                        <tr >
                          <td style="font-weight: bold; width:90px;">From: </td>
                          <td>${this.email}</td>
                        </tr>
                        <tr >
                          <td style="font-weight: bold; width:90px;  padding-top: 12px;">Name: </td>
                          <td style="padding-top: 12px;">${this.name}</td>
                        </tr>
                        <tr>
                          <td style="font-weight: bold;  padding-top: 12px; vertical-align: initial; width:70px;">Message: </td>
                          <td style="padding-top: 12px;">${this.message}</td>
                        </tr>
                      </tbody>
                   </table>
                  </div>
                </div>`;
            const { layout } = EmailTemplate();
            let emailTemplate = layout
            if (emailTemplate.includes('[CONTENT]')) {
                emailTemplate = emailTemplate.replace('[CONTENT]', template)
            }
            let to = process.env.VUE_APP_EMAIL;
            let payload = {
                from: process.env.VUE_APP_NO_REPLY_EMAIL,
                to: to,
                message: {
                    subject: this.subject,
                    text: this.message,
                    html: emailTemplate,
                },
                timestamp: moment().valueOf(),
                date: moment().format('L'),
                month: moment().format('MMMM'),
                year: moment().format('YYYY'),
                os: os,
                browser: browser
            }
            await db.collection('mail').add(payload).then(() => {
                this.name = '';
                this.email = '';
                this.subject = '';
                this.message = '';
                this.isRecaptchaFullfill = false;
                this.showError = false
                this.actionReset()
                this.$swal({
                    title: 'Success',
                    text: 'Message sent successfully',
                    icon: 'success',
                    showCancelButton: false,
                    cancelButtonText: 'Cancel',
                    confirmButtonText: 'Close',
                    customClass: {
                        confirmButton: 'btn bg-gradient-success',
                        cancelButton: 'btn bg-gradient-danger',
                    },
                    buttonsStyling: false,
                })
            }).catch((error) => {
                console.error(`Error: `, error);
                return error;
            }).finally(() => {
                this.isProcessing = false
            })
        }
    },
    watch: {
        cardDetails() {
            console.log('cardDetails', this.cardDetails)
            let cardData = this.cardDetails.split('/');
            this.exp_month = cardData[0] ? cardData[0] : '';
            this.exp_year = cardData[1] ? cardData[1] : '';
            this.cvc = cardData[2] ? cardData[2] : '';
        },
        isRecaptchaFullfill() {
            this.checkFormValid()
        },
        name() {
            this.checkFormValid()
        },
        email() {
            this.checkFormValid()
        },
        subject() {
            this.checkFormValid()
        },
        message() {
            this.checkFormValid()
        },

    },
}

</script>
<style lang="less" scoped>
@import '@/assets/css/admin.less';
</style>