
    let layout = `
        <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
        <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
        <head>
            <!--[if gte mso 9]>
            <xml>
                <o:OfficeDocumentSettings>
                <o:AllowPNG />
                <o:PixelsPerInch>96</o:PixelsPerInch>
                </o:OfficeDocumentSettings>
            </xml>
            <![endif]-->
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
            <!--[if !mso]><!-- -->
            <meta http-equiv="X-UA-Compatible" content="IE=edge" />
            <!--<![endif]-->
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <!--[if (gte mso 9)|(IE)]>
            <style type="text/css">
                table {
                border-collapse: collapse;
                }
                body,
                table,
                td,
                h1,
                h2,
                h3,
                h4,
                a,
                div {
                font-family: Arial, Helvetica, sans-serif !important;
                }
            </style>
            <![endif]-->
            <title>Landline Scrubber Email Template</title>
            <style type="text/css">
            html {
                width: 100%;
            }
            body {
                margin: 0;
                padding: 0;
                width: 100% !important;
                -webkit-text-size-adjust: none;
                -ms-text-size-adjust: none;
                background-color: #f2f1fa;
            } /* Force Hotmail to display emails at full width */
            table td {
                border-collapse: collapse;
            }
            table th {
                margin: 0 !important;
                padding: 0 !important;
                font-weight: normal;
            }
            table {
                border-collapse: collapse;
                mso-table-lspace: 0pt;
                mso-table-rspace: 0pt;
            }
            img {
                vertical-align: top !important;
                border: 0 !important;
                outline: none !important;
            }
            p {
                margin: 0 !important;
                padding: 0 !important;
            }
            a {
                text-decoration: none !important;
            }
            /* iOS BLUE LINKS */
            a[x-apple-data-detectors] {
                color: inherit !important;
                text-decoration: none !important;
                font-size: inherit !important;
                font-family: inherit !important;
                font-weight: inherit !important;
                line-height: inherit !important;
            }
            .gmailfix {
                display: none;
                display: none !important;
            }
            </style>
        </head>
        <body>
            <div style="background: #f2f1fa; width: 100%; height: 100vh">
            <table style="border-collapse: collapse; margin: 0 auto; padding: 0; max-width: 600px; background: #f2f1fa" width="100%" cellspacing="0" cellpadding="0" border="0" align="center">
                <tbody>
                <tr>
                    <td style="text-align: center; padding: 40px" valign="center" align="center">
                    <a href="#">
                        <img src="https://firebasestorage.googleapis.com/v0/b/landline-scrubber-f1c18.appspot.com/o/logo.png?alt=media&token=88817962-395b-4c60-af58-2e4e101022ed" width="280px" />
                    </a>
                    </td>
                </tr>
                <tr>
                    <td valign="center" align="left">
                    <div style="text-align: left; margin: 0 15px 0 15px; padding: 40px; background-color: #ffffff; border-radius: 6px">
                        <div style="padding-bottom: 30px; font-size: 17px; font-family: Arial, Helvetica, sans-serif">
                        <b>Hi there!</b>
                        </div>
                        <div style="padding-bottom: 20px; font-family: Arial, Helvetica, sans-serif; font-size: 15px">
                        [CONTENT]
                        <p style="font-family: Arial, Helvetica, sans-serif; font-size: 15px">Thanks for using Landline Scrubber.</p>
                        </div>
                        <div style="padding-bottom: 10px; font-family: Arial, Helvetica, sans-serif; font-size: 15px">Kind regards, <br />The Landline Scrubber Team.</div>
                    </div>
                    </td>
                </tr>
                <tr>
                    <td style="font-size: 13px; text-align: center; padding: 20px; color: #6d6e7c; font-family: Arial, Helvetica, sans-serif" valign="center" align="center">
                    <p style="font-family: Arial, Helvetica, sans-serif; font-size: 13px">
                        Copyright &copy;
                        <a href="#" target="_blank" style="font-family: Arial, Helvetica, sans-serif; color: #3100ff">LandlineScrubber</a>.
                    </p>
                    </td>
                </tr>
                </tbody>
            </table>
            </div>
            <div class="gmailfix" style="white-space: nowrap; font: 15px courier; line-height: 0">
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            </div>
        </body>
        </html>
    `
   
const EmailTemplate = () => {
    return { layout }
  }

export default EmailTemplate