<template>
    <main class="text-start paymentbill-wrapper">
        <section class="pt-3 pb-lg-4 pb-3 px-lg-5 px-0">
            <div class="welcome-section container-fluid px-lg-0 px-2">
                <div>
                    <div class="welcome-title my-3"> Add Payment Method - Secure Payment Powered by Stripe</div>
                    <p class="welcome-Text text-darkblue">
                        Info: Please add your payment method before adding funds.
                    </p>
                </div>
            </div>
        </section>

        <section class="pb-5 px-lg-5 px-2">
            <div class=" container-fluid  px-lg-0   ">
                <div class="container-wrapper  notice-wrapper">
                    <div class="row justify-content-between align-items-center">
                        <div class="col-lg-12 col-12 ">
                            <div class="textwelcome">
                                <span class="fw-bold">Note:</span>
                                We charge $1 to confirm that the credit card details are valid. You will
                                receive 500 credits (worth $1.00) + 200 Credits (as a bonus!)
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- billing overview and Information -->
        <section class="pb-5 px-lg-5 px-2">
            <div class="container-fluid Billing-overview container-wrapper border-top-primary">
                <form id="payment-form" class="row justify-content-between payment-form">
                    <div class="col-12 mb-3">
                        <div class="section-label">USER BILLING SETTINGS</div>
                        <div class="section-heading">Enter Cardholder Info</div>
                    </div>
                    <div id="payment-element" class="mb-3">
                        <!-- Elements will create form elements here -->
                    </div>
                    <div class="col-lg-6 pe-lg-2">

                        <div class="form-group Billing-overview-input position-relative">
                            <label class="label">Cardholder's Name</label>
                            <input type="text" placeholder="Enter Full Name" class="form-control"
                                aria-describedby="inputGroupPrepend" v-model="name" :class="{ 'border-danger border-2': errors['name'] }" />
                                <span class="input-error text-danger" v-if="errors['name']">{{ errors['name']
                            }}</span>
                        </div>
                    </div>
                    <!-- <div class="col-lg-6 ps-lg-4 mb-3">

                        <div class="form-group Billing-overview-input position-relative">
                            <label class="label">Card number</label>
                            <input type="text" placeholder="Enter details" v-mask="'#### #### #### ####'"
                                class="form-control rounded-0" aria-describedby="inputGroupPrepend" v-model="number" />
                            <span class="input-error text-danger" v-if="showError && errors['number']">{{ errors['number']
                            }}</span>
                        </div>
                    </div>
                    <div class="col-lg-6 pe-lg-4 mb-lg-4 mb-3">
                        <div class="form-group Billing-overview-input">
                            <label class="label">Month/Year</label>
                            <input type="text" id="Card Number" v-mask="'##/####'" v-model="cardMMYY" placeholder="MM/YYYY"
                                class="form-control rounded-0" aria-describedby="inputGroupPrepend" />

                            <span class="input-error text-danger" v-if="showError && errors['cardMMYY']">{{ errors['cardMMYY']
                            }}</span>
                        </div>

                    </div>
                    <div class="col-lg-6 ps-lg-4 mb-lg-4 mb-3">
                        <div class="form-group Billing-overview-input">
                            <label class="label">CVC</label>
                            <input type="text" id="Card Number" v-mask="'####'" v-model="cvc" placeholder="CVC"
                                class="form-control rounded-0" aria-describedby="inputGroupPrepend" />
                            <span class="input-error text-danger" v-if="showError && errors['cvc']">{{ errors['cvc'] }}</span>
                        </div>
                    </div> -->
                    <div class="col-lg-6 ps-lg-2">
                        <div class="form-group Billing-overview-input">
                            <label class="label">Address line 1</label>
                            <input type="text" id="" placeholder="Address line 1" class="form-control "
                                aria-describedby="inputGroupPrepend" v-model="line1"
                                :class="{ 'border-danger border-2': errors['line1'] }" />
                            <span class="input-error text-danger" v-if="errors['line1']">{{ errors['line1']
                            }}</span>
                        </div>
                    </div>
                    <div class="col-lg-6 pe-lg-2">
                        <div class="form-group Billing-overview-input">
                            <label class="label">Address line 2</label>
                            <input type="text" id="Address line " placeholder="Address line 2" class="form-control"
                                aria-describedby="inputGroupPrepend" v-model="line2" />
                        </div>
                    </div>
                    <div class="col-lg-6 ps-lg-2">
                        <div class="form-group Billing-overview-input">
                            <label class="label">City</label>
                            <input type="text" id="City" placeholder="City" class="form-control "
                                aria-describedby="inputGroupPrepend" v-model="city"
                                :class="{ 'border-danger border-2': errors['city'] }" />
                            <span class="input-error text-danger" v-if="errors['city']">{{ errors['city'] }}</span>
                        </div>
                    </div>
                    <!-- <div class="col-lg-6 pe-lg-4">
                        <div class="form-group Billing-overview-input">
                            <label class="label">ZIP / Postal Code</label>
                            <input type="text" id="ZIP / Postal Code" placeholder="ZIP / Postal Code" class="form-control"
                                aria-describedby="inputGroupPrepend" v-model="postal_code"
                                :class="{ 'border-danger border-2': errors['postal_code'] }" />
                            <span class="input-error text-danger" v-if="errors['postal_code']">{{
                                errors['postal_code'] }}</span>
                        </div>
                    </div> -->


                    
                    <div class="col-lg-6 pe-lg-2">
                        <div class="form-group Billing-overview-input">
                            <label class="label">State</label>
                            <input type="text" id="State" placeholder="State" class="form-control"
                                aria-describedby="inputGroupPrepend" v-model="state"
                                :class="{ 'border-danger border-2': errors['state'] }" />
                            <span class="input-error text-danger" v-if="errors['state']">{{ errors['state']
                            }}</span>
                        </div>
                    </div>
                    <!-- <div class="col-lg-6 ps-lg-4 mb-3   ">
                        <div class="form-group Billing-overview-input">
                            <label class="label">Country</label>
                            <select class="form-select text-grey" title="Pick One" v-model="country"
                                :class="{ 'border-danger border-2': errors['country'] }">
                                <option selected value="">Select Country</option>
                                <option :value="item.code" v-for="item, index in countryListAlpha" :key="index">
                                    {{ item.name }}</option>
                            </select>
                            <span class="input-error text-danger" v-if="errors['country']">{{ errors['country']
                            }}</span>
                        </div>
                    </div> -->
                    <div class="col-lg-12 pe-lg-2 mb-3">
                        <div class="form-group col-lg-6 col-12">
                            <button id="submit" type="submit" class="btn payment-btn mb-0 w-100">
                                <span v-if="isFormUploading">Processing... <i
                                        class="fa fa-circle-o-notch fa-spin"></i></span>
                                <span v-else>Update</span>
                            </button>

                            <div v-if="formResponse">
                                {{ formResponse }}
                            </div>
                            <div id="error-message">
                                <!-- Display error message to your customers here -->
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>

    </main>
</template>
    
    
<script>
import getCountries from "@/composables/getCountries"
import { ref } from "vue";
import getUser from "@/composables/getUser";
import Api from "@/Api";
import storage from "@/Storage";
// import moment from "moment";
export default {
    name: "Card",
    data() {
        return {
            cardMMYY: '',
            number: '',
            exp_month: '',
            exp_year: '',
            cvc: '',
            name: '',
            line1: '',
            line2: '',
            postal_code: '',
            city: '',
            state: '',
            isFormUploading: false,
            formResponse: null,
            showError: false,
            errors: {},
            payment_customer_exists: false,
            payment_method_exists: false,
            user_email: storage('user_login_email')
        }
    },
    setup() {
        const country = ref('');
        const { user } = getUser()
        const email = user?.value?.email;
        const { countryListAlpha } = getCountries()

        return {
            country,
            countryListAlpha,
            email
        }
    },
    beforeMount() {
        // if(storage('payment_method_exists') == true) {
        //     this.$router.go(-1)
        // }

    },
    mounted() {
        let user = Api.getUserSession()
        if(user?.billing_info?.customer_address) {
            this.name = user?.user_name ? user.user_name : '';
            this.line1 = user?.billing_info?.customer_address?.line1 ? user.billing_info.customer_address.line1 : '';
            this.line2 = user?.billing_info?.customer_address?.line2 ? user.billing_info.customer_address.line2 : '';
            this.city = user?.billing_info?.customer_address?.city ? user.billing_info.customer_address.city : '';
            this.state = user?.billing_info?.customer_address?.state ? user.billing_info.customer_address.state : '';
        }
        const stripe = window.Stripe(process.env.VUE_APP_STRIPE_SECRET);
        const options = {
            mode: 'payment',
            amount: 1099,
            currency: 'usd',
            appearance: {/*...*/ },
            paymentMethodCreation: 'manual',
            fields: {
                billingDetails: 'auto'
            }
        };

        const elements = stripe.elements(options);
        const paymentElement = elements.create('payment');
        paymentElement.mount('#payment-element');


        const form = document.getElementById('payment-form');
        const submitBtn = document.getElementById('submit');

        const handleError = (error) => {
            console.log('error', error.message)
            // const messageContainer = document.querySelector('#error-message');
            // messageContainer.textContent = error.message;
            this.isFormUploading = false
        }

        form.addEventListener('submit', async (event) => {
            event.preventDefault();
            this.checkNameValid()
            this.checkLine1Valid()
            this.checkStateValid()
            this.checkCityValid()

            if (submitBtn.disabled) {
                return;
            }
            if(Object.keys(this.errors).length > 0) {
                return
            }
            this.isFormUploading = true;

            const { error: submitError } = await elements.submit();
            if (submitError) {
                handleError(submitError);
                return;
            }

            const { paymentMethod, error } = await stripe.createPaymentMethod({
                elements
            });

            if (error) {
                this.$swal({
                    title: 'Error',
                    text: error?.message ? error.message : 'You card was declined',
                    icon: 'error',
                    showCancelButton: false,
                    cancelButtonText: 'Cancel',
                    confirmButtonText: 'Close',
                    customClass: {
                        confirmButton: 'btn bg-gradient-success',
                        cancelButton: 'btn bg-gradient-danger',
                    }
                })
                this.isFormUploading = false;
                return
            }



            let payload = {
                city: this.city,
                country: this.country,
                line1: this.line1,
                line2: this.line2,
                customer_name: this.name,
                state: this.state,
                email: this.user_email,
                payment_method_id: paymentMethod.id,
            }
            if(this.$route.query.selectedAmount) {
                payload = {...payload, amount:this.$route.query.selectedAmount}
            }
            if (this.$route.query.reference_code) {
                payload['reference_code'] = this.$route.query.reference_code
            }
            Api.paymentSetup(payload).then((res) => {
                this.$swal({
                    title: 'Success',
                    text: res?.data?.message ? res.data.message : 'Payment Complete',
                    icon: 'success',
                    showCancelButton: false,
                    cancelButtonText: 'Cancel',
                    confirmButtonText: 'Close',
                    customClass: {
                        confirmButton: 'btn bg-gradient-success',
                        cancelButton: 'btn bg-gradient-danger',
                    }
                })
                this.$router.push({ name: 'AccountInfo' })
            }).catch((err) => {
                console.log('Err :- ', err)
            }).finally(() => {
                this.isFormUploading = false;
            })
        })

    },
    methods: {
        checkCityValid() {
            if (this.city == '') {
                this.errors['city'] = "City is required"
            } else {
                delete this.errors.city;
            }
        },
        checkNameValid() {
            if (this.name == '') {
                this.errors['name'] = "Name is required"
            } else {
                delete this.errors.name;
            }
        },
        checkStateValid() {
            if (this.state == '') {
                this.errors['state'] = "State is required"
            } else {
                delete this.errors.state;
            }
        },
        checkLine1Valid() {
            if (this.line1 == '') {
                this.errors['line1'] = "Line1 is required"
            } else {
                delete this.errors.line1;
            }
        },
        checkFormValid() {
            this.errors = {}
            // let currentYear = moment().format('yyyy')
            // let currentMonth = moment().format('MM')
            if (this.city == '') {
                this.errors['city'] = "City is required"
            }
            if (this.state == '') {
                this.errors['state'] = "State is required"
            }
            if (this.line1 == '') {
                this.errors['line1'] = "Line1 is required"
            }
            if (this.name == '') {
                this.errors['name'] = "Name is required"
            }
            // if (this.number == '') {
            //     this.errors['number'] = "Credit Card Number is required"
            // } else {
            //     if (this.number.split(" ").join("").length < 15) {
            //         this.errors['number'] = "Invalid Credit Card Number"
            //     }
            // }

            // if (this.name == '') {
            //     this.errors['name'] = "Name is required"
            // }

            // if (this.exp_year == '') {
            //     this.errors['cardMMYY'] = "Invalid Format. It must be MM/YYYY"
            // } else {
            //     if (this.exp_year.length !== 4) {
            //         this.errors['cardMMYY'] = "Invalid Format. It must be MM/YYYY"
            //     }
            //     if (parseInt(this.exp_year) < parseInt(currentYear)) {
            //         this.errors['cardMMYY'] = "Invalid Year"
            //     }
            // }

            // if (this.exp_month == '') {
            //     this.errors['cardMMYY'] = "Invalid Format. It must be MM/YYYY"
            // } else {
            //     if (this.exp_month.length !== 2) {
            //         this.errors['cardMMYY'] = "Invalid Format. It must be MM/YYYY"
            //     }
            //     if (parseInt(this.exp_month) > 12) {
            //         this.errors['cardMMYY'] = "Invalid Month"
            //     }
            // }

            // if (parseInt(this.exp_year) == parseInt(currentYear) && parseInt(this.exp_month) < parseInt(currentMonth)) {
            //     this.errors['cardMMYY'] = "Invalid Month"
            // }


            // if (this.cvc == '') {
            //     this.errors['cvc'] = "Cvc is required"
            // } else {
            //     if (this.cvc.length < 3) {
            //         this.errors['cvc'] = "Invalid Cvc"
            //     }
            // }


        },

        async sendPaymentMethod() {
            this.showError = true;
            this.checkFormValid()
            if (Object.keys(this.errors).length > 0) {
                return
            }

            this.isFormUploading = true
            Api.getUserInfo().then(async (res) => {
                if (res?.data?.auth_token) {
                    let payload = {
                        city: this.city,
                        country: this.country,
                        line1: this.line1,
                        line2: this.line2,
                        postal_code: this.postal_code,
                        state: this.state,
                        email: res?.data?.user_login_email,
                        customer_name: this.name,
                        number: this.number.split(" ").join(""),
                        exp_month: this.exp_month,
                        exp_year: this.exp_year,
                        cvc: this.cvc,
                    }

                    await Api.saveUserSession(res?.data)
                    this.addCard(payload)
                    // if (!res?.data?.payment_info_exists) {
                    //     this.addCard(payload)
                    // } else {
                    //     this.updateCard(payload)
                    // }
                }
            }).catch((err) => {
                console.log('Error: ', err)
            })



        },
        addCard(payload) {
            Api.paymentSetup(payload).then(() => {
                // console.log('res', res)
                this.$swal({
                    title: 'Success',
                    text: 'Card Added Successfully',
                    icon: 'success',
                    showCancelButton: false,
                    cancelButtonText: 'Cancel',
                    confirmButtonText: 'Close',
                    customClass: {
                        confirmButton: 'btn bg-gradient-success',
                        cancelButton: 'btn bg-gradient-danger',
                    }
                })


                Api.getUserInfo().then((res) => {
                    if (res?.data?.auth_token) {
                        Api.saveUserSession(res?.data)
                    }
                    // if (this.$route.query.returnUrl) {
                    //     this.$router.push({ name: this.$route.query.returnUrl })
                    // } 
                    // else {
                    this.$router.push({ name: 'AccountInfo' })
                    // }
                }).catch((err) => {
                    console.log('Error: ', err)
                })
            }).catch((errpr) => {
                console.log('err', errpr)
            }).finally(() => {
                this.isFormUploading = false
            })
        },
        updateCard(payload) {
            Api.updateUserCard(payload).then(() => {
                this.$swal({
                    title: 'Success',
                    text: 'Card Updated Successfully',
                    icon: 'success',
                    showCancelButton: false,
                    cancelButtonText: 'Cancel',
                    confirmButtonText: 'Close',
                    customClass: {
                        confirmButton: 'btn bg-gradient-success',
                        cancelButton: 'btn bg-gradient-danger',
                    }
                })


                Api.getUserInfo().then((res) => {
                    if (res?.data?.auth_token) {
                        Api.saveUserSession(res?.data)
                    } if (this.$route.query.returnUrl) {
                        this.$router.push({ name: this.$route.query.returnUrl })
                    } else {
                        this.$router.push({ name: 'AccountInfo' })
                    }
                }).catch((err) => {
                    console.log('Error: ', err)
                })
            }).catch((error) => {
                console.log('err', error)
            }).finally(() => {
                this.isFormUploading = false
            })
        }
    },
    watch: {
        // cardMMYY() {
        //     let cardData = this.cardMMYY.split('/');
        //     this.exp_month = cardData[0] ? cardData[0] : '';
        //     this.exp_year = cardData[1] ? cardData[1] : '';
        //     this.checkFormValid()
        // },
        // name() {
        //     this.checkFormValid()
        // },
        // number() {
        //     this.checkFormValid()
        // },
        // cvc() {
        //     this.checkFormValid()
        // },
        line1() {
            this.checkLine1Valid()
        },
        city() {
            this.checkCityValid()
        },
        state() {
            this.checkStateValid()
        },
        name() {
            this.checkNameValid()
        },

    }
}
</script>
<style lang="less" scoped>
@import '@/assets/css/admin.less';
</style>
<style>
#payment-form .form-control,
#payment-form .form-select {
    padding: 0.65rem !important;
}

#payment-form .input-error {
    font-size: 0.93rem;
}
</style>