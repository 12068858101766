<template>
  <main class="mt-0 main-content login">
    <section>
    <img src="@/assets/img/lls_welcome_logo.png" class="welcome-bg-logo" alt="" />
      <div class="container login-container col-lg-12 d-flex">
        <div class="loginWrapper">
          <div class='loginHeader'>
            <h1 class="mt-4">Log In</h1>
            <p>Sign in to your account using email and password provided during registration.</p>
          </div>

          <div class="row justify-content-center loginBody">
            <div class="col-lg-12 col-md-12 mt-2">
              <!-- <h5 class="text-dark text-center mt-2 mb-3">Sign in</h5> -->
              <form role="form" @submit.prevent="handleSubmit" class="text-start">
                <div class="mb-3">
                  <input id="email" type="email" class="form-control" placeholder="Email" v-model="email"  />
                  <span class="text-sm text-danger" v-if="showErrors && errors['email']">{{ errors['email']
                  }}</span>
                </div>

                <div class="mb-3">
                  <input id="password" type="password" class="form-control" placeholder="Password" v-model="password"
                     />
                    <span class="text-sm text-danger" v-if="showErrors && errors['password']">{{ errors['password']
                  }}</span>
                </div>
                <div class="form-check mb-2">
                  <input class="form-check-input" type="checkbox" v-model="rememberMe" id="flexCheckDefault">
                  <label class="form-check-label" for="flexCheckDefault">
                    Remember me
                  </label>
                </div>


                <div class="error text-sm text-danger mb-2">{{ error }}</div>

                <div class="forgot mb-4"><router-link to="/forgot">Forgot Password?</router-link></div>

                <div class="text-center">
                  <button class="submit-btn-login mb-3 btn" @click="Login"
                    :disabled="isProcessing">
                    <span v-if="isProcessing">
                      Logging in... <i class="fa fa-circle-o-notch fa-spin"></i>
                    </span>
                    <span v-else>
                      LOG IN
                    </span>
                  </button>
                </div>
              </form>

              <p class="mb-3  ">No Account? Get one now <router-link to="/signup">Sign up.</router-link></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
// import moment from 'moment';
import Api from "@/Api";
// import ArgonInput from "@/components/ArgonInput.vue";
// import ArgonSwitch from "@/components/ArgonSwitch.vue";
// import ArgonButton from "@/components/ArgonButton.vue";
// import ArgonAlert from "@/components/ArgonAlert.vue";
const body = document.getElementsByTagName("body")[0];
export default {
  name: "Login",
  components: {
    //   ArgonInput,
    //   ArgonSwitch,
    //   ArgonButton,
    // ArgonAlert,
  },


  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },

  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },

  setup() {

    const nextInputFocus = () => {
      document.querySelector('#password').focus();
    
    };
    
    const clickSignIn = () => {
      document.querySelector('.submit-btn-login').click();
    };

    return { nextInputFocus, clickSignIn };
  },

  data() {
  
    return {
      email: '',
      password: '',
      rememberMe: false,
      error: '',
      isProcessing: false,
      errors: {},
      showErrors :false,
  
}
  
  },

  methods: {
    checkFormValid() {
      this.errors = {}
      if (this.email == '') {
        this.errors['email'] = "Email is required"
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(this.email)) {
        this.errors['email'] = 'Invalid email address'
      }

      if (this.password == '') {
        this.errors['password'] = "Password is required"
      }
     
    },

    async saveSession() {
      await Api.getUserInfo().then(async (res) => {
        if (res?.data?.auth_token) {
          await Api.saveUserSession(res?.data)
        }
      }).catch((err) => {
        console.log('Error: ', err)
      })
    },
    handleSubmit() {

      // this.isProcessing = true;
      // let token 

      // var myHeaders = new Headers();
      // myHeaders.append("Authorization", "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdXRoX3Rva2VuIjoiNzk1NDE2Nzk0MDAyMTEiLCJpYXQiOjE2ODMwMzY4MzQsImV4cCI6MTY4MzA0NzYzNH0.2gz2TpNUro2xB9HZkj5Pu8vDx9ZQwpZjO34HOvUPlP8");

      // var raw = JSON.stringify({
      //   "le": this.email,
      //   "lp": this.password
      // });

      // var requestOptions = {
      //   method: 'POST',
      //   //headers: myHeaders,
      //   body: raw,
      //   redirect: 'follow'
      // };

      // fetch("https://api.landlinescrubber.com/login", requestOptions)
      //   .then(response => response.text())
      //   .then(async (res) => {
      //     console.log("Result: ", JSON.parse(res))
      //     this.$router.push({ name: 'AccountInfo' })
      //     await Api.saveUserSession(JSON.parse(res))
      //   })
      //   .catch((err) => {
      //     this.error = err.response.data.message
      //     console.log('error: ', err)
      //   }).finally(() => {
      //     this.isProcessing = false;
      //   })


      this.error = ''
      this.showErrors = true

      this.checkFormValid()
      if (Object.keys(this.errors).length > 0) {
        return
      }
      this.isProcessing = true
      let Obj = {
        le: this.email,
        lp: this.password,

      }
      Api.logIn(Obj).then(async (res) => {
        // console.log(res)
        let sesssionData = {}
        if (res.data.user.usertype == 'user') {
          sesssionData = {
            usertype: res.data.user.usertype,
            token: res.data.user.token
          }
          await Api.saveUserSession(sesssionData)
          this.saveSession()
          this.$router.push({ name: 'AccountInfo' })
        } else {
          sesssionData = {
            usertype: res.data.user.usertype,
            admin_token: res.data.user.token,
            token: res.data.user.token
          }
          await Api.saveUserSession(sesssionData)
          this.saveSession()
          this.$router.push({ name: 'Admin' })
        }
      }).catch((err) => {
        this.error = err.response.data.message
        console.log(err.response.data.message)

      }).finally(() => {

        this.isProcessing = false;

      })
    }
  },
  watch: {
      email() {
        this.checkFormValid()
      },
      password() {
        this.checkFormValid()
      },
    
    }
};
</script>

<style>



main .loginFooter .footerDetailsWrapper {
  color: #3100FF !important;
}

main .loginFooter .footerDetailsWrapper a {
  color: #3100FF !important;
  text-decoration: underline;
}

main .loginFooter .footerDetailsWrapper a:hover {
  text-decoration: none;
}
</style>
<style lang="less" scoped>
@import '@/assets/css/login.less';
</style>