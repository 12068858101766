import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./routes";
/* import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css"; */
import '@/assets/css/template.less'
import VueTilt from "vue-tilt.js";
import VueSweetalert2 from "vue-sweetalert2";
import ArgonDashboard from "./argon-dashboard";
import { auth } from "./firebaseConfig";
import { VueMaskDirective } from 'v-mask';
import VueRecaptcha from "vue3-recaptcha-v2";
import VuePapaParse from 'vue-papa-parse'
const vMaskV2 = VueMaskDirective;
const vMaskV3 = {
    beforeMount: vMaskV2.bind,
    updated: vMaskV2.componentUpdated,
    unmounted: vMaskV2.unbind
}

import VueScrollTo from 'vue-scrollto';

let appInstance;
auth.onAuthStateChanged(() => {
    if (!appInstance) {
        appInstance = createApp(App);
        appInstance.use(store);
        appInstance.use(VueRecaptcha, {
            siteKey: process.env.VUE_APP_RECAPTCHAA_SITE_KEY,
            alterDomain: false, // default: false
        })
        appInstance.use(router);
        appInstance.use(VuePapaParse);
        appInstance.use(VueTilt);
        appInstance.directive('mask', vMaskV3);
        appInstance.use(VueSweetalert2);
        appInstance.use(ArgonDashboard);
        appInstance.use(VueScrollTo);
        appInstance.mount("#app");
    }
});
