<template>
  <main class="mt-0 main-content text-start">
    <section class="pt-3 pb-sm-4 pb-3 px-sm-5 px-0">
      <div class="welcome-section container-fluid px-0">
        <div class="bulkHeader">
          <div class="welcome-title">Bulk Upload CSV File</div>
        </div>
      </div>
    </section>
    <template v-if="userInfo?.user_plan_status !== '' && userInfo?.user_plan_status == 'active'">
      <section class="pt-3 pb-sm-4 pb-3 px-sm-5 px-0" v-if="step == 1">
        <div class="container-wrapper  notice-wrapper">
          <div class="row justify-content-between align-items-center">
            <div class="col-lg-12 col-12 ">
              <b>Note:</b> Please ensure that your CSV File has a Header Row, otherwise the first row of data will be
              considered a header row and will not be processed.
            </div>
          </div>
        </div>
        <div class="container-fluid p-3  container-wrapper  border-top-primary mt-5">
          <div class="bulkBody ">
            <div class="dropzone-container col-lg-11" :key="selectedInputIndex">
              <input type="file" name="file" id="fileInput" class="hidden-input" @change="onSelectCsv($event)" ref="file"
                accept=".csv" />
              <div v-if="isDragging">Release to drop files here.</div>
              <div class="fileInputDesc d-flex justify-content-center flex-column align-items-center" v-else>
                <img src="@/assets/img/uploadIcon.svg"><br>
                <p class="mb-lg-5">Drag and Drop CSV File Here to Upload</p>
                <div class="text-center buttonWrapper w-100 cursor-pointer">
                  <div class="mx-auto selectfile position-relative"
                    v-on:click="$event.stopPropagation(); $refs.file.click();" style="z-index:999;">
                    Or select file
                  </div>
                </div>
              </div>
              <div class="preview-container d-flex justify-content-center mt-4 w-100" v-if="files.length"
                @click=" $event.stopPropagation()">
                <div v-for="    file     in     files    " :key="file.name"
                  class="preview-card cursor-pointer position-relative d-flex justify-content-center w-100"
                  style="z-index:99;">
                  <div class="fileDesc">
                    <p>
                      {{ file.name }}
                    </p>
                  </div>
                  <div class="buttonWrapper cursor-pointer">
                    <button class="ml-2" type="button" @click=" reset(); $event.stopPropagation();" title="Remove file">
                      <img src="@/assets/img/removeFile.svg">
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center">
              <button type="button" class="btn col-lg-2 mt-3 mb-5 upload-file" @click=" processUpload(); step = 2"
                :disabled="files.length == 0">Upload File
              </button>
            </div>
          </div>
        </div>
      </section>
      <template v-if="step == 2">
        <section class="pb-5 px-sm-5 px-0">
          <div class=" container-fluid  px-lg-0   ">
            <div class="container-wrapper  notice-wrapper  py-2 px-3" style="min-height:unset;">
              <div class="row justify-content-between align-items-center">
                <div class="col-12 px-lg-5 px-0">
                  <div class="textwelcome row align-items-center">
                    <div class="col px-4 text-start Apiphone-text pt-3 pb-3 ">
                      <span v-if="headerRow == 'no'">All Uploads require a Header Row - Please Add a Header Row to Your
                        CSV, Save the document, <span class="text-darkblue cursor-pointer" @click="reset()">
                          Go Back
                        </span>
                        and Re-upload</span>
                      <span v-else>
                        Does Your CSV File Have A HeaderRow? <span class="text-darkblue cursor-pointer"
                          @click="reset()">Go
                          Back</span>
                      </span>
                    </div>
                    <div class="col d-flex justify-content-end text-end pt-1 pb-1">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
                          value="yes" v-model="headerRow" />
                        <label class="form-check-label" for="inlineRadio1">Yes</label>
                      </div>
                      <div class="form-check form-check-inline text-end pt-1 pb-1 ">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2"
                          value="no" v-model="headerRow" />
                        <label class="form-check-label" for="inlineRadio2">No</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="pt-3 pb-lg-4 pb-3 px-lg-5 px-0 position-relative">
          <!-- <div class="position-absolute w-100 h-100 bg-grayed" v-if="headerRow !== 'yes'"></div> -->
          <div class="container-fluid p-sm-5 p-3 pt-5 container-wrapper mapping-box border-top-primary">
            <div class="row">
              <div class="bulkBody col-xl-4 col-lg-6 col-12 mb-3">
                <div class=" mb-3">
                  <div class="bulk-upload-text mb-2">Select Phone (Number) Column to Process </div>
                  <select v-model="phone" class="form-select text-start bulkupload-select-header text-dark "
                    title="Pick One">
                    <option selected hidden value="">Select Column</option>
                    <option :value="items" :disabled="checkOptionDisabled(items)" v-for="    items, index     in     csvData?.headers    " :key="index">
                      {{ items }}
                    </option>
                  </select>
                  <span class="text-sm text-danger" v-if="showError && errors['phone']">{{ errors['phone'] }}</span>
                </div>
              </div>
              <div class="bulkBody col-xl-4 col-lg-6 col-12 mb-3">
                <div class="bulk-upload-text mb-2">Select First Name Column </div>
                <select v-model="first_name_col" class="form-select text-start bulkupload-select-header text-dark mb-3"
                  title="Pick One">
                  <option selected value="">Do Not Map</option>
                  <option :value="items" :disabled="checkOptionDisabled(items)" v-for="    items, index     in     csvData?.headers    " :key="index">
                    {{ items }}
                  </option>
                </select>
              </div>
              <div class="bulkBody col-xl-4 col-lg-6 col-12 mb-3">
                <div class="bulk-upload-text mb-2">Select Last Name Column </div>
                <select v-model="last_name_col" class="form-select text-start bulkupload-select-header text-dark mb-3"
                  title="Pick One">
                  <option selected value="">Do Not Map</option>
                  <option :value="items" :disabled="checkOptionDisabled(items)" v-for="    items, index     in     csvData?.headers    " :key="index">
                    {{ items }}
                  </option>
                </select>
              </div>
              <div class="bulkBody col-xl-4 col-lg-6 col-12 mb-3">
                <div class="bulk-upload-text mb-2">Select Full Name Column </div>
                <select v-model="full_name_col" class="form-select text-start bulkupload-select-header text-dark mb-3"
                  title="Pick One">
                  <option selected value="">Do Not Map</option>
                  <option :value="items" :disabled="checkOptionDisabled(items)"  v-for="    items, index     in     csvData?.headers    " :key="index">
                    {{ items }}
                  </option>
                </select>
              </div>
              <div class="bulkBody col-xl-4 col-lg-6 col-12 mb-3">
                <div class="bulk-upload-text mb-2">Select Business Name Column </div>
                <select v-model="business_name_col" class="form-select text-start bulkupload-select-header text-dark mb-3"
                  title="Pick One">
                  <option selected value="">Do Not Map</option>
                  <option :value="items" :disabled="checkOptionDisabled(items)" v-for="    items, index     in     csvData?.headers    " :key="index">
                    {{ items }}
                  </option>
                </select>
              </div>
              <div class="bulkBody col-xl-4 col-lg-6 col-12 mb-3">
                <div class="bulk-upload-text mb-2">Select Email Column </div>
                <select v-model="email_col" class="form-select text-start bulkupload-select-header text-dark mb-3"
                  title="Pick One">
                  <option selected value="">Do Not Map</option>
                  <option :value="items" :disabled="checkOptionDisabled(items)" v-for="    items, index     in     csvData?.headers    " :key="index">
                    {{ items }}
                  </option>
                </select>
              </div>
              <div class="bulkBody col-xl-4 col-lg-6 col-12 mb-3">
                <div class="bulk-upload-text mb-2">Select Street Address Column </div>
                <select v-model="street_address_col"
                  class="form-select text-start bulkupload-select-header text-dark mb-3" title="Pick One">
                  <option selected value="">Do Not Map</option>
                  <option :value="items" :disabled="checkOptionDisabled(items)" v-for="    items, index     in     csvData?.headers    " :key="index">
                    {{ items }}
                  </option>
                </select>
              </div>
              <div class="bulkBody col-xl-4 col-lg-6 col-12 mb-3">
                <div class="bulk-upload-text mb-2">Select Street Address 2 Column </div>
                <select v-model="street_address_2_col"
                  class="form-select text-start bulkupload-select-header text-dark mb-3" title="Pick One">
                  <option selected value="">Do Not Map</option>
                  <option :value="items" :disabled="checkOptionDisabled(items)" v-for="    items, index     in     csvData?.headers    " :key="index">
                    {{ items }}
                  </option>
                </select>
              </div>
              <div class="bulkBody col-xl-4 col-lg-6 col-12 mb-3">
                <div class="bulk-upload-text mb-2">Select City Column </div>
                <select v-model="city_col" class="form-select text-start bulkupload-select-header text-dark mb-3"
                  title="Pick One">
                  <option selected value="">Do Not Map</option>
                  <option :value="items" :disabled="checkOptionDisabled(items)" v-for="    items, index     in     csvData?.headers    " :key="index">
                    {{ items }}
                  </option>
                </select>
              </div>
              <div class="bulkBody col-xl-4 col-lg-6 col-12 mb-3">
                <div class="bulk-upload-text mb-2">Select State Column </div>
                <select v-model="state_col" class="form-select text-start bulkupload-select-header text-dark mb-3"
                  title="Pick One">
                  <option selected value="">Do Not Map</option>
                  <option :value="items" :disabled="checkOptionDisabled(items)" v-for="    items, index     in     csvData?.headers    " :key="index">
                    {{ items }}
                  </option>
                </select>
              </div>
              <div class="bulkBody col-xl-4 col-lg-6 col-12 mb-3">
                <div class="bulk-upload-text mb-2">Select Postal Code Column </div>
                <select v-model="postal_code_col" class="form-select text-start bulkupload-select-header text-dark mb-3"
                  title="Pick One">
                  <option selected value="">Do Not Map</option>
                  <option :value="items" :disabled="checkOptionDisabled(items)" v-for="    items, index     in     csvData?.headers    " :key="index">
                    {{ items }}
                  </option>
                </select>
              </div>
              <div class="bulkBody col-xl-4 col-lg-6 col-12 mb-3">
                <div class="bulk-upload-text mb-2">Select Dob Column </div>
                <select v-model="dob_col" class="form-select text-start bulkupload-select-header text-dark mb-3"
                  title="Pick One">
                  <option selected value="">Do Not Map</option>
                  <option :value="items" :disabled="checkOptionDisabled(items)" v-for="    items, index     in     csvData?.headers    " :key="index">
                    {{ items }}
                  </option>
                </select>
              </div>
              <div class="bulkBody col-xl-4 col-lg-6 col-12 mb-3">
                <div class="bulk-upload-text mb-2">Select Dnc Column </div>
                <div class=" mb-3">
                  <select v-model="dnc_col" class="form-select text-start bulkupload-select-header text-dark"
                    title="Pick One">
                    <option selected hidden value="">Select Column</option>
                    <option :value="items" :disabled="checkOptionDisabled(items)" v-for="    items, index     in     csvData?.headers    " :key="index">
                      {{ items }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="bulkBody col-xl-4 col-lg-6 col-12 mb-3">
                <div class="bulk-upload-text mb-2">Select Line Type Column </div>
                <div class=" mb-3">
                  <select v-model="line_type_col" class="form-select text-start bulkupload-select-header text-dark"
                    title="Pick One">
                    <option selected hidden value="">Select Column</option>
                    <option :value="items" :disabled="checkOptionDisabled(items)" v-for="    items, index     in     csvData?.headers    " :key="index">
                      {{ items }}
                    </option>
                  </select>
                <span class="text-sm text-danger" v-if="showError && errors['line_type_col']">{{ errors['line_type_col'] }}</span>
                </div>
              </div>
              <div class="bulkBody  col-12">
                <div class="mb-3 w-100 text-center">
                  <button class="btn w-100 mx-auto process-btn addCreditBtn mt-2" @click="checkStep3()">
                    Process
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="data-table pb-5 px-sm-5 px-0">
          <div
            class="container-wrapper table-responsive  table-text-bulkview container-fluid px-lg-0 px-2 pt-1  mt-2 pb-4">
            <p class="mt-4 mb-3 table-text-bulkview-title">CSVFile Data &nbsp; &nbsp;(First 10 Records)</p>
            <div v-if="isCsvProcessing" class="p-5 text-center">
              Loading... <i class="fa fa-circle-o-notch fa-spin"></i>
            </div>
            <table class="table table-lg table table-striped table-hover mt-2" v-else>
              <thead class="border border-bottom">
                <tr class="TableText-Text">
                  <th scope="col" class="" v-for="    items, index     in     csvData?.headers    " :key="index">{{
                    items }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(    items, i    )  in     csvData?.data?.slice(0, 10)    " :key="i">
                  <td v-for="(    item, j    )  in     csvData?.headers    " :key="j">{{ (items[item]) }} </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
      </template>

      <section v-if="step == 3" class="pt-3 pb-sm-4 pb-3 px-sm-5 px-0">
        <div class="container-fluid p-3  container-wrapper border-top-primary">
          <div class=" container-fluid px-0 text-center">
            <div class="mb-3">
              <div class="col-12 mx-auto" style="max-width:  610px;">
                <div class="row align-items-center">
                  <div class="col-4 text-start">
                    <button class="btn py-1 px-3 mb-0 border-0 btn-info" @click=" step = 2">Go Back</button>
                  </div>
                  <div class="col-4 text-center bulkBody-text-title">
                    File Info
                  </div>
                </div>
                <div class="column   mt-1 mb-1 border-0">
                  <div class=" bulk-Bodybox-view text-start">
                    <div class="row mt-4 px-4 justify-content-center align-items-center">
                      <div class="col-6 bulktext-styleinfo py-2  px-3">
                        <p class="mb-0">Filename:</p>
                      </div>
                      <div class="col-6 text-sm-end ">
                        <div class="w-100">
                          <button class="BulkCard-button  py-2 pe-3 ps-3">{{ files[0]?.name }}</button>
                        </div>
                      </div>
                      <div class="col-6 bulktext-styleinfo  py-2 px-3 ">
                        <p class="mb-0">Uploaded at:</p>
                      </div>
                      <div class="col-6 bulktext-styleinfo py-2 text-sm-end ">
                        <div>
                          <button class="BulkCard-button  py-2 pe-3 ps-3">{{ getTime() }}</button>
                        </div>
                      </div>
                      <div class="col-6 bulktext-styleinfo py-2 px-3">
                        <p class="mb-0">Total Records:</p>
                      </div>
                      <div class="col-6 bulktext-styleinfo pt-2 text-sm-end ">
                        <div>
                          <button class="BulkCard-button  py-2 pe-3 ps-3">{{ csvData?.data?.length }}</button>
                        </div>
                      </div>
                      <div class="col-6 bulktext-styleinfo py-2 px-3 ">
                        <p class="mb-0">Column For Phone Number:</p>

                      </div>
                      <div class="col-6 bulktext-styleinfo py-2 text-sm-end">
                        <div>
                          <button class="BulkCard-button  py-2 pe-3 ps-3">{{phone}}</button>
                        </div>
                      </div>
                      <!-- <div class="bulk-laststep-footer  text-start">
                        <div class="row">
                          <div class="col-6 py-3 px-3">
                            Total Credits:
                          </div>
                          <div class="col-6 text-end py-3 px-4">{{ csvData?.data?.length }}</div>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class=" container-fluid px-0 text-center">
            <div class="mb-3 text-center">
              <button class="btn addCreditBtn-process mt-2" :disabled="isBulkUploading" @click=" applyBulkUpload()">
                <span v-if="isBulkUploading">Processing... <i class="fa fa-circle-o-notch fa-spin"></i></span>
                <span v-else>Process Records</span>
              </button>
              <!-- <div class="text-danger fw-bold mt-2" v-if="total_credits_balance < csvData?.data?.length">It looks like you don’t have enough credits. <router-link to="/purchase">Click here</router-link> to add more credits</div> -->
            </div>
          </div>
        </div>
      </section>
      <section v-if="step == 4" class="pt-3 pb-sm-4 pb-3 px-sm-5 px-0">
        <div class="container-fluid p-3 pt-5 container-wrapper  border-top-primary">
          <div class="bulkBody ">
            <div class="lastonetep-bulkpage text-center">
              <div class="last-step-title mb-3">Thank You!</div>
              <div class="last-step-description mx-auto mb-4">Your file is added in queue and getting ready. It can take
                up to
                20 minutes depending on number of records. </div>
              <div class="last-step-note mt-1 mb-3 mx-auto">You will get an email once your file is ready! If you do not
                see the email in your inbox, make sure to check your junk/spam folder </div>
            </div>
          </div>
          <div class="container">
            <div class=" align-items-justify text-center  ">
              <router-link to="export">
                <button class="btn addCreditBtn check-file-status-btn mt-2 d-inline-block mx-lg-2 pt-0 pb-0 mx-auto">
                  Check File Status
                </button>
              </router-link>
              <!-- <router-link to="export">
                  <button class="btn addCreditBtn view-all-files-btn mt-2 d-inline-block mx-lg-2 pt-0 pb-0 mx-auto">
                    View All Files
                  </button>
                </router-link> -->
            </div>
          </div>
        </div>
      </section>
    </template>
    <template v-else>
      <section class="pb-5 px-sm-5 px-2">

        <div class=" container-fluid  px-0   ">
          <div class="container-wrapper  notice-wrapper">
            <div class="row justify-content-between align-items-center">
              <div class="col-lg-12 col-12 ">
                <div class="text-center text-danger fw-bold " v-if="userInfo?.user_plan_status == 'paused'">
                  <router-link to="/accountInfo?scrollTo=activate">
                    Please activate your account first
                  </router-link>
                </div>
                <div class="text-center text-danger fw-bold " v-else>
                  <router-link to="/purchase">
                    Please buy a plan first
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>

  </main>
</template>
<script>
// import DropFile from "@/components/custom/DropFile.vue";
import Api from "@/Api";
import useStorage from "@/composables/useStorage";
import moment from "moment";
import { db } from "@/firebaseConfig";
import EmailTemplate from "@/components/EmailTemplate"
import csv2json from "csvjson-csv2json"
// import ArgonInput from "@/components/ArgonInput.vue";
// import ArgonSwitch from "@/components/ArgonSwitch.vue";
// import ArgonButton from "@/components/ArgonButton.vue";
// import ArgonAlert from "@/components/ArgonAlert.vue";
export default {
  name: "UploadExisting",
  props: ['userInformation'],
  created() {
  },
  beforeUnmount() {
  },
  data() {
    return {
      isDragging: false,
      files: [],
      isModalVisible: false,
      headers: [],
      csvData: {},
      isCsvProcessing: false,
      phone: '',
      last_name: '',
      first_name: '',
      isBulkUploading: false,
      step: 1,
      newcsv: {},
      isTableError: false,
      isCsvUploading: false,
      selectedInputIndex: 1,
      url: null,
      userInfo: null,
      headerRow: 'yes',
      first_name_col: '',
      last_name_col: '',
      full_name_col: '',
      business_name_col: '',
      email_col: '',
      street_address_col: '',
      street_address_2_col: '',
      city_col: '',
      state_col: '',
      postal_code_col: '',
      dob_col: '',
      dnc_col: '',
      line_type_col: '',
      errors: {},
      showError:false,
      header_row:1,
      total_credits_balance:0,
      isCheckTotalBalanace:true
    }
  },
  mounted() {
    this.userInfo = Api.getUserSession()
    // console.log('this.userInfo',  this.userInfo)
  },
  watch: {
    step() {
      window.scrollTo(0, 0)
    },
    phone() {
      this.checkCsvFormValid()
    },
    line_type_col() {
      this.checkCsvFormValid()
    },
    userInfo() {
      // console.log('userInfo', this.userInfo)
    },
    headerRow() {
      if(this.headerRow == 'yes') {
        this.header_row = 1;
      } else {
        this.header_row = 0;
      }
    },
    
    csvData: {
      deep:true,
      handler() {
        this.checkTotalBalance()
      }
    }
  },
  methods: {
    checkTotalBalance() {
      this.isCheckTotalBalanace = true;
      Api.getUserInfo().then((res) => {
        this.total_credits_balance = res?.data?.total_credits_balance ? res.data?.total_credits_balance : 0;
      }).catch((err) => {
        console.log('Err ', err)
      }).finally(() => {
        this.isCheckTotalBalanace = false;
      })
    },
    checkOptionDisabled(event) {
      if (event == this.first_name_col) {
        return true
      } else if(event == this.phone) {
        return true
      } else if(event == this.last_name_col) {
        return true
      } else if(event == this.full_name_col) {
        return true
      } else if(event == this.business_name_col) {
        return true
      } else if(event == this.email_col) {
        return true
      } else if(event == this.street_address_col) {
        return true
      } else if(event == this.street_address_2_col) {
        return true
      } else if(event == this.city_col) {
        return true
      } else if(event == this.state_col) {
        return true
      } else if(event == this.postal_code_col) {
        return true
      } else if(event == this.dob_col) {
        return true
      } else {
        return false
      }
    },
    checkStep3() {
      this.checkCsvFormValid()
      this.showError = true;
      if (Object.keys(this.errors).length > 0) {
        return
      } else {
        this.step = 3
      }
    },
    checkCsvFormValid() {
      this.errors = {}
        if (this.phone == '') {
          this.errors['phone'] = 'Phone is required';
        }
        if (this.line_type_col == '') {
          this.errors['line_type_col'] = 'Line Type is required'
        }
    },
    processUpload() {
      this.phone = ''
      this.isCsvProcessing = true
      let fileReader = new FileReader();
      fileReader.readAsText(this.files[0]);
      fileReader.onload = this.getCsvDetails
      fileReader.onerror = function () {
        // alert(fileReader.error);
      };
    },
    async onSelectCsv(event) {
      this.files = [];
      let file = event.target.files[0];
      if (file) {
        this.files.push(file)
      }
    },
    reset() {
      this.step = 1;
      this.phone = '';
      this.last_name = ' ';
      this.first_name = '',
        this.csvData = {}
      this.files = []
      this.showError = false
      this.selectedInputIndex += 1
    },
    getCsvDetails(event) {
      this.csvData = {}
      // var allTextLines = event.target.result.split('/\r|\n/');

      var headers = [];
      let idx = 0;
      // if (this.headerRow == 'yes') {
      //   idx = 0
      // } else {
      //   idx = 1
      // }
      var lines = [];
      const json = csv2json(event.target.result, { parseNumbers: true });
      // console.log(json, idx);
      if (json.length == 0) {
        this.$swal({
          title: 'Attention',
          text: 'Csv is empty',
          icon: 'warning',
          showCancelButton: false,
          cancelButtonText: 'Cancel',
          confirmButtonText: 'ok',
          customClass: {
            confirmButton: 'btn bg-gradient-success',
            cancelButton: 'btn bg-gradient-danger',
          },
          buttonsStyling: false,

        })
        this.reset()
        return;
      }
      headers = Object.keys(json[idx]);
      lines = json
      // console.log('allTextLines', allTextLines)
      // for (var i = 1; i < allTextLines.length; i++) {
      //   var data = allTextLines[i].split(',');
      //   if (data.length == headers.length) {
      //     let key = {}
      //     for (var j = 0; j < headers.length; j++) {
      //       key[headers[j]] = data[j]
      //     }
      //     lines.push(key)
      //     console.log('key',key)
      //   }
      // }
      this.csvData = {
        headers: headers,
        data: lines
      }
      // console.log('data', this.csvData)
      this.isCsvProcessing = false;
    },
    async applyBulkUpload() {
      // if(this.total_credits_balance < this.csvData?.data?.length) {
      //   return
      // }
      this.isBulkUploading = true;

      const { uploadFile, url, error } = useStorage();
      if (this.files[0]) {
        await uploadFile(this.files[0]);
      }
      if (error.value) {
        this.$swal({
          title: 'Error',
          text: 'An Error Occured',
          icon: 'warning',
          showCancelButton: false,
          cancelButtonText: 'Cancel',
          confirmButtonText: 'ok',
          customClass: {
            confirmButton: 'btn bg-gradient-success',
            cancelButton: 'btn bg-gradient-danger',
          },
          buttonsStyling: false,

        })
        return
      }
      // console.log('error', error)
      // console.log('this.csvData.data', this.csvData.data)
      // let data = '';

      // const { layout } = EmailTemplate();
      // const applyBulk = functions.httpsCallable('applyBulkUpload');
      // applyBulk({
      //   csvData: this.csvData,
      //   file_name: this.files[0].name,
      //   uploaded_file: url.value,
      //   total_records: this.csvData.data.length,
      //   phone: this.phone,
      //   userId: this.userInfo?.userId,
      //   email: this.userInfo?.email,
      //   template: layout
      // })
      // this.step = 4
      // let data = ''
      // this.csvData?.data.forEach((el) => {
      //   if (data == '' && el[this.phone] !== '') {
      //     data = el[this.phone]
      //   } else {
      //     if (el[this.phone] !== '') {
      //       data += ',' + el[this.phone]
      //     }
      //   }
      //   // data = data == '' ? el[this.phone] ? el[this.phone]  : '' : data + ',' + el[this.phone] ? el[this.phone] : '';
      // })
      // // console.log('data', data)
      let payload = {
        file_name: this.files[0].name,
        uploaded_file: url.value,
        phone_col: this.phone,
        first_name_col: this.first_name_col,
        last_name_col: this.last_name_col,
        full_name_col: this.full_name_col,
        business_name_col: this.business_name_col,
        email_col: this.email_col,
        street_address_col: this.street_address_col,
        street_address_2_col: this.street_address_2_col,
        city_col: this.city_col,
        state_col: this.state_col,
        postal_code_col: this.postal_code_col,
        dob_col: this.dob_col,
        dnc_col: this.dnc_col,
        line_type_col: this.line_type_col,
        header_row:this.header_row
      }
      Api.bulkUploadPhoneNumber(payload).then(() => {
        this.step = 4;
        // let file_reference = res.data.file_reference
        // let headers = [...this.csvData.headers, 'Blacklist', 'Linetype']
        // this.newcsv = {
        //   data: [...this.csvData.data],
        //   headers: headers
        // }
        // if (Object.keys(res.data.phones_verification).length) {
        //   this.isTableError = false
        //   this.newcsv.data?.forEach((el) => {
        //     el['Dnc'] = res.data.phones_verification[el[this.phone]].blacklist == 0 ? false : true
        //     el['Linetype'] = res.data.phones_verification[el[this.phone]].linetype
        //   })
        //   this.exportCSVFile(this.newcsv.headers, this.newcsv.data, 'Export', file_reference)
        //   // this.step = 4;
        // } else {
        //   this.isTableError = true;
        // }
      }).catch((err) => {
        console.log('err:', err)
      }).finally(() => {
        this.isBulkUploading = false
      })
    },
    convertToCSV: async function (objArray) {
      var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
      var str = "";
      for (var i = 0; i < array.length; i++) {
        var line = "";
        for (var index in array[i]) {
          if (line != "") line += ",";
          line += await array[i][index];
        }
        str += line + "\r\n";
      }
      return str;
    },
    getTime() {
      return moment().format('YYYY-MM-DD')
    },
    exportCSVFile: async function (headers = null, items, fileTitle, file_reference) {
      this.isCsvUploading = true
      if (headers) {
        items.unshift(headers);
      }
      // Convert Object to JSON
      var jsonObject = await JSON.stringify(items);
      var csv = await this.convertToCSV(jsonObject);
      var exportedFilename = fileTitle + ".csv" || "export.csv";
      var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, exportedFilename);
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportedFilename);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          await this.uploadFiles(url, file_reference)
        }
      }
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      this.$refs.file.files = e.dataTransfer.files;
      this.onSelectCsv();
      this.isDragging = false;
    },
    remove(i) {
      this.files.splice(i, 1);
    },
    async uploadFiles(event, file_reference) {
      fetch(event)
        .then((res) => res.blob())
        .then(async (myBlob) => {
          myBlob.name = 'export.csv';
          myBlob.lastModified = new Date();
          const { uploadFile, url, error } = useStorage();
          const myFile = new File([myBlob], 'export.csv', {
            type: myBlob.type,
          });
          if (myFile) {
            await uploadFile(myFile);
          }
          if (!error.value) {
            Api.uploadUserfiles({ url: url.value, file_reference: file_reference, header_row:this.header_row }).then(() => {
              this.url = {
                value: url.value,
                type: 'success',
                msg: `File Uploaded Successfully, Click <a class='text-pink' download href=${url.value}>here</a> to download`
              }
              const { layout } = EmailTemplate()
              let emailTemplate = layout
              let template = ` <div style="padding-bottom: 20px; font-family: Arial, Helvetica, sans-serif; font-size: 15px">
                      <p style="font-family: Arial, Helvetica, sans-serif; font-size: 15px">Your ${this.files[0].name} has been processed successfully!</p>
                      <br />
                      <div style="padding-bottom: 40px; text-align: center; font-family: Arial, Helvetica, sans-serif; font-size: 15px">
                          <a href="${url.value}"
                          style="text-decoration: none;
                              display: inline-block;
                              text-align: center;
                              padding: 0.75575rem 1.3rem;
                              font-size: 0.925rem;
                              line-height: 1.5;
                              border-radius: 0.35rem;
                              color: #ffffff;
                              background-color: #f668c5;
                              border: 0px;
                              margin-right: 0.75rem !important;
                              font-weight: 600 !important;
                              outline: none !important;
                              vertical-align: middle;
                              font-family: Arial, Helvetica, sans-serif;
                              font-size: 15px;
                              margin-top: 15px !important;
                          "
                          target="_blank"
                          >Download File</a
                          >
                          <a
                          href="https://app.landlinescrubber.com/export"
                          style="
                              text-decoration: none;
                              display: inline-block;
                              text-align: center;
                              padding: 0.75575rem 1.3rem;
                              font-size: 0.925rem;
                              line-height: 1.5;
                              border-radius: 0.35rem;
                              color: #ffffff;
                              background-color: #3100ff;
                              border: 0px;
                              margin-right: 0.75rem !important;
                              font-weight: 600 !important;
                              outline: none !important;
                              vertical-align: middle;
                              font-family: Arial, Helvetica, sans-serif;
                              font-size: 15px;
                              margin-top: 15px !important;
                          "
                          target="_blank"
                          >View All Files
                          </a>
                      </div>`;
              if (emailTemplate.includes('[CONTENT]')) {
                emailTemplate = layout.replace('[CONTENT]', template)
              }
              let obj = {
                to: 'gurpreet.singh@baam.com',
                from: 'noreply@landlinescrubber.com',
                message: {
                  subject: 'File Uploaded',
                  text: `File Uploaded Successfully`,
                  html: emailTemplate,
                },
                timestamp: moment().valueOf(),
                date: moment().format('L'),
                month: moment().format('MMMM'),
                year: moment().format('YYYY'),
                userId: this.userInfo?.userId,
                type: 'files'
              }
              db().collection('mail').add(obj).then(() => {
                // console.log('res',res)
                // axios.post('https://webhook.site/a16f6717-af6d-4cc1-ae4f-34e448c15b5e', { type: 'mail', msg: res })
              });
              this.csvData = {}
              this.newcsv = {}
            }).catch((err) => {
              console.log('err:', err)
              this.url = {
                value: null,
                type: 'error',
                msg: 'Error while uploading file'
              }
            }).finally(() => {
              this.isCsvUploading = false
            })
          }
        });
    },
    showModal() {
      this.isModalVisible = true;
      // if(this.files.length > 0) {
      //   this.isModalVisible = true;
      // } else {
      //   alert("Please upload at least one CSV file");
      // }
    },
    closeModal() {
      this.isModalVisible = false;
    }
  },
};
</script>
  
<style lang="less" scoped>
@import '@/assets/css/bulk.less';
</style>
  
<style>
.bg-grayed {
  background: rgba(253, 253, 253, 0.7);
  z-index: 99;
  left: 0;
}

.BulkCard-button {
  word-break: break-all;
}
.BulkCard-button {
  word-break: break-all;
}
.mapping-box select option:disabled {
  background-color: #cccccc;
  opacity: 0.1!important;
  color: #666666;
}
</style>