<template>
  <nav class="headerMain navbar navbar-expand-lg px-lg-5  container-fluid shadow-none">
    <button class="navbar-toggler px-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      raia-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
      @click="isNavbarOpen = !isNavbarOpen">
      <picture>
        <img src="@/assets/img/hamburger-icon.svg" alt="">
      </picture>
    </button>
    <router-link to="/">
      <picture>
        <source class="logo ps-lg-2" media="(max-width:991px)" srcset="@/assets/img/mobile-logo.svg">
        <img class="logo" src="@/assets/img/logo.svg" alt="Landline Scrubber">
      </picture>
    </router-link>


    <div class="position-absolute   Custom-header-alert"
      v-if="userInfo?.admin_token && userInfo?.token !== userInfo?.admin_token">
      <div class="alert alert-primary " role="alert">
        Logged in as {{ userInfo?.user_login_email }} <span class="fw-bold cursor-pointer" @click="switchBack()">switch to
          my account</span>

      </div>
    </div>

    <div class="navbar-overlay" :class="isNavbarOpen ? 'd-block' : 'd-none'"></div>
    <div class="navbar-list navbar-collapse" :class="isNavbarOpen ? 'show' : ''" id="navbarSupportedContent">
      <div class="menu d-lg-none text-start">
        <div class="col-10 text-end menu-label">
          Menu
        </div>
        <div class="col-2 text-end">
          <img src="@/assets/img/close-nav.svg" class="cursor-pointer" alt="" @click="isNavbarOpen = !isNavbarOpen">
        </div>
      </div>
      <ul class="navbar-nav ms-lg-auto ms-0 text-start px-lg-3 py-2">
        <li class="nav-item  me-4">
          <router-link to="/purchase" class="nav-link">
            <div class="nav-link-border me-2"></div>
            <div>CREDITS/PLANS</div>
          </router-link>
        </li>
        <li class="nav-item  me-4">
          <router-link to="/apidoc" class="nav-link">
            <div class="nav-link-border me-2"></div>
            <div>API</div>
          </router-link>
        </li>
        <li class="nav-item me-4">
          <router-link to="/accountInfo" class="nav-link">
            <div class="nav-link-border me-2"></div>
            <div>ACCOUNT</div>
          </router-link>
        </li>
        <li class="nav-item me-4 " v-if="admin_token">
          <router-link to="/admin" class="nav-link">
            <div class="nav-link-border me-2"></div>
            <div>ADMIN</div>
          </router-link>
        </li>
        <li class="nav-item me-4">
          <router-link to="/bulk" class="nav-link">
            <div class="nav-link-border me-2"></div>
            <div>Bulk Upload</div>
          </router-link>
        </li>
        <li class="nav-item me-4">
          <router-link to="/export" class="nav-link">
            <div class="nav-link-border me-2 "></div>
            <div>EXPORT</div>
          </router-link>
        </li>
      </ul>
    </div>
    <div>
      <a  href="javascript:void(0)" class="position-relative " @click="showdropdown = !showdropdown">
        <img class="logo" src="@/assets/img/navbar-user.svg" alt="Landline Scrubber">
      </a>
      <div class="position-absolute Dropbox-header shadow-lg p-3 mb-5   px-2 py-2 " v-if="showdropdown">
        <span @click="showdropdown = false" class="closeHeaderPopout"> <img src="@/assets/img/removeFile.svg" class="me-3"></span>
        <div class="text-start py-1 px-lg-5 px-3 fw-bold">
          <div class=" drop-text-account  mt-0 pt-0 fw-bold">
            {{ userInfo?.user_name }}
          </div>

          <div v-if="userInfo?.admin_token && userInfo?.token !== userInfo?.admin_token">
            <div class=" fw-bold">
              Logged in as
            </div>
            <div class=" drop-text-account  mt-0 pt-0 fw-bold">
              {{ userInfo.user_login_email }}
            </div>
            <div class=" text-primary text-sm fw-bold cursor-pointer"
              @click="switchBack(); showdropdown = !showdropdown">
              Switch to my account
            </div>
          </div>

          <p class="drop-text-account fw-bold" v-else>
            {{ userInfo.user_login_email }}
          </p>
          <router-link to="/accountInfo">
            <button type="button" class="addCreditBtn mt-5 mb-0 btn btn-sm text-white">Manage My Account</button>
          </router-link>

          <div class=" text-end pt-0 Account-view-text-colr mb-1 logoutUser" @click="logout_user()">

            <span>
              <img src="@/assets/img/Accountmiunsicon.svg">
            </span>
            <span>
              SIGN OUT
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="dropdown">
      <a href="javascript:void(0)" class="dropdown-toggle" type="button" id="dropdownMenuButton1"
        data-bs-toggle="dropdown" aria-expanded="false">
        <img class="logo" src="@/assets/img/navbar-user.svg" alt="Landline Scrubber">
      </a>
      <ul class="dropdown-menu dropdown-menu-end mt-0" aria-labelledby="dropdownMenuButton1" style=" margin-top: 1rem!important;">
            <li><router-link class="dropdown-item text-center p-2 text-uppercase" to="troubleshooting">Trouble Shooting</router-link></li>
        <li><a class="dropdown-item text-center p-2" href="#" @click="logout_user()">Logout</a></li>
      </ul>
    </div> -->
  </nav>
</template>
<script>
import Api from '@/Api';
import storage from "@/Storage";
// import { auth } from '@/firebaseConfig'
export default {
  name: 'HeaderMain',
  props: ['userInformation'],
  emit: ['userInformation'],
  data() {
    return {
      isNavbarOpen: false,
      showdropdown: false,
      userInfo: null,
      admin_token: storage('admin_token')

    }
  },
  mounted() {
    Api.getUserInfo().then(async (res) => {
      if (res?.data?.auth_token) {
        await Api.saveUserSession(res?.data)
        this.userInfo = await Api.getUserSession();
      }
    }).catch((err) => {
      console.log('Error: ', err)
    })
    window.addEventListener("resize", () => {
      if (window.matchMedia("(min-width: 991px)").matches) {
        this.showdropdown = false
        this.isNavbarOpen = false;
      } 
    });
  },
  methods: {
    logout_user() {
      // auth.signOut();
      Api.logout()
      this.$router.push({ name: 'Login' })
    },
    async switchBack() {
      // console.log('admin_token', this.admin_token)
      this.$swal({
        title: 'Attention',
        text: `Are you sure you want to switch to your account?`,
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn bg-gradient-success',
          cancelButton: 'btn bg-gradient-danger',
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.userInfo.token = this.admin_token
          this.userInfo.selectedUser = null
          await Api.saveUserSession(this.userInfo)
          this.userInfo = await Api.getUserSession();
          window.location.reload()
        } else if (
          result.dismiss === this.$swal.DismissReason.cancel
        ) {
          this.$swal.dismiss;
        }
      });

    }
  },
  watch: {
    selectedUser() {
      Api.getUserInfo().then(async (res) => {
        if (res?.data?.auth_token) {
          await Api.saveUserSession(res?.data)
          this.userInfo = await Api.getUserSession();
        }
      }).catch((err) => {
        console.log('Error: ', err)
      })
    },
    userInformation() {
      this.userInfo = { ...this.userInformation }
    },
    $route() {
      this.isNavbarOpen = false;
      this.showdropdown = false;
    }
  },
}
</script>
<style>
.dropdown .dropdown-toggle:after {
  content: '' !important;
}

.Custom-header-alert .alert {
  color: #004085 !important;
  background-color: #cce5ff !important;
  border-color: #b8daff !important;
  background-image: none;
  font-size: 13px !important;
  padding: 0.3rem 1rem !important;
}

.Custom-header-alert {
  top: 0px;
  left: 50%;
  transform: translateX(-50%);

}

.Dropbox-header {
  background: #ECFFC7;
  border-radius: 5px;
  width: 522px;
  min-height: 210px;
  padding-left: 2rem;
  margin-top: 4px;
  text-align: right;
  right: 39px;
  top: 79px;
  z-index: 999;
}

.closeHeaderPopout,
.logoutUser span {
  cursor:pointer;
}

@media only screen and (max-width:575px) {
  .Dropbox-header {
    width: 92vw
  }
}

.drop-text-account {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 22px;
  color: #88C418;
}

.Account-view-text-colr {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 730;
  font-size: 16px;
  line-height: 19px;
  color: #0028FF;
}
</style>