const axios = require("axios");
import storage from "./Storage";
import router from "./routes"
let baseURL = process.env.VUE_APP_API_URL;
import * as sweetalert from 'sweetalert';
const instance = axios.create({
    baseURL: baseURL,
    headers: {
        "Content-Type": "text/plain",
        "Authorization": ''
    }
});

const adminInstance = axios.create({
    baseURL: baseURL,
    headers: {
        "Content-Type": "text/plain",
        "Authorization": ''
    }
});

const unauthInstance = axios.create({
    baseURL: baseURL,
    headers: {
        "Content-type": "text/plain"
    }
});

const selectedUserInstance = axios.create({
    baseURL: baseURL,
    headers: {
        "Content-type": "text/plain"
    }
});


instance.interceptors.request.use((config) => {
    const token = storage('token');
    if (token && config.headers) config.headers.Authorization = `Bearer ${token}`
    config.validateStatus = (status) => status < 400;
    return config
})

adminInstance.interceptors.request.use((config) => {
    const token = storage('admin_token');
    if (token && config.headers) config.headers.Authorization = `Bearer ${token}`
    config.validateStatus = (status) => status < 400;
    return config
})

selectedUserInstance.interceptors.request.use((config) => {
    const token = storage('selectedUser_token');
    if (token && config.headers) config.headers.Authorization = `Bearer ${token}`
    config.validateStatus = (status) => status < 400;
    return config
})

instance.interceptors.response.use((successRes) => {
    if (successRes?.data == 'Error: jwt expired') {
        localStorage.removeItem('lls-userinfo')
        router.push({ name: 'Login' })
    }
    return successRes;
}, (error) => {
    sweetalert({
        title: 'Error!',
        text: error?.response?.data?.message ? error?.response?.data?.message : 'An error occurred',
        icon: 'error',
        buttons: {
            confirm: {
                text: "Close",
                value: true,
                visible: true,
                className: "btn bg-gradient-danger mx-auto",
                closeModal: true
            }
        }
    });
    console.log("caught error", error);
    return Promise.reject(error);
}
);


adminInstance.interceptors.response.use((successRes) => {
    if (successRes?.data == 'Error: jwt expired') {
        localStorage.removeItem('lls-userinfo')
        router.push({ name: 'Login' })
    }
    return successRes;
},
    (error) => {
        sweetalert({
            title: 'Error!',
            text: error?.response?.data?.message ? error?.response?.data?.message : 'An error occurred',
            icon: 'error',
            buttons: {
                confirm: {
                    text: "Close",
                    value: true,
                    visible: true,
                    className: "btn bg-gradient-danger mx-auto",
                    closeModal: true
                }
            }
        });
        console.log("caught error", error);
        return Promise.reject(error);
    }
);

selectedUserInstance.interceptors.response.use((successRes) => {
    // console.log('successRes',successRes)
    if (successRes?.data == 'Error: jwt expired') {
        localStorage.removeItem('lls-userinfo')
        router.push({ name: 'Login' })
    }
    return successRes;
}, (error) => {
    sweetalert({
        title: 'Error!',
        text: error?.response?.data?.message ? error?.response?.data?.message : 'An error occurred',
        icon: 'error',
        buttons: {
            confirm: {
                text: "Close",
                value: true,
                visible: true,
                className: "btn bg-gradient-danger mx-auto",
                closeModal: true
            }
        }
    });
    console.log("caught error", error);
    return Promise.reject(error);
}
);


const Api = {

    payment_customer_exists() { return JSON.parse(localStorage.getItem('lls-userinfo'))?.payment_customer_exists },
    payment_method_exists() { return JSON.parse(localStorage.getItem('lls-userinfo'))?.payment_method_exists },

    bulkVerifyPhoneNumber: async (payload) => {
        return await instance.post(`/bulk_verify`, payload)
    },
    bulkUploadPhoneNumber: async (payload) => {
        return await instance.post(`/bulk_upload`, payload)
    },
    singleVerifyPhoneNumber: async (payload) => {
        return instance.get(`verify?p=${payload.number}`)
    },
    paymentSetup: async (payload) => {
        return instance.post(`payment`, payload)
    },
    signUp: async (payload) => {
        return unauthInstance.post(`token`, payload)
    },
    logIn: async (payload) => {
        return unauthInstance.post(`login`, payload)
    },

    getAllUsers: async () => {
        return adminInstance.get(`users?per_page=all`)
    },
    adminLogin: async (payload) => {
        return adminInstance.post('login', payload)
    },
    getUserInfo: async () => {
        // const apiHeaders = { 
        //     headers: {
        //         "Content-Type": "application/json",
        //         "Authorization" : `Bearer ${JSON.parse(localStorage.getItem('lls-userinfo'))?.token}`,
        //     } 
        // }
        return instance.get(`user`)
    },
    getSelectedUserInfo() {
        return selectedUserInstance.get(`user`)
    },
    saveUserSession: async (payload) => {
        let obj = JSON.parse(localStorage.getItem('lls-userinfo'))
        if (obj) {
            obj = { ...obj, ...payload }
        } else {
            obj = { ...payload }
        }
        localStorage.setItem('lls-userinfo', JSON.stringify(obj))
        // console.log('savedUserSession', JSON.parse(localStorage.getItem('lls-userinfo')))
    },
    getUserSession() {
        let data = JSON.parse(localStorage.getItem('lls-userinfo'))
        if (data) {
            return data
        } else {
            return null
        }
    },
    logout() {
        localStorage.removeItem('lls-userinfo')
    },
    uploadUserfiles(payload) {
        return instance.put(`files`, payload)
    },
    getUserFiles(params) {
        let queryUrl = `files?page=${params.page}&per_page=${params.per_page}`
        if (params.search) {
            queryUrl += `&query=${params.search}`
        } 
        if(params.dnc) {
            queryUrl += `&dnc=${params.dnc}`
        }
        if(params.line_type) {
            queryUrl += `&line_type=${params.line_type}`
        }
        return instance.get(queryUrl)
    },
    getPlans() {
        return unauthInstance.get(`plans`)
    },
    updateApiKey() {
        return instance.put(`apikey`)
    },
    buyPlan: async (payload) => {
        return instance.post(`payment`, payload)
    },
    getPhonelogs(params) {
        if (params?.phone) {
            return instance.get(`logs?order_by=line_type&page=${params.page}&phone=${params.phone}`)
        } else {
            return instance.get(`logs?order_by=line_type&page=${params.page}`)
        }
    },
    getPurchase(params) {
        // let headers = { 
        //     headers: {
        //         "Content-Type": "application/json",
        //         "Authorization" : `Bearer ${storage('token')}`,
        //     } 
        // }
        return instance.get(`purchases?year=${params.year}&month=${params.month}&per_page=${params.per_page}`)
    },
    getSelectedUserPurchase(params) {
        return selectedUserInstance.get(`purchases?year=${params.year}&month=${params.month}&per_page=${params.per_page}`)
    },
    updatePurchaseStatus(payload) {
        return instance.put(`purchases`, payload)
    },
    deleteUserCard() {
        return instance.delete(`payment`)
    },
    updateUserCard(payload) {
        return instance.put(`payment`, payload)
    },

    getUserbyReference(id) {
        return instance.get(`files?file_reference=${id}`)
    },
    updatePurchaseSettings(payload) {
        return instance.put(`purchase_settings`, payload)
    },
    deleteUser(payload) {
        return instance.patch(`user`, payload)
    },
    updateSelectedUserInfo(payload) {
        return adminInstance.put(`users`, payload)
    },
    adjustCredits(payload) {
        return adminInstance.post(`admin`, payload)
    },
    sendResetPasswordLink(payload) {
        return instance.post(`passreset`, payload)
    },
    resetPassword(payload, i) {
        return unauthInstance.patch(`passreset?i=${i}`, payload)
    },
    changePassword(payload) {
        return instance.put(`token`, payload)
    }

}

export default Api