<template>
 
  <main class="mt-0 text-start main-content faqContainer">

    <section class="pt-4 pb-sm-5 pb-3 px-sm-5 px-2">
      <div class="container-fluid  px-0">
          <div class="welcome-title">FAQs</div>
      </div>
    </section>

    <section class="pb-5 px-sm-5 px-2">
      <div class=" container-fluid  container-wrapper  notice-wrapper">
        <div class="row justify-content-between align-items-center">
          <div class="col-lg-7 mb-lg-0 mb-2">
            <span class="fw-bold">Note:</span>
            If you don't find the answer you are looking for <router-link to="contact" class="text-darkblue">CLICK HERE</router-link>
          </div>
          <div class="col-lg-5 mb-lg-0 mb-2">
            <div class="input-group ms-lg-auto search-form py-2 bg-white rounded-3 border">
              <span class="input-group-text border-0 px-3 py-1" id="basic-addon2">
                <img src="@/assets/img/search.svg" class="" alt="">
              </span>
              <input type="search" id="form2" class="form-control border-0 shadow-none py-1 ps-4" placeholder="Search"
                aria-label="Search" />
            </div>
          </div>
        </div>
      </div>
    </section>


    <div class=" pt-3 pb-lg-4 pb-3 px-sm-5 px-0">
      <div class="faqWrapper  container-fluid px-0 "> 
        <div class="faqBody  col-lg-12">
          <div class="wrapper">
            <div class="question d-flex align-items-center">
              <div class="px-3 q-icon">Q</div>
              <div class="text px-lg-5 px-3">How accurate is the data?</div>
            </div>
            <div class="answer1 answer d-flex align-items-center">
              <div class="px-3 a-icon">A</div>
              <div class="text px-lg-5 px-3">Phone numbers are constantly changing so no tool can be 100% accurate, but we update our data
                regularly.</div>
            </div>
            <div class="question2 question d-flex align-items-center">
              <div class="px-3 q-icon">Q</div>
              <div class="text px-lg-5 px-3">Is there a specific format the CSV file needs to be in?</div>
            </div>
            <div class="answer2 answer d-flex align-items-center">
              <div class="px-3 a-icon">A</div>
              <div class="text px-lg-5 px-3">As long as you have the phone number included you can upload your file. Be sure to map the phone number
                column correctly during your upload.</div>
            </div>
            <div class="question2 question d-flex align-items-center">
              <div class="px-3 q-icon">Q</div>
              <div class="text px-lg-5 px-3">Is there a minimum purchase amount?</div>
            </div>
            <div class="answer2 answer d-flex align-items-center">
              <div class="px-3 a-icon">A</div>
              <div class="text px-lg-5 px-3">The minimum amount you can spend in one transaction is $5 which will provide roughly 2,850 credits.</div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </main>
</template>

<script>
import storage from "@/Storage";
export default {
  name: "FAQs",
  components: {
  },
  data() {
    return {
      token :storage('token')
    }
  }
};
</script>

<style lang="less" scoped>
@import '@/assets/css/miscPages.less';
</style>