<template>
  <main class="mt-0 main-content">
    <section>
      <div class="container">
        <div class="verifyWrapper">
          <div class="verifyHeader mb-sm-3 mb-4">Password Reset Link Sent.
          </div>
          <div class="verifyBody">
             <div class="mb-sm-0 mb-3"> We have sent you an e-mail. If you have not received it please check your spam folder.</div>
              <div class="mb-sm-0 mb-3">Otherwise contact us if you do not receive it in a few minutes.</div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
// import ArgonInput from "@/components/ArgonInput.vue";
// import ArgonSwitch from "@/components/ArgonSwitch.vue";
// import ArgonButton from "@/components/ArgonButton.vue";
// import ArgonAlert from "@/components/ArgonAlert.vue";
const body = document.getElementsByTagName("body")[0];
export default {
  name: "LinkSent",
  components: {
  //   ArgonInput,
  //   ArgonSwitch,
  //   ArgonButton,
    // ArgonAlert,
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
  setup() {
    const email = ref("");
    const error = ref(null);
    const isProcessing = ref(false);
    const store = useStore();
    const router = useRouter();
    const forgot = async () => {
      isProcessing.value = true;
      try {
        let forgot = await store.dispatch("forgot", {
          email: body.querySelector("#email").value
        });
        console.log("forgot", forgot);
        isProcessing.value = false;
        router.push({ name: "AccountInfo" });
        // do we want another page for success forgot???? Ask Manish
      } catch (err) {
        isProcessing.value = false;
        const errorMessage = err.message.replace(
          "FirebaseError: Firebase: ",
          ""
        );
        error.value = err.message.replace("FirebaseError: Firebase:", "");
        if (errorMessage === "Error (auth/user-not-found).") {
          error.value = "This email does not exist in the system.";
        }
      }
    };
    const clickForgot = () => {
      document.querySelector('.submit-btn-forgot').click();
    };
    return { forgot, email, error, clickForgot };
  },
};
</script>
<style lang="less" scoped>@import '@/assets/css/miscPages.less';</style>