<template>
  <div class="row g-0" :style="color ? 'flex-direction:column-reverse' : ''">
    <div class="footerDetailsWrapper px-lg-5 px-2 w-100" :class="color ? 'pt-4' : ''">
      <div class="footerDetails row w-100 g-0 align-items-center" v-if="!hideLinks">
        <div class="text-md-end copyright-links d-md-flex col-md-6 mb-md-3 mb-4 justify-content-end ">
          <router-link to="/contact" class="nav-link py-0 me-3 my-2  text-decoration-underline copyright-footer-text"
            :class="color ? color : ''">
            Contact Us
          </router-link>
          <router-link to="/refundpolicy" class="nav-link my-2 py-0 me-3 text-decoration-underline copyright-footer-text"
            :class="color ? color : ''">
            Refund Policy
          </router-link>
          <router-link to="/terms" class="nav-link my-2 py-0 me-3 text-decoration-underline copyright-footer-text"
            :class="color ? color : ''">
            Terms of Services
          </router-link>
          <router-link to="/privacypolicy" class="nav-link my-2 py-0 me-3 text-decoration-underline copyright-footer-text"
            :class="color ? color : ''">
            Privacy Policy
          </router-link>
        </div>
        <div class="copyright col-md-6 px-md-4 px-0 mb-3 text-start " :class="color ? color : ''">
          ©{{ new Date().getFullYear() }} Landline Scrubber All rights reserved.
        </div>
      </div>
    </div>
    <footer class="w-100">
      <div class="footerLogoWrapper col-lg-12" :class="$route.name == 'Welcome' ? 'welcome-footer' : ''">
        <img class="logo p-lg-0 p-2" src="@/assets/img/footerLogo.svg" alt="Landline Scrubber" :class="$route.name == 'Welcome' ? 'welcome-footer-inner' : ''">
      </div>
    </footer>
  </div>
</template>

<script>

export default {
  name: "AppFooter",
  props: ['color', 'hideLinks'],
  // setup() {
  //   const deploymentVersion = process.env.VUE_APP_DEPLOYMENT_VERSION;
  //   const year = moment().format('YYYY')
  //   return {
  //     deploymentVersion,
  //     year
  //   }
  // }
  watch: {
    $route() {
      window.scrollTo(0, 0)
    }
  }
}
</script>
<style>
footer {
  background: url('@/assets/img/footer.svg');
  background-size: cover;
  width: 100%;
  margin: auto;

}

.welcome-footer {
  height: 80.12px;
  position: relative;
}

.welcome-footer-inner {
  max-width: 588.32px;
  top: 42%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 95%;
}
@media only screen and (max-width:575px) {
  .welcome-footer {
    height: 49.88px!important;
  }
  .welcome-footer-inner {
    max-width:330.58px;
    top: 41%;
  }
}
</style>
