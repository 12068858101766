<template>
  <main class="mt-0 text-start main-content faqContainer">

    <section class="pt-4 pb-sm-5 pb-3 px-sm-5 px-2">
      <div class="container-fluid  px-0">
        <div class="welcome-title">Common Problems / Solutions</div>
      </div>
    </section>

    <section class="pb-5 px-sm-5 px-2">
      <div class=" container-fluid  container-wrapper  notice-wrapper">
        <div class="row justify-content-between align-items-center">
          <div class="col-lg-7 mb-lg-0 mb-2">
            <span class="fw-bold">Note:</span>
            If you don't find the answer you are looking for <span class="text-darkblue">CLICK HERE</span>
          </div>
          <div class="col-lg-5 mb-lg-0 mb-2">
            <div class="input-group ms-lg-auto search-form py-2 bg-white rounded-3 border">
              <span class="input-group-text border-0 px-3 py-1" id="basic-addon2">
                <img src="@/assets/img/search.svg" class="" alt="">
              </span>
              <input type="search" id="form2" class="form-control border-0 shadow-none py-1 ps-4" placeholder="Search"
                aria-label="Search" />
            </div>
          </div>
        </div>
      </div>
    </section>


    <div class=" pt-3 pb-sm-4 pb-3 px-lg-5 px-0">
      <div class="faqWrapper  container-fluid px-0">
        <div class="faqBody  col-lg-12">
          <div class="wrapper">
            <div class="question d-flex align-items-center">
              <div class="px-3 q-icon">P</div>
              <div class="text px-lg-5 px-3 ">
                I keep getting an error message when I upload my file.
              </div>
            </div>
            <div class="answer1 answer d-flex align-items-center">
              <div class="px-3 a-icon">S</div>
              <div class="text px-lg-5 px-3 ">
                Ensure that the filetype that you're trying to upload is in .CSV format. You can change the file type in Excel by Saving As: CSV or in Google Sheets by downloading as CSV.
              </div>
            </div>
            <div class="question2 question d-flex align-items-center">
              <div class="px-3 q-icon">P</div>
              <div class="text px-lg-5 px-3 ">
                Contacts are telling me that they are on the DNC list, but LandlineScrubber does not show them as on the DNC.

              </div>
            </div>
            <div class="answer2 answer d-flex align-items-center">
              <div class="px-3 a-icon">S</div>
              <div class="text px-lg-5 px-3 z">
                We are constantly updating our DNC database in real time. According to the National Do Not Call Registry terms and conditions it can take up to 31 days for the phone number to become registered.
              </div>
            </div>
            <div class="question3 question d-flex align-items-center">
              <div class="px-3 q-icon">P</div>
              <div class="text px-lg-5 px-3 ">
                My API Key isn't working
              </div>
            </div>
            <div class="answer3 answer d-flex align-items-center mb-5 ">
              <div class="px-3 a-icon">S</div>
              <div class="text px-lg-5 px-3 ">
                Refresh your API Key with LanlineScrubber and reattempt the connection. If you are still having issues please feel free to contact us for assistance.
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="mt-5">
    </div>


  </main>
</template>
  
<script>
export default {
  name: "TroubleShooting",
  components: {
  },
};
</script>
  
<style lang="less" scoped>@import '@/assets/css/miscPages.less';</style>