<template>
    <main class="mt-0 main-content forgot-wrapper">
        <section v-if="!linkSent">
            <div class="container container-fluid px-0">
                <div class="forgotWrapper col-lg-6 col-md-8 col-12 mx-auto">
                    <div class="forgotHeader text-center">
                        <h1 class="">Reset your password?</h1>

                    </div>
                    <div class="forgotBody mx-auto">
                        <div class="userInfo">
                            <form role="form" @submit.prevent="resetPassword" class="text-start">
                                <div class="mb-3">
                                    <input id="password" type="password" class="form-control"
                                        placeholder="Enter your password*" v-model="password" />
                                    <span class="text-danger" v-if="showErrors && errors['password']">
                                        <small>{{ errors['password'] }}</small>
                                    </span>
                                </div>

                                <div class="mb-3">
                                    <input id="conFirmPassword" type="password" class="form-control"
                                        placeholder="Reenter your password*" v-model="confirmPassword" />
                                    <span class="text-danger" v-if="showErrors && errors['confirmPassword']">
                                        <small>{{ errors['confirmPassword'] }}</small>
                                    </span>
                                </div>
                                <div class="error text-sm text-danger">{{ error }}</div>

                                <div class="">
                                    <button class="submit-btn-forgot mb-3" @click="passwordResetLink"
                                        :disabled="isProcessing">
                                        <span v-if="isProcessing">
                                            Reseting... <i class="fa fa-circle-o-notch fa-spin"></i>
                                        </span>
                                        <span v-else>
                                            Reset Password
                                        </span>
                                    </button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section v-else>
            <div class="container verifyWrapper-container">
                <div class="verifyWrapper">
                    <div class="verifyHeader mb-sm-3 mb-4">Password Reset Link Sent.
                    </div>
                    <div class="verifyBody">
                        <div class="mb-sm-0 mb-3"> We have sent you an e-mail. If you have not received it please check your
                            spam folder.</div>
                        <div class="mb-sm-0 mb-3">Otherwise contact us if you do not receive it in a few minutes.</div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>
  
<script>
import Api from '@/Api';
import 'firebase/auth'
// import ArgonInput from "@/components/ArgonInput.vue";
// import ArgonSwitch from "@/components/ArgonSwitch.vue";
// import ArgonButton from "@/components/ArgonButton.vue";
// import ArgonAlert from "@/components/ArgonAlert.vue";
const body = document.getElementsByTagName("body")[0];
export default {
    name: "ResetPassword",
    components: {
        //   ArgonInput,
        //   ArgonSwitch,
        //   ArgonButton,
        // ArgonAlert,
    },
    data() {
        return {
            isProcessing: false,
            error: null,
            linkSent: false,
            errors: {},
            password: '',
            confirmPassword: '',
            showErrors: false
        }
    },
    created() {
        this.$store.state.hideConfigButton = true;
        this.$store.state.showNavbar = false;
        this.$store.state.showSidenav = false;
        this.$store.state.showFooter = false;
        body.classList.remove("bg-gray-100");
    },
    beforeUnmount() {
        this.$store.state.hideConfigButton = false;
        this.$store.state.showNavbar = true;
        this.$store.state.showSidenav = true;
        this.$store.state.showFooter = true;
        body.classList.add("bg-gray-100");
    },
    methods: {
        checkFormValid() {
            this.errors = {}
            if (this.confirmPassword !== this.password) {
                this.errors['confirmPassword'] = "Please Make Sure Password and Confirm password Matches"
            }
            if (this.password == '') {
                this.errors['password'] = "Password is Required"
            }
            if (this.confirmPassword == '') {
                this.errors['confirmPassword'] = "Confirm Password is Required"
            }
        },
        async resetPassword() {
            this.checkFormValid()
            this.showErrors = true;
            if (Object.keys(this.errors).length) {
                return
            }
            this.isProcessing = true
            Api.resetPassword({ userpass: this.password }, this.$route.query.i).then((res) => {
                if (res?.data) {
                    this.$swal({
                        title: "",
                        text: res?.data?.message ? res?.data?.message : "Password Changed Successfully!",
                        icon: "success",
                        confirmButtonText: "Close",
                        customClass: {
                            confirmButton: "btn bg-gradient-danger",
                            cancelButton: "btn bg-gradient-danger",
                        },
                        buttonsStyling: false,
                    }).then(() => {
                    this.$router.push({ name: "Login" })
                })
                }
                this.showError = false
                this.password = '';
                this.confirmPassword = ''
                this.error = {}
            }).catch((err) => {
                this.$swal({
                    title: "",
                    text: err?.response.data?.message ? err?.response?.data?.message : "Am Error Occured!",
                    icon: "error",
                    confirmButtonText: "Close",
                    customClass: {
                        confirmButton: "btn bg-gradient-danger",
                        cancelButton: "btn bg-gradient-danger",
                    },
                    buttonsStyling: false,
                })
            }).finally(() => {
                this.isProcessing = false
            })
        }
    },
    watch: {
        password() {
            this.checkFormValid()
        },
        confirmPassword() {
            this.checkFormValid()
        }
    },
    mounted() {
        if(!this.$route.query.i) {
            this.$router.push({name:'Forgot'})
        }
    }
};
</script>
  
<style lang="less" scoped>
@import '@/assets/css/forgot.less';
</style>
<style lang="less" scoped>
@import '@/assets/css/miscPages.less';
</style>
<style>
.forgot-wrapper,
.forgot-wrapper .container {
    min-height: 75vh !important;
}
</style>