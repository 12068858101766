<template>
  <main class="mt-0 main-content text-start faqContainer privacy-policy">
    <section class="pt-4 pb-lg-5 pb-3 px-lg-5 px-2">
      <div class="container-fluid  px-0">
        <div class="welcome-title">Terms & Conditions</div>
      </div>

    </section>
    <section>
      <div class="container-fluid  px-0">
        <div class=" py-lg-4 py-2 px-sm-5 px-2">
          <div class="introtext mt-1 mb-1"> Please read these Terms and Conditions ("Terms", "Terms and Conditions",
            “Terms of Service”) carefully before using the LandlineScrubber.com services (the "Service") operated by
            SCRUBBALICIOUS LLC ("us", "we", or "our").
          </div>
        </div>
      </div>

      <div class="container-fluid  px-0">
        <div class=" py-1  px-lg-5 px-2">
          <div class="introtext  mb-5">

            By accessing or using the Service, you agree to be bound by these Terms. If you do not agree to all the terms
            and conditions of this agreement, then you must not access or use the Service. These Terms apply to all
            visitors, users, and others who access or use the Service.


          </div>
        </div>
      </div>
    </section>
    <section class="privacy-policy-list ">
      <ol class="ps-md-5">
        <li class=" px-3 mt-2 py-2">
          <div class="">
            <div class=" privacy-textwrapper-text  ">
              <span class="fw-bold-900"> Service Description </span>
              SCRUBBALICIOUS LLC provides a Service that allows users to verify whether a phone number is a landline or
              mobile number by purchasing prepaid credits ("Credits"). Users can utilize these Credits to access the
              information provided by our Service. The Credits are non-refundable, non-transferable, and subject to these
              Terms.
            </div>
          </div>
        </li>

        <li class=" px-3 mt-2 py-2">
          <div class="">
            <div class=" privacy-textwrapper-text">
              <span class="fw-bold-900"> Registration and Account</span> To use our Service, you must register and
              create an account. You agree to provide accurate, current, and complete information during the registration
              process and to update such information to keep it accurate, current, and complete. You are responsible for
              maintaining the confidentiality of your account password and for all activities that occur under your
              account.

            </div>
          </div>
        </li>

        <li class="px-3 mt-2 py-2">
          <div class="privacy-textwrapper-text  ">
            <span class="fw-bold-900"> Privacy </span>
            Your privacy is important to us. Please review our Privacy Policy, which governs the use of personal
            information in connection with the Service
          </div>

        </li>

        <li class=" px-3 mt-2 py-2">
          <div class="privacy-textwrapper-text  ">
            <span class="fw-bold-900"> Payment </span>
            By purchasing Credits, you agree to pay the applicable fees and charges. All fees are stated in US dollars and
            are non-refundable, non-transferable, and non-exchangeable, except as required by law or as otherwise provided
            in these Terms. We reserve the right to change the pricing for Credits at any time.
          </div>

        </li>

        <li class="px-3 mt-2 py-2">
          <div class="privacy-textwrapper-text  ">
            <span class="fw-bold-900"> User Conduct </span>
            You agree not to use the Service for any illegal, fraudulent, or unauthorized purpose. You agree not to use
            the Service in any way that could damage, disable, overburden, or impair the Service or interfere with any
            other party's use and enjoyment of the Service.
          </div>
        </li>
        <li class="px-3 mt-2 py-2">
          <div class="privacy-textwrapper-text  ">
            <span class="fw-bold-900"> Intellectual Property </span>
            The Service and its original content, features, and functionality are and will remain the exclusive property
            of SCRUBBALICIOUS LLC and its licensors. The Service and all related content, including but not limited to
            text, images, graphics, logos, and software are protected by copyright, trademark, and other laws of both the
            United States and foreign countries
          </div>
        </li>
        <li class="px-3 mt-2 py-2">
          <div class="privacy-textwrapper-text  ">
            <span class="fw-bold-900"> Limitation of Liability </span>
            The Service is provided on an “as is” and “as available” basis. We disclaim all warranties, express or
            implied, including but not limited to warranties of merchantability, fitness for a particular purpose, and
            non-infringement. We do not guarantee the accuracy, completeness, or timeliness of the Service. In no event
            shall SCRUBBALICIOUS LLC, its directors, employees, partners, agents, suppliers, or affiliates be liable for
            any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of
            profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of, or
            inability to access or use, the Service; (ii) any conduct or content of any third party on the Service; or
            (iii) unauthorized access, use, or alteration of your transmissions or content, whether based on warranty,
            contract, tort (including negligence), or any other legal theory, whether or not we have been informed of the
            possibility of such damage.
          </div>
        </li>
        <li class="px-3 mt-2 py-2">
          <div class="privacy-textwrapper-text  ">
            <span class="fw-bold-900"> Indemnification </span>
            You agree to defend, indemnify, and hold harmless SCRUBBALICIOUS LLC, its directors, employees, partners,
            agents, suppliers, or affiliates from and against any and all claims, damages, obligations, losses,
            liabilities, costs or debt, and expenses (including but not limited to attorney's fees), resulting from or
            arising out of (i) your use and access of the Service; (ii) a breach of these Terms; or (iii) your violation
            of any law or the rights of a third party.
          </div>
        </li>
        <li class="px-3 mt-2 py-2">
          <div class="privacy-textwrapper-text  ">
            <span class="fw-bold-900"> Governing Law </span>
            These Terms shall be governed and construed in accordance with the laws of the United States and the State in
            which SCRUBBALICIOUS LLC is registered, without regard to its conflict of law provisions
          </div>
        </li>
        <li class="px-3 mt-2 py-2">
          <div class="privacy-textwrapper-text  ">
            <span class="fw-bold-900"> Governing Law </span>
            Eligibility You must be at least 18 years old to use the Service. By using the Service, you represent and
            warrant that you meet the requirement.
          </div>

        </li>
        <li class="px-3 mt-2 py-2">
          <div class="privacy-textwrapper-text  ">
            <span class="fw-bold-900"> Account Suspension and Termination: </span>
            We reserve the right to suspend or terminate your access to the Services at any time for any reason, including
            but not limited to non-payment, violation of our Terms of Service, or if we suspect fraudulent or illegal
            activities associated with your account. In the event of termination, you will not be entitled to any refunds
            or credits for unused services.
          </div>

        </li>
        <li class="px-3 mt-2 py-2">
          <div class="privacy-textwrapper-text  ">
            <span class="fw-bold-900">Subscription Policy: </span>
            We offer various plans, which may include different features and pricing – some of which include a recurring
            subscription fee. Details about the subscription plans and pricing can be found on our website. If you enroll
            in one of these plans you will be charged a recurring subscription fee based on the plan you have selected.
            The fee will be billed automatically to the payment method you provided upon registration, and the billing
            cycle will commence on the date you sign up for the subscription. You are responsible for ensuring that your
            payment information is accurate and up-to-date. Failure to update your payment information may result in
            suspension or termination of your access to the Services.

            <div>
            </div>

            <span class="fw-bold-900 text-decoration-underline">
              Renewals and Cancellations:</span> Your subscription will automatically renew at the end of each billing
            cycle unless you cancel the subscription
            prior to the renewal date. To cancel your subscription, follow the instructions provided on our website or
            contact our customer support
            team at <span class="text-decoration-underline"><a href="mailto:info@landlinescrubber.com">info@landlinescrubber.com.</a></span> Please note that
            cancellations must be initiated at least 24 hours before
            the renewal date to avoid
            being charged for the next billing cycle. No refunds will be provided for partial months or unused Services.
            <div>
            </div>
            <span class="fw-bold-900 text-decoration-underline ">
              Changes to Subscription Policy:
            </span>
            We may update or modify the Subscription Policy at our sole discretion. We will notify you of any
            changes by posting the updated policy on our website or by sending an email notification. Your continued use
            of the Services after the
            effective date of any changes constitutes acceptance of the amended Subscription Policy.
          </div>
        </li>
        <li class="px-3 mt-2 py-2">
          <div class="privacy-textwrapper-text  ">
            <span class="fw-bold-900">
              Changes to Terms and Conditions:
            </span>

            We reserve the right to change these Terms and Conditions at any time, without notice. Your continued use of
            our service after any changes to these Terms and Conditions shall constitute your acceptance of such changes.
          </div>
        </li>
        <li class="px-3 mt-2 py-2">
          <div class="privacy-textwrapper-text  ">
            <span class="fw-bold-900"> Contact Us </span>
            If you have any questions about these Terms and Conditions, please contact us at <span
              class="text-decoration-underline"><a href="mailto:info@landlinescrubber.com">info@landlinescrubber.com.</a></span>
          </div>
        </li>

      </ol>


    </section>
  </main>
</template>

<script>
export default {

  name: "terms",
  components: {
  },

};

</script>

<style lang="less" scoped>@import '@/assets/css/miscPages.less';</style>