<template>
  <main class="mt-0 main-content signUp">
    <section>
      <img src="@/assets/img/lls_welcome_logo.png" class="welcome-bg-logo" alt="" />
      <div class="container col-lg-12 d-flex">
        <div class="signupWrapper">
          <div class='signupHeader'>
            <h1 class="mt-4">Sign Up</h1>
            <p>Registration is easy. Please provide us with an email and password .</p>
          </div>
          <div class="row justify-content-center signupBody">
            <div class="col-lg-12 col-md-12 mt-2">
              <!-- <h5 class="text-dark text-center mt-2 mb-3">Sign in</h5> -->
              <form role="form" @submit.prevent="handleSignUp" class="text-start">
                <div class="mb-3">
                  <input id="email" type="email" class="form-control" placeholder="EMAIL*" v-model="email" />
                  <span class="text-sm text-danger" v-if="showErrors && errors['email']">{{ errors['email']
                  }}</span>
                </div>
                <div class="mb-3">
                  <input id="password" type="password" class="form-control" placeholder="PASSWORD*" v-model="password" />
                  <span class="text-sm text-danger" v-if="showErrors && errors['password']">{{ errors['password']
                  }}</span>
                </div>

                <div class="mb-3">
                  <div class="form-check ">
                    <input class="form-check-input" type="checkbox" v-model="acceptTerms" id="flexCheckDefault">
                    <label class="form-check-label mb-0" for="flexCheckDefault">
                      Accept <router-link to="/terms">Terms of Service</router-link>
                    </label>
                  </div>

                  <span class="text-sm text-danger d-block" v-if="showErrors && errors['acceptTerms']">{{ errors['acceptTerms']
                  }}</span>
                </div>
                <div class="error text-sm text-danger mb-2">{{ error }}</div>
                <div class="error text-sm text-success mb-2">{{ success }}</div>

                <div class="text-center">
                  <button class="submit-btn-signup mb-3 btn" :disabled="isProcessing">
                    <span v-if="isProcessing">
                      Signing in... <i class="fa fa-circle-o-notch fa-spin"></i>
                    </span>
                    <span v-else>
                      Sign up
                    </span>
                  </button>
                </div>
              </form>
              <p class="mb-5">Already have an account? <router-link to="/login">Log in.</router-link></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import Api from "@/Api";
// import ArgonInput from "@/components/ArgonInput.vue";
// import ArgonSwitch from "@/components/ArgonSwitch.vue";
// import ArgonButton from "@/components/ArgonButton.vue";
// import ArgonAlert from "@/components/ArgonAlert.vue";
const body = document.getElementsByTagName("body")[0];
export default {
  name: "SignUp",
  components: {
    //   ArgonInput,
    //   ArgonSwitch,
    //   ArgonButton,
    // ArgonAlert,
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
  setup() {
    const nextInputFocus = () => {
      document.querySelector('#password').focus();
    };
    const clickSignUp = () => {
      document.querySelector('.submit-btn-signup').click();
    };
    return { nextInputFocus, clickSignUp };
  },
  data() {
    return {
      email: '',
      password: '',
      success: '',
      error: '',
      isProcessing: false,
      acceptTerms: false,
      errors: { },
      showErrors:false
    }
  },
  methods: {

    checkFormValid() {
      this.errors = {}
      if (this.email == '') {
        this.errors['email'] = "Email is required"
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(this.email)) {
        this.errors['email'] = 'Invalid email address'
      }

      if (this.password == '') {
        this.errors['password'] = "Password is required"
      }
      if (!this.acceptTerms) {
        this.errors['acceptTerms'] = "Please Accept Terms of Service"
      }
    },
   
    async handleSignUp() {
      this.error = '';
      this.success = '';
      this.checkFormValid()
      this.showErrors = true
      if (Object.keys(this.errors).length > 0) {
        return
      }
      this.isProcessing = true

      let Obj = {
        le: this.email,
        lp: this.password,
        ut: 'user'

      }
      Api.signUp(Obj).then(async () => {
        // console.log(res)
        this.success = 'Account created successfully, Redirecting to login'
        setTimeout(() => {
          this.$router.push({ name: 'Login' })
        }, 2500);
      }).catch((err) => {
        this.error = err.response.data.message
        console.log(err.response.data.message)
      }).finally(() => {
        this.isProcessing = false;
      })
    },
  }, 
  
  watch: {
      email() {
        this.checkFormValid()
      },
      password() {
        this.checkFormValid()
      },
      acceptTerms() {
        this.checkFormValid()
      }

    }
};
</script>
<style>
main .signUpFooter .footerDetailsWrapper {
  color: #3100FF !important;
}

main .signUpFooter .footerDetailsWrapper a {
  color: #3100FF !important;
  text-decoration: underline;
}

main .signUpFooter .footerDetailsWrapper a:hover {
  text-decoration: none;
}
</style>

<style lang="less" scoped>
@import '@/assets/css/signup.less';
</style>