<template>
    <main class="mt-0 main-content text-start">
        <section class="pt-3 pb-sm-4 pb-3  mt-5">
            <div class="container-fluid px-sm-5 py-sm-3 py-0 px-0  ">
                <div class="container-wrapper border-top-primary">
                <div class="d-flex mx-auto justify-content-between align-items-center" style="max-width: 610px;">
                    <div class="ms-3 col-4 text-start">
                        <button class=" btn  py-1 px-3 mb-0  border-0 btn-info" @click="goback()">Go back</button>
                    </div>
                    <div class="text-center bulkBody-text-title col-4">
                        File Info
                    </div>
                    <div class="col-4"></div>
                </div>


                <div class=" text-center">
                    <div class="mb-3">
                        <div class="col-12 mx-auto " style="max-width:  610px;">
                            <div class="column   mt-1 mb-1 border-0">
                                <div class=" bulk-Bodybox-view text-start">
                                    <div class="row mt-4 px-4">
                                        <div class="col-6 bulktext-styleinfo pt-2  px-3">
                                            <p>File ID:</p>
                                        </div>
                                        <div class="col-6 bulktext-styleinfo text-sm-end ">
                                            <p class="pb-2 pe-3 ps-3">
                                               
                                                <span v-if="isLoading">Loading... <i class="fa fa-circle-o-notch fa-spin"></i></span>
                                                <span v-else>{{ file.file_reference }}</span>
                                            </p>
                                        </div>
                                        <div class="col-6 bulktext-styleinfo pt-2  px-3">
                                            <p>Filename:</p>
                                        </div>
                                        <div class="col-sm-6 bulktext-styleinfo col-12 text-sm-end ">
                                            <p class=" pb-2 pe-3 ps-3">
                                               
                                                <span v-if="isLoading">Loading... <i class="fa fa-circle-o-notch fa-spin"></i></span>
                                                <span v-else>{{ file.file_name }}</span>
                                            </p>
                                        </div>
                                        <div class="col-6 bulktext-styleinfo  pt-2 px-3 ">
                                            <p>Uploaded at:</p>
                                        </div>
                                        <div class="col-6 bulktext-styleinfo pt-2 text-sm-end ">
                                            <p class=" pb-2 pe-3 ps-3">
                                               
                                                <span v-if="isLoading">Loading... <i class="fa fa-circle-o-notch fa-spin"></i></span>
                                                <span v-else> {{ getTime(file.last_updated) }}</span>
                                            </p>
                                        </div>
                                        <div class="col-6 bulktext-styleinfo pt-2 px-3">
                                            <p>Total Records (CSV File):</p>
                                        </div>
                                        <div class="col-6 bulktext-styleinfo pt-2 text-sm-end ">
                                            <p class=" pb-2 pe-3 ps-3">
                                               
                                                <span v-if="isLoading">Loading... <i class="fa fa-circle-o-notch fa-spin"></i></span>
                                                <span v-else>{{ file.total_records }}</span>
                                            </p>
                                        </div>
                                        <div class="col-6 bulktext-styleinfo pt-2 px-3">
                                            <p>Total Records (Output File):</p>
                                        </div>
                                        <div class="col-6 bulktext-styleinfo pt-2 text-sm-end ">
                                            <p class=" pb-2 pe-3 ps-3">
                                               
                                                <span v-if="isLoading">Loading... <i class="fa fa-circle-o-notch fa-spin"></i></span>
                                                <span v-else>{{ file.file_url == 'Failed' ? 0 :  file.total_records }}</span>
                                            </p>
                                        </div>
                                        <!-- <div class="col-6 bulktext-styleinfo pt-2 px-3 ">
                                            <p>Column For Phone Number:</p>

                                        </div>
                                        <div class="col-6 bulktext-styleinfo pt-2 text-sm-end">
                                            <p class="  pb-2 pe-3 ps-3">Billing Period</p>
                                        </div> -->
                                        <div class="col-6 bulktext-styleinfo pt-2 px-3">
                                            <p>LineType Details:</p>

                                        </div>

                                        <div class="col-6 bulktext-styleinfo pt-2 text-sm-end ">
                                            <p class=" pb-2 pe-3 ps-3">
                                               
                                                <span v-if="isLoading">Loading... <i class="fa fa-circle-o-notch fa-spin"></i></span>
                                                <span v-else class="text-break">{{  file.file_url == 'Failed' ? '' : JSON.stringify(file.line_types)?.replace(/[{}]/g,
                                                '') }}</span>
                                            </p>
                                        </div>
                                        <div class="col-6 bulktext-styleinfo pt-2 px-3">

                                            <p>DNCType Details:</p>

                                        </div>
                                        <div class="col-6 bulktext-styleinfo pt-2 text-sm-end ">
                                            <p class=" pb-2 pe-3 ps-3">
                                               
                                                <span v-if="isLoading">Loading... <i class="fa fa-circle-o-notch fa-spin"></i></span>
                                                <span v-else class="text-break">{{ file.file_url == 'Failed' ? '' : JSON.stringify(file.dnc_status)?.replace(/[{}]/g,
                                                '') }}</span>
                                            </p>
                                        </div>
                                        <div class="col-6 bulktext-styleinfo  pt-2 px-3 ">
                                            <p>Uploaded File:</p>
                                        </div>
                                        <div class=" col-6 bulktext-styleinfo pt-2 text-sm-end  ">
                                            <button class=" btn  py-1 px-3" @click="downloadOriginalURI()">Download</button>
                                        </div>
                                        <div class="col-6 bulktext-styleinfo  pt-2 px-3 ">
                                            <p>Output File:</p>
                                        </div>
                                        <div class=" col-6 bulktext-styleinfo pt-2 text-sm-end  ">
                                            <p v-if="file.file_url == 'Failed'">Failed</p>
                                            <button v-else class=" btn py-1 px-3" @click="downloadURI()">Download</button>
                                        </div>
                                        <div class="col-6 bulktext-styleinfo pt-2 px-3 ">
                                            <p>Credits Reversed For Duplicates:</p>
                                        </div>
                                        <div class="col-6 bulktext-styleinfo pt-2 text-sm-end">
                                            <p class="pb-2 pe-3 ps-3">
                                                <span v-if="isLoading">Loading... <i class="fa fa-circle-o-notch fa-spin"></i></span>
                                                <span v-else>{{ file.credits_reversed ? file.credits_reversed : 0 }}
                                                </span>
                                            </p>
                                        </div>
                                        <div class="bulk-laststep-footer  text-start">
                                            <div class="row">
                                                <div class="col-sm-6 py-3 px-3">
                                                    Total Credits:
                                                </div>
                                                <div class="col-sm-6 text-end py-3 px-4">
                                                    <span v-if="isLoading">Loading... <i class="fa fa-circle-o-notch fa-spin"></i></span>
                                                <span v-else>{{ file.file_url == 'Failed' ? 0 :  file.credits_charged }}
                                                </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                
                <div class="text-center text-danger mt-3 fw-bold" v-if="file.file_message">{{ file.file_message }}</div>
            </div>
            </div>
        </section>
    </main>
</template>
<script>
// import DropFile from "@/components/custom/DropFile.vue";
import Api from "@/Api";
import useStorage from "@/composables/useStorage";
import moment from "moment";
// import ArgonInput from "@/components/ArgonInput.vue";
// import ArgonSwitch from "@/components/ArgonSwitch.vue";
// import ArgonButton from "@/components/ArgonButton.vue";
// import ArgonAlert from "@/components/ArgonAlert.vue";
export default {
    name: "Fileinfo",
    components: {
        //   ArgonInput,
        //   ArgonSwitch,
        //   ArgonButton,

        // DropFile
        // ArgonAlert,
    },
    created() {
    },
    beforeUnmount() {
    },
    data() {
        return {
            isDragging: false,
            files: [],
            isModalVisible: false,
            headers: [],
            csvData: {},
            isCsvProcessing: false,
            phone: '',
            last_name: '',
            first_name: '',
            isBulkUploading: false,
            step: 1,
            newcsv: {},
            isTableError: false,
            isCsvUploading: false,
            url: null,
            file: {},
            isLoading:true
        }
    },
    watch: {
        headers() {
            console.log('watch', this.headers)
        }
    },
    methods: {
        goback() {
            this.$router.push({ name: 'Export' })
        },
        downloadOriginalURI() {
            var link = document.createElement("a");
            link.download = this.file.file_name;
            link.href = this.file.uploaded_file;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        downloadURI() {
            var link = document.createElement("a");
            link.download = this.file.file_name;
            link.href = this.file.file_url;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        async onSelectCsv(event) {
            this.files = [];
            console.log('event', event)
            let file = event.target.files[0];
            if (file) {
                this.files.push(file)
                this.isCsvProcessing = true;
            }
            let fileReader = new FileReader();
            fileReader.readAsText(this.files[0]);
            fileReader.onload = this.getCsvDetails
            fileReader.onerror = function () {
            };
        },
        reset() {
            this.step = 1;
            this.phone = '';
            this.last_name = ' ';
            this.first_name = '',
                this.csvData = {}
            this.files = []
        },
        getCsvDetails(event) {
            this.csvData = {}
            var allTextLines = event.target.result.split(/\r\n|\n/);
            var headers = [];
            headers = allTextLines[0].split(',');
            var lines = [];
            for (var i = 1; i < allTextLines.length; i++) {
                var data = allTextLines[i].split(',');
                if (data.length == headers.length) {
                    let key = {}
                    for (var j = 0; j < headers.length; j++) {
                        key[headers[j]] = data[j]
                    }
                    lines.push(key)
                    // console.log('key',key)
                }
            }
            this.csvData = {
                headers: headers,
                data: lines
            }
            console.log('data', this.csvData)
            this.isCsvProcessing = false;
        },
        applyBulkUpload() {
            this.isBulkUploading = true;
            console.log('this.csvData.data', this.csvData.data)
            let data = '';
            this.csvData?.data.forEach((el) => {
                data = data == '' ? el[this.phone] : data + ',' + el[this.phone];
            })
            console.log('data', data)
            let obj = {
                phones: data
            }
            Api.bulkVerifyPhoneNumber(obj).then((res) => {
                console.log('res:', res)
                let headers = [...this.csvData.headers, 'Blacklist', 'Linetype']
                this.newcsv = {
                    data: [...this.csvData.data],
                    headers: headers
                }
                if (Object.keys(res.data.phones_verification).length) {
                    this.isTableError = false
                    this.newcsv.data?.forEach((el) => {
                        console.log('res.data.phones_verification[i].blacklist', res.data.phones_verification[el[this.phone]].blacklist)
                        el['Blacklist'] = res.data.phones_verification[el[this.phone]].blacklist == 0 ? false : true
                        el['Linetype'] = res.data.phones_verification[el[this.phone]].linetype
                    })
                    this.exportCSVFile(this.newcsv.headers, this.newcsv.data, 'Export')
                    this.step = 4;
                    console.log(' this.newcsv', this.newcsv)
                } else {
                    this.isTableError = true;
                }
            }).catch((err) => {
                console.log('err:', err)
            }).finally(() => {
                this.isBulkUploading = false
            })
        },
        convertToCSV: async function (objArray) {
            var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
            var str = "";
            for (var i = 0; i < array.length; i++) {
                var line = "";
                for (var index in array[i]) {
                    if (line != "") line += ",";
                    line += await array[i][index];
                }
                str += line + "\r\n";
            }
            return str;
        },
        getTime(time) {
            return moment(time).format('YYYY-MM-DD ')
        },
        exportCSVFile: async function (headers = null, items, fileTitle) {
            this.isCsvUploading = true
            if (headers) {
                items.unshift(headers);
            }
            // Convert Object to JSON
            var jsonObject = await JSON.stringify(items);
            var csv = await this.convertToCSV(jsonObject);
            var exportedFilename = fileTitle + ".csv" || "export.csv";
            var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
            if (navigator.msSaveBlob) {
                // IE 10+
                navigator.msSaveBlob(blob, exportedFilename);
            } else {
                var link = document.createElement("a");
                if (link.download !== undefined) {
                    // feature detection
                    // Browsers that support HTML5 download attribute
                    var url = URL.createObjectURL(blob);
                    link.setAttribute("href", url);
                    link.setAttribute("download", exportedFilename);
                    link.style.visibility = "hidden";
                    console.log('sendLink', link)
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    await this.uploadFiles(url)
                }
            }
        },
        dragover(e) {
            e.preventDefault();
            this.isDragging = true;
        },
        dragleave() {
            this.isDragging = false;
        },
        drop(e) {
            e.preventDefault();
            this.$refs.file.files = e.dataTransfer.files;
            this.onSelectCsv();
            this.isDragging = false;
        },
        remove(i) {
            this.files.splice(i, 1);
        },
        async uploadFiles(event) {
            fetch(event)
                .then((res) => res.blob())
                .then(async (myBlob) => {
                    console.log(myBlob);
                    console.log('myBlob', myBlob)
                    myBlob.name = 'export.csv';
                    myBlob.lastModified = new Date();
                    const { uploadFile, url, error } = useStorage();
                    const myFile = new File([myBlob], 'export.csv', {
                        type: myBlob.type,
                    });
                    console.log('myFile', myFile)
                    if (myFile) {
                        await uploadFile(myFile);
                    }
                    if (!error.value) {
                        Api.uploadUserfiles({ url: url.value }).then((res) => {
                            console.log('res', res)
                            this.url = {
                                value: url.value,
                                type: 'success',
                                msg: `File Uploaded Successfully, Click <a class='text-pink' download href=${url.value}>here</a> to download`
                            }
                        }).catch((err) => {
                            console.log('err:', err)
                            this.url = {
                                value: null,
                                type: 'error',
                                msg: 'Error while uploading file'
                            }
                        }).finally(() => {
                            this.isCsvUploading = false
                            console.log('this.url', this.url)
                        })
                    }
                });
        },
        showModal() {
            this.isModalVisible = true;
            // if(this.files.length > 0) {
            //   this.isModalVisible = true;
            // } else {
            //   alert("Please upload at least one CSV file");
            // }
        },
        closeModal() {
            this.isModalVisible = false;
        },

    },
    mounted() {
        this.isLoading = true
        Api.getUserbyReference(this.$route.query.file_reference).then((res) => {
            console.log(res.data.files[0])
            this.file = res.data.files[0]

        }).catch((err) => {
            console.log('err', err)

        }).finally(() => {
            this.isLoading = false
        })

    }
};
</script>
<style lang="less" scoped>
@import '@/assets/css/bulk.less';
</style>