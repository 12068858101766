<template>
    <HeaderMain v-if="token" :userInformation="userInfo" @userInformation="updateUserData($event); updateRenderKey()"
        :key="headerKey" />
    <HeaderLogin v-else />
    <router-view :userInformation="userInfo" @userInformation="updateUserData($event); updateRenderKey()"></router-view>
    <AppFooter v-if="showFooter" :hideLinks="hideLinks" :color="color" />
</template>
<script>
import HeaderMain from "@/components/custom/HeaderMain.vue";
import AppFooter from "@/components/custom/AppFooter.vue";
import HeaderLogin from "@/components/Login/HeaderLogin.vue";
import Api from "@/Api";
import storage from "@/Storage";
export default {
    name: "AppFrontend",
    components: {
        HeaderMain,
        AppFooter,
        HeaderLogin,
    },
    data() {
        return {
            hideLinks: false,
            color: null,
            token: storage('token'),
            userInfo: {},
            headerKey: 0,
            showFooter: true
        }
    },
    methods: {
        checkRoutes() {
            if (this.$route.name == 'Login' || this.$route.name == 'SignUp' || this.$route.name == 'Plans') {
                this.hideLinks = true
            } else {
                this.hideLinks = false
            }

            if (this.$route.name == 'Welcome') {
                this.showFooter = false
            } else {
                this.showFooter = true
            }
        },
        updateRenderKey() {
            this.headerKey += 1;
        },
        updateUserData(event) {
            this.userInfo = event
        }
    },
    async mounted() {
        this.checkRoutes()
        this.userInfo = await Api.getUserSession();
    },
    watch: {
        $route() {
            this.checkRoutes()
            this.token = storage('token')
        }
    }

}
</script>