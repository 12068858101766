<template>
    <main class="text-start api-doc main-content">
        <section class="pt-4 pb-3 px-sm-5 px-0">
            <div class="container-fluid  px-0">
                <div class="welcome-title ">API Documentation</div>
            </div>
        </section>
        <section class="pb-5 px-sm-5 px-0 ">
            <div class=" container-fluid  px-0">
                <div class="container-wrapper mt-2 mb-4   py-2 section-label" style="min-height: unset;">To integrate with your CRM click below to generate your API key and paste into the API field in the LandLine Scrubber integration.</div>
                <div class="container-wrapper  notice-wrapper  py-2 px-3" style="min-height:unset;">
                    <div class="row justify-content-between align-items-center">
                        <div class="col-lg-12 col-12 ">
                            <div class="textwelcome d-flex align-items-center">
                                <img src="@/assets/img/bell-icon.svg" class="far fa-bell  Api-bell-icon">
                                <span class="px-4  Apiphone-text "> To Get Your API Key
                                    <router-link to="getapi" class="text-outline"> Click Here.</router-link>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pb-5 px-sm-5 px-0 ">
            <div class=" container-fluid  px-lg-0 px-2  ">
                <div class="row justify-content-between align-items-center">
                    <div class="col-lg-12 col-12 ">
                        <div class=" ">
                            <div class="label welcome-title-label d-flex mt-2">
                                <div class="me-2"> 1).</div> 
                                <div>Number Checker API</div>
                            </div>
                            <div class="container-wrapper my-4 mb-3   py-2 section-label" style="min-height: unset;">Required Parameters: API Key and Number</div>
                            <div class="container-wrapper border-0">
                                <div class="mb-4">
                                    <label class="label section-heading mt-0 mb-3">Curl Command:</label>
                                    <div
                                        class="form-control api-doc-input  d-flex align-items-center rounded-2 g-0 pb-3 pt-3">
                                        <img src="@/assets/img/exclamation.svg" />
                                        <div class="api-doc-text d-block">curl --location --request GET
                                            'https://api.landlinescrubber.com/api/check_number?p=Your_Number&k=Your_API_KEY'
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-4">
                                    <label class="label section-heading mt-0 mb-3">Using Browser/Webhook (You can paste below code
                                        into new
                                        tab.)</label>
                                    <div
                                        class="form-control api-doc-input  d-flex align-items-center rounded-2 g-0 pb-3 pt-3">
                                        <img src="@/assets/img/exclamation.svg" />
                                        <div class="api-doc-text d-block ">
                                            https://api.landlinescrubber.com/api/check_number?p=Your_Number&k=Your_API_KEY
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-4">
                                    <label class="label section-heading mt-0 mb-3">Using Python:</label>
                                    <div class="form-control api-doc-input  d-flex align-items-center  rounded-2 g-0 pb-3 pt-3">
                                        <img src="@/assets/img/exclamation.svg" />
                                        <div class="api-doc-text d-block">
                                            import requests
                                            <br>
                                            <br>
                                            url = "https://api.landlinescrubber.com/api/check_number?p=Your_Number&k=Your_API_KEY"
                                            <br>
                                            <br>
                                            payload={}
                                            headers = {}
                                            <br>
                                            <br>
                                            response = requests.request("GET", url, headers=headers, data=payload)
                                            <br>
                                            <br>
                                            print(response.text)
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>

        <section class="pb-5 px-sm-5 px-0">
            <div class=" container-fluid  px-0   ">
                <div class="row justify-content-between align-items-center">
                    <div class="col-lg-12 col-12 ">
                        <div class="form-group Billing-overview-input">
                            <label class="label d-flex welcome-title-label "> 
                                <div class="me-2">2).</div>
                                <div class=""> Check Remaining Credits API</div>
                            </label>
                            <div class="container-wrapper my-4 py-2 section-label" style="min-height: unset;">Required Parameters: API Key</div>
                            <div class="container-wrapper border-0">
                                <div class="mb-4">
                                    <label class="label section-heading mt-0 mb-3">Curl Command:</label>
                                    <div class="form-control api-doc-input  d-flex align-items-center rounded-2 g-0 pb-3 pt-3">
                                        <img src="@/assets/img/exclamation.svg" />
                                        <a href="#" class="api-doc-text d-block">curl --location --request GET
                                            'https://api.landlinescrubber.com/api/check_credits?k=Your_API_KEY'
                                        </a>
                                    </div>
                                </div>
                                <div class="mb-4">
                                    <label class="label section-heading mt-0 mb-3">Using Browser/Webhook (You can paste below code into new
                                        tab.)</label>
                                    <div class="form-control api-doc-input d-flex align-items-center rounded-2 g-0 pb-3 pt-3">
                                        <img src="@/assets/img/exclamation.svg" />
                                        <a href="#" class="api-doc-text d-block">curl --location --request GET
                                            'https://api.landlinescrubber.com/api/check_credits?k=Your_API_KEY'
                                        </a>
                                    </div>
                                </div>
                                <div class="mb-4">
                                    <label class="label section-heading mt-0 mb-3">Using Python:</label>

                                    <div class="form-control api-doc-input  d-flex align-items-center rounded-2 g-0 pb-3 pt-3">
                                        <img src="@/assets/img/exclamation.svg" />
                                        <div class="api-doc-text d-block">
                                            import requests
                                            <br>
                                            <br>
                                            url =
                                            "https://api.landlinescrubber.com/api/check_credits?k=Your_API_KEY"
                                            <br>
                                            <br>
                                            payload={}
                                            headers = {}
                                            <br>
                                            <br>
                                            response = requests.request("GET", url, headers=headers, data=payload)
                                            <br>
                                            <br>
                                            print(response.text)
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
        </section>
    </main>
</template>    
<script>
import getCountries from "@/composables/getCountries"
import { ref } from "vue";
// import getUser from "@/composables/getUser";
export default {
    name: "ApiDoc",
    data() {
        return {
            cardDetails: '',
            number: '',
            exp_month: '',
            exp_year: '',
            cvc: '',
            name: '',
            line1: '',
            line2: '',
            postal_code: '',
            city: '',
            state: '',
            isFormUploading: false,
            formResponse: null
        }
    },
    setup() {
        const country = ref('');
        // const { user } = getUser()
        // const email = user?.value?.email;
        // console.log('email', email)
        const { countryListAlpha } = getCountries()
        return {
            country,
            countryListAlpha,
            // email
        }
    },
    methods: {
        checkFormValid() {
            if (this.city == '' || this.country == '' || this.line1 == '' || this.postal_code == '' || this.number == '' || this.number.length < 16 || this.state == '' || this.name == '' || this.exp_month == '' || this.exp_year == '' || this.cvc == '') {
                return false
            }
            return true
        },
    },
    watch: {
        cardDetails() {
            console.log('cardDetails', this.cardDetails)
            let cardData = this.cardDetails.split('/');
            this.exp_month = cardData[0] ? cardData[0] : '';
            this.exp_year = cardData[1] ? cardData[1] : '';
            this.cvc = cardData[2] ? cardData[2] : '';
        },

    }
}

</script>
<style lang="less" scoped>@import '@/assets/css/admin.less';</style>