<template>
    <main class="text-start user-logs main-content" @click="isDownloadOpen = false">
        <section class="pt-3 pb-lg-4 pb-3 px-md-5 px-0">
            <div class="welcome-section container-fluid px-0">
                <div>
                    <div class="welcome-title my-3">Export</div>
                    <div class="welcome-sub-title">Uploaded Files</div>
                </div>
            </div>
        </section>
        <section class="pb-lg-5 pb-3 px-sm-5 px-0 export-user-logs">
            <div class=" container-fluid px-0">
                <div class="row justify-content-between">
                    <div class="mb-3 col-lg-6 col-12 mt-2">
                        <div
                            class="d-sm-inline d-flex select-menu align-items-center justify-content-between mb-sm-0 mb-3 me-sm-5 mb-2">
                            <div class="d-inline-block label">Export Options LineType:</div>
                            <div class="d-inline-block">
                                <select class="form-select ms-3" title="Pick One" v-model="lineType"
                                    @change="page = 1; getFiles()">
                                    <option value="">All</option>
                                    <option value="mobile">Mobile</option>
                                    <option value="landline">Landline</option>
                                    <option value="invalid">Invalid</option>
                                    <option value="toll_free">Toll Free</option>
                                    <option value="voip">Voip</option>
                                </select>
                            </div>
                        </div>
                        <div
                            class="d-sm-inline d-flex select-menu align-items-center justify-content-between mb-sm-0 mb-3 me-sm-5 mb-2">
                            <div class="d-inline-block label">DNC Type:</div>
                            <div class="d-inline-block">
                                <select class="form-select ms-3" title="Pick One" v-model="dncType"
                                    @change="page = 1; getFiles()">
                                    <option value="">All</option>
                                    <option value="0">Clean</option>
                                    <option value="1">DNC</option>
                                </select>
                            </div>
                        </div>
                        <!-- <div class="d-lg-inline px-sm-0 px-4 me-2  mb-2">
                        <button type="button" class="addCreditBtn  btn mb-0 btn-sm text-white">Save</button>
                    </div> -->
                    </div>
                    <div class="col-lg-4 col-12 mt-2">
                        <div class="input-group search-form py-2 rounded-3 border">
                            <span class="input-group-text border-0 px-3 py-1" id="basic-addon2">
                                <img src="@/assets/img/search.svg" class="" alt="">
                            </span>
                            <input type="search" v-model="search" id="form2"
                                class="form-control border-0 shadow-none py-1 ps-4" placeholder="Search by File Name"
                                aria-label="Search" />
                        </div>
                    </div>
                </div>
                <div class="px-sm-0 px-4">
                    <p class="welcome-Text">Note: Filters will only be active upon export of file. To see all results,
                        ensure both filters show 'All'.</p>
                </div>
            </div>
        </section>
        <section class="pb-5 px-lg-5 px-2 ">
            <div class="container-fluid px-lg-0 px-2  phone-logs-search-form">
                <div class="row justify-content-end">


                </div>
            </div>
        </section>
        <section class="data-table pb-5 px-lg-4" v-if="isDesktopView">
            <div class="container-wrapper table-responsive container-fluid px-lg-0 px-2 pt-1  mt-2 pb-4">
                <table class="table table-lg table-hover mt-1">
                    <thead>
                        <tr class="TableText-Text">
                            <th class="col-1">#</th>
                            <th class="col">File name</th>
                            <th class="col">UPLOADED AT</th>
                            <th class="col">Status</th>
                            <th class="col">Total Rows</th>
                            <th class="col"> Duplicates</th>
                            <th>Credits Charged</th>
                            <th>FULL DETAIL </th>
                            <th>OUTPUT FILE</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-if="isFilesLoading">
                            <tr>
                                <td class="text-center" colspan="9">Loading... <i class="fa fa-circle-o-notch fa-spin"></i>
                                </td>
                            </tr>
                        </template>
                        <template v-else>
                            <template v-if="ExportFiles?.files?.length">
                                <tr :class="{ 'border-top-primary': index == 0 }" v-for="file, index in  ExportFiles?.files "
                                    :key="index">
                                    <td>
                                        <span>{{ (index + 1) + ((page - 1) * per_page) }}</span>
                                    </td>
                                    <td>
                                        {{ file.file_name }}
                                    </td>
                                    <td>
                                        {{ getTime(file.last_updated) }}
                                    </td>
                                    <td>
                                        <i class="fal fa-check fa-lg" v-if="file.file_url && file.file_url !== ''"></i>
                                        <span v-else> <i class="fa fa-circle-o-notch fa-spin"></i></span>
                                    </td>
                                    <td>
                                        <span v-if="file.file_url && file.file_url !== ''">
                                            {{ file.total_records }}
                                        </span>
                                        <span v-else> <i class="fa fa-circle-o-notch fa-spin"></i></span>
                                    </td>
                                    <td>
                                        <span v-if="file.file_url && file.file_url !== ''">{{ file.credits_reversed ?
                                            file.credits_reversed : '0' }}</span>
                                        <span v-else> <i class="fa fa-circle-o-notch fa-spin"></i></span>
                                    </td>
                                    <td>
                                        <span v-if="file.file_url && file.file_url !== ''">{{ file.file_url == 'Failed' ? 0
                                            : file.credits_charged ? file.credits_charged : 0 }}</span>
                                        <span v-else> <i class="fa fa-circle-o-notch fa-spin"></i></span>
                                    </td>
                                    <td class="text-center">
                                        <template v-if="file.file_url && file.file_url !== ''">
                                            <button type="button" class="btn getcsvbtn-export mt-3 shadow-none"
                                                :class="{ 'mb-1': file?.file_method == 'upload' }"
                                                @click="goTo(file)">View</button>
                                            <div class="badge badge-success mx-auto d-block" style="width: max-content;"
                                                v-if="file?.file_method == 'upload'">Direct DB</div>
                                        </template>
                                        <span v-else> <i class="fa fa-circle-o-notch fa-spin"></i></span>
                                    </td>
                                    <td class="text-center">
                                        <div class="align-items-end text-center position-relative"
                                            v-if="file.file_url && file.file_url !== ''"
                                            v-on:click="$event.stopPropagation();">
                                            <span v-if="file.file_url == 'Failed'">Failed</span>
                                            <button v-else type="button" class="btn getcsvbtn mb-0"
                                                @click=" downloadURI(file)">Get
                                                CSV</button>
                                        </div>
                                        <span v-else> <i class="fa fa-circle-o-notch fa-spin"></i></span>
                                    </td>
                                </tr>
                            </template>
                            <template v-else>
                                <tr>
                                    <td class="text-center" colspan="9">No Data Found</td>
                                </tr>
                            </template>
                        </template>
                    </tbody>
                </table>
            </div>
        </section>
        <section v-else>
            <section class="pb-5 px-lg-5 px-2 ">
                <div class="container-fluid px-lg-0 px-2 datatable-sm  mt-2">
                    <div class="border-0 px-4" v-if="isFilesLoading">
                        <div class="d-flex exporttext-file">
                            <div class="px-1 col-2">
                                #
                            </div>
                            <div class="px-3 col-4">
                                File name
                            </div>
                            <div class="col-6">
                                UPLOADED<br> at
                            </div>
                        </div>
                        <div class="d-flex justify-content-center bg-light p-4 my-2 exporttext-file w-100%">
                            Loading... <i class="fa fa-circle-o-notch fa-spin"></i>
                        </div>
                    </div>
                    <template v-else>
                        <template v-if="ExportFiles?.files?.length">
                            <div class="  border-0 px-2 mb-3 " v-for=" file, index  in  ExportFiles?.files " :key="index">
                                <div class="d-flex exporttext-file">

                                    <div class="px-1 col-2">
                                        #
                                    </div>
                                    <div class="px-3 col-4">
                                        File name
                                    </div>
                                    <div class="col-6">
                                        UPLOADED<br> at
                                    </div>
                                </div>

                                <div class="d-flex bg-light p-4 mb-2 mt-2 exporttext-file w-100%">
                                    <div>
                                        {{ (index + 1) + (page * per_page) }}
                                    </div>
                                    <div class="px-2 col-6">
                                        {{ file.file_name }}
                                    </div>
                                    <div class="px-2 col-6">
                                        {{ getTime(file.last_updated) }}
                                    </div>
                                </div>
                                <div class="row text-center  mt-1  border-0">
                                    <template v-if="file.file_url && file.file_url !== ''">
                                        <button type="button" :class="{ 'mb-1': file?.file_method == 'upload' }"
                                            class="getcsvbtn-export   btn mb-0 btn-sm text-white"
                                            @click=" goTo(file)">View</button>
                                        <div class="badge badge-success mx-auto d-block" style="width: max-content;"
                                                v-if="file?.file_method == 'upload'">Direct DB</div>
                                    </template>
                                    <span v-else> <i class="fa fa-circle-o-notch fa-spin"></i></span>
                                </div>
                                <div class="row text-center  mt-1  border-0 ">
                                    <button v-if="file.file_url && file.file_url !== ''" class="btn addCreditBtn mt-2 "
                                        @click=" downloadURI(file)">
                                        Get CSV
                                    </button>
                                    <span v-else> <i class="fa fa-circle-o-notch fa-spin"></i></span>
                                    <!-- <template v-if="ismobileDownloadOpen && selectedIndex == index">
                                        <div class="align-items-end text-end position-relative"
                                            v-on:click="$event.stopPropagation();">
                                            <div class="download-csv-step download-csv-step-userlogs me-md-4 me-0  w-100  p-2  text-end"
                                                style="max-width: 308px;  ">
                                                <div class="download-email-phone text-start px-3 py-2 col-sm-12 "
                                                    @click="downloadURI(file)">
                                                    <span v-if="isDownloadUri">
                                                        Loading... <i class="fa fa-circle-o-notch fa-spin"></i>
                                                    </span>
                                                    <span v-else>
                                                        Download CSV

                                                    </span>
                                                </div>
                                                <div class="col-lg-11 mx-auto  download-csv-border  my-2">
                                                </div>
                                                <div :disabled="isEmailSending"
                                                    class="Email-text-phonelogs text-start px-3 py-2 " @click="mail(file)">
                                                    <span v-if="isEmailSending">
                                                        Loading... <i class="fa fa-circle-o-notch fa-spin"></i>
                                                    </span>
                                                    <span v-else>
                                                        Email CSV
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </template> -->
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="border-0 px-4">
                                <div class="d-flex exporttext-file">
                                    <div class="px-1 col-2">
                                        #
                                    </div>
                                    <div class="px-3 col-4">
                                        File name
                                    </div>
                                    <div class="col-6">
                                        UPLOADED<br> at
                                    </div>
                                </div>
                                <div class="d-flex justify-content-center bg-light p-4 my-2 exporttext-file w-100%">
                                    No Data Found
                                </div>
                            </div>
                        </template>
                    </template>
                </div>
            </section>
        </section>
        <div class="px-lg-5 px-0 mt-2 mb-5">
            <button class="btn me-2" @click=" prevPage()"
                :disabled="ExportFiles.page == 1 || ExportFiles.total_files_count == 0">
                <i class="fa-solid fa-chevron-left"></i>
            </button>
            <button class="btn me-2" @click=" nextPage()"
                :disabled="ExportFiles.page == totalPages || ExportFiles.total_files_count == 0">
                <i class="fa-solid fa-chevron-right"></i>
            </button>
        </div>
    </main>
</template>    
<script>
import moment from "moment";
import Api from "@/Api";
import { db } from "@/firebaseConfig";
import useStorage from "@/composables/useStorage";
import axios from "axios";

export default {
    name: "Export",
    props: ['userInformation'],
    emits: ['userInformation'],
    data() {
        return {
            isDesktopView: window.matchMedia("(min-width: 600px)").matches,
            isDownloadOpen: false,
            selectedIndex: null,
            ExportFiles: [],
            isFilesLoading: false,
            searchInput: '',
            per_page: 10,
            search: '',
            ismobileDownloadOpen: false,
            page: 1,
            totalPages: 1,
            isEmailSending: false,
            isDownloadUri: false,
            userInfo: null,
            dncType: '',
            lineType: '',

        }
    },
    mounted() {
        this.userInfo = Api.getUserSession()
        window.addEventListener("resize", this.myEventHandler);
        this.getFiles()

    },
    methods: {
        downloadURI(item) {
            if (!item.file_url) {
                this.$swal({
                    title: 'Error',
                    text: 'No file found',
                    icon: 'error',
                    showCancelButton: false,
                    cancelButtonText: 'Cancel',
                    confirmButtonText: 'Close',
                    customClass: {
                        confirmButton: 'btn bg-gradient-success',
                        cancelButton: 'btn bg-gradient-danger',
                    },
                    buttonsStyling: false,
                })
                return;
            }
            this.isDownloadUri = true;
            if (this.dncType !== '' || this.lineType !== '') {
                this.filterCsv(item)

            } else {
                var link = document.createElement("a");
                link.download = item.file_name;
                link.href = item.file_url;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            this.isDownloadUri = false;
            this.isDownloadOpen = false;
            this.ismobileDownloadOpen = false;
        },
        async filterCsv(item) {
            // console.log('item.file_url', item.file_url)
            axios.get(item.file_url).then(async (resp) => {
                // console.log('resp', resp)
                let data = resp.data
                const lines = data.split(/\r\n|\n/)
                const header = lines[0].split(',')
                console.log('header', header)
                const output = await lines.slice(1).map(line => {
                    const fields = line.split(',')
                    return Object.fromEntries(header.map((h, i) => [h, fields[i]])) // 4️⃣
                })
                console.log('dncType', this.dncType)
                let filteredData = []
                let checkDnc = '';
                checkDnc = this.dncType == 1 ? 'dnc' : 'clean';
                if (this.dncType !== '' && this.lineType !== '') {
                    // console.log('if')
                    filteredData = output.filter((el) => el["Line Type"]?.toLowerCase()?.includes(this.lineType.toLowerCase()) && el["DNC"]?.toLowerCase()?.includes(checkDnc.toLowerCase()))
                } else if (this.dncType !== '') {
                    // console.log('output',output)
                    // console.log('else if')
                    filteredData = output.filter((el) => el["DNC"]?.toLowerCase()?.includes(checkDnc.toLowerCase()))
                } else if (this.lineType !== '') {
                    // console.log('else')
                    filteredData = output.filter((el) => el["Line Type"]?.toLowerCase()?.includes(this.lineType.toLowerCase()))
                } else {
                    filteredData = output
                }
                console.log('filteredData', filteredData)
                this.exportCSVFile(header, filteredData, item.file_name)
            })
        },
        convertToCSV: async function (objArray) {
            var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
            var str = "";
            for (var i = 0; i < array.length; i++) {
                var line = "";
                for (var index in array[i]) {
                    if (line != "") line += ",";
                    line += await array[i][index];
                }
                str += line + "\r\n";
            }
            return str;
        },

        exportCSVFile: async function (headers = null, items, fileTitle) {
            // console.log('headers', headers, 'fileTitle', fileTitle)
            this.isCsvUploading = true
            if (headers) {
                items.unshift(headers);
            }
            // Convert Object to JSON
            var jsonObject = await JSON.stringify(items);
            var csv = await this.convertToCSV(jsonObject);
            var exportedFilename = fileTitle || "export.csv";
            var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
            if (navigator.msSaveBlob) {
                // IE 10+
                navigator.msSaveBlob(blob, exportedFilename);
            } else {
                var link = document.createElement("a");
                if (link.download !== undefined) {
                    // feature detection
                    // Browsers that support HTML5 download attribute
                    // if (new_upload) {

                    //     var url = URL.createObjectURL(blob);
                    //     fetch(url)
                    //         .then((res) => res.blob())
                    //         .then(async (myBlob) => {
                    //             myBlob.name = 'export.csv';
                    //             myBlob.lastModified = new Date();
                    //             const { uploadFile, url, error } = useStorage();
                    //             const myFile = new File([myBlob], 'export.csv', {
                    //                 type: myBlob.type,
                    //             });
                    //             if (myFile) {
                    //                 await uploadFile(myFile, 'temp');
                    //             }
                    //             if (!error.value) {
                    //                 let data = {
                    //                     file_name: fileTitle,
                    //                     path: url.value
                    //                 }
                    //                 this.sendMail(undefined, data)
                    //             }
                    //         });

                    // } else {
                        var uri = URL.createObjectURL(blob);
                        link.setAttribute("href", uri);
                        link.setAttribute("download", exportedFilename);
                        link.style.visibility = "hidden";
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    // }
                }
            }
        },
        async mail(item) {
            if (this.dncType !== '' || this.lineType !== '') {
                this.isEmailSending = true
                this.filterCsv(item, true)
            } else {
                this.sendMail(item)
            }
        },
        async sendMail(item = undefined, attachmentsData = undefined) {
            let attachments = []
            if (attachmentsData) {
                attachments = [
                    {
                        filename: attachmentsData.file_name,
                        path: attachmentsData.path
                    }
                ]

            } else {
                attachments = [
                    {
                        filename: item.file_name,
                        path: item.file_url
                    }
                ]
            }
            this.isEmailSending = true;
            let userAgent = window.navigator.userAgent
            let platform = window.navigator.platform
            let macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
            let windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
            let iosPlatforms = ['iPhone', 'iPad', 'iPod']
            let os = null;
            let browser = null;

            if (macosPlatforms.indexOf(platform) !== -1) {
                os = 'Mac OS';
            } else if (iosPlatforms.indexOf(platform) !== -1) {
                os = 'iOS';
            } else if (windowsPlatforms.indexOf(platform) !== -1) {
                os = 'Windows';
            } else if (/Android/.test(userAgent)) {
                os = 'Android';
            } else if (!os && /Linux/.test(platform)) {
                os = 'Linux';
            }

            if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
                browser = 'Opera';
            } else if (navigator.userAgent.indexOf("Edg") != -1) {
                browser = 'Edge';
            } else if (navigator.userAgent.indexOf("Chrome") != -1) {
                browser = 'Chrome';
            } else if (navigator.userAgent.indexOf("Safari") != -1) {
                browser = 'Safari';
            } else if (navigator.userAgent.indexOf("Firefox") != -1) {
                browser = 'Firefox';
            } else if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true)) {
                browser = 'IE';
            } else {
                browser = 'unknown';
            }
            let from = process.env.VUE_APP_EMAIL;
            await db.collection('mail').add({
                from: from,
                to: this.userInfo?.email,
                message: {
                    subject: "Download Csv link",
                    html: 'Your Csv File',
                    attachments: attachments
                },
                timestamp: moment().valueOf(),
                date: moment().format('L'),
                month: moment().format('MMMM'),
                year: moment().format('YYYY'),
                os: os,
                browser: browser,
                userId: this.userInfo.userId,
                type: 'contact'
            }).then(() => {

                if (attachmentsData) {
                    setTimeout(() => {
                        const { deleteFile, deleteError } = useStorage();
                        deleteFile(attachmentsData.path)
                        if (!deleteError.value) {
                            this.$swal({
                                title: 'Success',
                                text: 'Mail sent successfully',
                                icon: 'success',
                                showCancelButton: false,
                                cancelButtonText: 'Cancel',
                                confirmButtonText: 'Close',
                                customClass: {
                                    confirmButton: 'btn bg-gradient-success',
                                    cancelButton: 'btn bg-gradient-danger',
                                },
                                buttonsStyling: false,
                            })
                        } else {
                            this.$swal({
                                title: 'Error',
                                text: 'Error while sending mail',
                                icon: 'error',
                                showCancelButton: false,
                                cancelButtonText: 'Cancel',
                                confirmButtonText: 'Close',
                                customClass: {
                                    confirmButton: 'btn bg-gradient-success',
                                    cancelButton: 'btn bg-gradient-danger',
                                },
                                buttonsStyling: false,
                            })
                        }

                        this.isEmailSending = false
                        this.isDownloadOpen = false
                        this.ismobileDownloadOpen = false;
                    }, 5000);
                } else {
                    // console.log(res)
                    this.$swal({
                        title: 'Success',
                        text: 'Mail sent successfully',
                        icon: 'success',
                        showCancelButton: false,
                        cancelButtonText: 'Cancel',
                        confirmButtonText: 'Close',
                        customClass: {
                            confirmButton: 'btn bg-gradient-success',
                            cancelButton: 'btn bg-gradient-danger',
                        },
                        buttonsStyling: false,
                    })
                    this.isEmailSending = false
                    this.isDownloadOpen = false
                    this.ismobileDownloadOpen = false;
                }

            }).catch((error) => {
                console.error(`Error: `, error);
                this.isEmailSending = false
                this.isDownloadOpen = false
                this.ismobileDownloadOpen = false;
                return error;
            }).finally(() => {

            })
        },
        myEventHandler() {
            this.isDesktopView = window.matchMedia("(min-width: 600px)").matches
        },
        getTime(value) {
            return moment(value).format('yyyy-MM-DD\xa0\xa0\xa0h:mm:ss a')
        },
        getFiles() {
            this.ExportFiles = []
            window.scrollTo(0, 0)
            this.isFilesLoading = true;
            let params = {
                search: this.search ? this.search : '',
                page: this.page,
                per_page: this.per_page,
                dnc: this.dncType,
                line_type: this.lineType

            }
            // console.log('payload',payload)
            Api.getUserFiles(params).then((res) => {
                // console.log(res.data)
                if (res.data.page) {
                    this.page = +res.data.page
                }
                if (res.data.total_pages) {
                    this.totalPages = +res.data.total_pages
                }
                this.ExportFiles = res.data;

            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                this.isFilesLoading = false
            })
        },
        nextPage() {
            if (this.page !== this.totalPages) {
                this.page += 1
                this.getFiles()
            }
        },
        prevPage() {
            if (this.page !== 1) {
                this.page -= 1
                this.getFiles()
            }
        },
        goTo(item) {
            this.$router.push({ name: 'Fileinfo', query: { file_reference: item.file_reference } })
        }
    },
    watch: {
        search() {
            this.page = 1;
            this.getFiles()
        }
    }
}
</script>
<style lang="less" scoped>@import '@/assets/css/admin.less';</style>