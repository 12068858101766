<template>
    <main class="mt-0 main-content text-start faqContainer">
        <section class=" pb-lg-4 pt-5 px-sm-5 px-2">
            <div class="container-fluid  px-0">
                <div class="welcome-title ">Refund Policy</div>
            </div>
        </section>
        <section class="pt-lg-0 pt-5 pb-lg-4 pb-3 px-sm-5 px-0">
            <div class="welcome-section container-fluid px-lg-0 px-2 refund-policy-list">
                <div class="section-heading mt-0 mb-3">Recurring Monthly Subscription Plan Fees</div>
                <ul class="ps-3">
                    <li class="welcome-Text">
                        The Client (landlinescrubber.com Customer aka Scrubbalicious LLC Customer) is solely responsible to pay for all subscription fees and credit purchases, and authorizes Scrubbalicious LLC (and its payment processors) to debit the Client’s bank account or charge the Client’s credit card, as stated on landlinescrubber.com's billing page. The client acknowledges and agrees that all unlimited plan subscriptions are recurring and will renew automatically every month.

                        The client shall also be solely responsible for paying and remitting (to the appropriate tax authorities) all applicable taxes (withholding tax, sales tax, services tax, value-added tax (VAT), goods and services tax (GST), etc.) or duties imposed by any government entity or collecting agency. In the event Client fails to satisfy its tax and/or duty obligations, the Client shall reimburse Scrubbalicious LLC upon demand for any taxes and/or duties paid on behalf of Client and shall defend, indemnify, and hold Scrubbalicious LLC harmless against any claim and/or liability (including penalties) resulting from Client’s failure to pay such taxes and/or duties.
                    </li>
                </ul>
            </div>
        </section>

        <section class="pt-3 pb-lg-4 pb-3 px-sm-5 px-0">
            <div class="welcome-section container-fluid px-lg-0 px-2 refund-policy-list">
                <div class="section-heading mb-3">Cancellation Policy</div>
                <ul class="ps-3">
                    <li class="welcome-Text">
                        Client (Scrubbalicious LLC Customer) acknowledges that all subscription fees are charged automatically on a monthly recurring basis until the Client cancels their subscription. The client may cancel their subscription by emailing <a href="mailto:info@landlinescrubber.com">info@landlinescrubber.com.</a> You must email support prior to, or on, the payment due date in order to cancel the current payment due.
                    </li>
                </ul>
            </div>
        </section>

        <section class="pt-3 pb-lg-4 pb-3 px-sm-5 px-0">
            <div class="welcome-section container-fluid px-lg-0 px-2 refund-policy-list">
                <div class="section-heading mb-3">Refund Policy</div>
                <ul class="ps-3">
        
                    <li class="welcome-Text ">
                        The Scrubbalicious LLC unlimited service is billed in advance on a monthly basis. No refunds will be issued unless cancelled prior to the payment due date.

                        Purchasable credits are non-refundable; no refunds will be issued.

                        Scrubbalicious LLC does not offer prorations or prorated refunds for canceled subscription plans. There will be no refunds or credits for partial months of service, downgrade refunds, or refunds for months unused with an open account (See cancellation policy above). No exceptions shall be made to ensure everyone is treated equally.
                    </li>
                </ul>
                

            </div>
        
        
        </section>
    </main>

</template>

<script>
export default {

    name: "RefundPolicy",
};
</script>

<style lang="less" scoped>
@import '@/assets/css/miscPages.less';
</style>