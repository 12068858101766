<template>
    <main class="text-start api-doc">
        <section class="pt-4 pb-3 px-sm-5 px-2">
            <div class="container-fluid  px-0">
                <div class="welcome-title mt-3">API Key </div>
            </div>
        </section>
        <section class="pb-4  px-lg-5 px-2 ">
            <div class=" container-fluid  px-0 mt-3  ">
                <div class="container-wrapper  notice-wrapper  py-2 px-3" style="min-height:unset;">
                    <div class="row justify-content-between align-items-center">
                        <div class="col-lg-12 col-12 ">
                            <div class="textwelcome d-flex align-items-center g-0">
                                <img src="@/assets/img/bell-icon.svg" class="far fa-bell  Api-bell-icon">
                                <span class="px-4  Apiphone-text Api-textclick  ">Your API Key:
                                    <input id="yourApiKey" :value="userInfo?.api_key">
                                    <button @click="copyFunction" class="copyButton me-3 pt-2 pb-2 btn btn-sm text-white">Copy</button>
                                    <p id="copySuccess" style="display:none;">Copied!</p>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="text-center px-md-5 ">
                <button type="button" class="addCreditBtn  me-3 pt-2 pb-2 btn btn-sm text-white" :disabled="isProcessing"
                    @click="generateNewApiKey()">

                    <span v-if="isProcessing">
                        <i class="fa fa-circle-o-notch fa-spin"></i>
                    </span>
                    <span v-else>
                        Generate New API KEY
                    </span>

                </button>

                <span>
                    <button type="button" class=" btn addCreditBtn pb-2 pt-2 " @click="callBack()">
                        Cancel
                    </button>

                </span>
            </div>
        </section>

        <section class="pb-5 px-lg-5 px-2 ">
            <div class=" container-fluid  px-0   ">
                <div class="row justify-content-between align-items-center">
                    <div class="col-lg-12 col-12 ">
                        <div class="form-group Billing-overview-input ">
                            <div class="container-wrapper mt-2  py-2 section-label  api-get-nothing"
                                style="min-height: unset;">
                                <span class="fw-bold"> Note:</span> After Generating New API Key, Old API Key will not work
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- <section class="pb-5 px-lg-5 px-2 ">
            <div class=" container-fluid  px-lg-0   ">
                <div class="row justify-content-between align-items-center">
                    <div class="col-lg-12 col-12 ">
                        <div class="form-group Billing-overview-input ">
                            <label class="label section-heading "> API Usage Stats (Only Unique API Calls)</label>
                            <div class="container-wrapper mt-3   api-key-note   py-2 section-label"
                                style="min-height: unset;">
                                <div class="d-md-flex d-block api-key-block text-center">
                                    <div class="px-3 pt-3 pb-3 g-1 "><span class="fw-bold">Total:</span> &nbsp;&nbsp;0</div>
                                    <div class="px-3 pt-3  pb-3 g-1"><span class="fw-bold">Today:</span>&nbsp;&nbsp;0</div>
                                    <div class="px-3 pt-3 pb-3 g-1"><span class="fw-bold">This Month:</span>&nbsp;&nbsp;0
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->
    </main>
</template>    
<script>
import Api from "@/Api";
export default {
    name: "GetApi",
    props:['userInformation'],
    emits:['userInformation'],
    data() {
        return {
            isFormUploading: false,
            formResponse: null,
            isProcessing: false,
            userInfo:null
        }
    },
    methods: {
        callBack() {
            return this.$router.push({ name: 'apidoc' })

        },
        generateNewApiKey() {
            this.isProcessing = true;
            Api.updateApiKey().then(() => {
                Api.getUserInfo().then(async (res) => {
                    if (res?.data?.auth_token) {
                        await Api.saveUserSession(res?.data)
                        this.userInfo = await Api.getUserSession()
                    }
                }).catch((err) => {
                    console.log('Error: ', err)
                })
            }).catch((err) => {
                console.log("error", err)
            }).finally(() => {
                this.isProcessing = false
            })
        },
        copyFunction() {
            // Get the text field
            var copyText = document.getElementById("yourApiKey");

            // Select the text field
            copyText.select();
            copyText.setSelectionRange(0, 99999); // For mobile devices

            // Copy the text inside the text field
            navigator.clipboard.writeText(copyText.value);

            document.getElementById('copySuccess').setAttribute('style', 'display:inline-block;');
            setTimeout(function(){
                document.getElementById('copySuccess').setAttribute('style', 'display:none;');
            }, 3000);
        }
    },
    mounted() {
        this.userInfo =  Api.getUserSession()
    }
}
</script>
<style lang="less" scoped>@import '@/assets/css/admin.less';</style>