<template>
  <main class="mt-0 main-content forgot-wrapper">
    <section v-if="!linkSent">
      <div class="container">
        <div class="forgotWrapper">
          <div class="forgotHeader">
            <h1>Forgot your password?</h1>
            <p>Change your password in three easy steps. This helps to keep your new password secure.</p>
            <ol>
              <li>Fill in your email address below.</li>
              <li>We'll email you a reset link.</li>
              <li>Use the link to reset your password.</li>
            </ol>
          </div>
          <div class="forgotBody">
            <div class="userInfo">
              <form role="form" @submit.prevent="handleSubmit" class="text-start">
                <div class="mb-3">
                  <input id="email" type="email" class="form-control" placeholder="EMAIL ADDRESS*" v-model="email"
                     />
                  <span class="text-danger" v-if="showError && errors['email']">
                    <small>{{ errors['email'] }}</small>
                  </span>
                </div>

                <div class="error text-sm text-danger">{{ error }}</div>

                <div class="">
                  <button class="submit-btn-forgot mb-3" @click="passwordResetLink" :disabled="isProcessing">
                    <span v-if="isProcessing">
                      Sending... <i class="fa fa-circle-o-notch fa-spin"></i>
                    </span>
                    <span v-else>
                      Send Reset Password Link
                    </span>
                  </button>
                  <!-- <div> If you did not receive an email, <a href="#">click here to resend e-mail.</a></div> -->
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>
    </section>
    <section v-else>
      <div class="container verifyWrapper-container">
        <div class="verifyWrapper">
          <div class="verifyHeader mb-sm-3 mb-4">Password Reset Link Sent.
          </div>
          <div class="verifyBody">
            <div class="mb-3">{{ success }}</div>
            <!-- <div class="mb-sm-0 mb-3"> We have sent you an e-mail. If you have not received it please check your spam
              folder.</div>
            <div class="mb-sm-0 mb-3">Otherwise contact us if you do not receive it in a few minutes.</div> -->
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import 'firebase/auth'
import Api from '@/Api';
// import ArgonInput from "@/components/ArgonInput.vue";
// import ArgonSwitch from "@/components/ArgonSwitch.vue";
// import ArgonButton from "@/components/ArgonButton.vue";
// import ArgonAlert from "@/components/ArgonAlert.vue";
const body = document.getElementsByTagName("body")[0];
export default {
  name: "Forgot",
  components: {
    //   ArgonInput,
    //   ArgonSwitch,
    //   ArgonButton,
    // ArgonAlert,
  },
  data() {
    return {
      isProcessing: false,
      email: '',
      error: null,
      linkSent: false,
      errors: {},
      success:'',
      showError: false
    }
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
  methods: {
    async isSendLinkFormValid() {
      this.errors = {}
      //eslint-disable-next-line
      let reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/

      if (!reg.test(this.email)) {
        this.errors['email'] = "Invalid Email Format"
      }
      if (this.email == '') {
        this.errors['email'] = "Email is Required"
      }
    },
    async passwordResetLink() {
      this.isSendLinkFormValid()
      this.isProcessing = true
      this.showError = true;
      if (Object.keys(this.errors).length) {
        return
      }
      Api.sendResetPasswordLink({ user_login_email: this.email }).then((res) => {
        this.success = res.data.message
        this.errors = {}
        this.showError = false
        this.email = ''
        this.linkSent = true
        // this.$swal({
        //   title: 'Success',
        //   text: res.data.message,
        //   icon: 'success',
        //   showCancelButton: false,
        //   cancelButtonText: 'Cancel',
        //   confirmButtonText: 'Close',
        //   customClass: {
        //     confirmButton: 'btn bg-gradient-success',
        //     cancelButton: 'btn bg-gradient-danger',
        //   }
        // })
      }).catch((err) => {
        this.$swal({
          title: 'Error',
          text: err.response.data.message,
          icon: 'error',
          showCancelButton: false,
          cancelButtonText: 'Cancel',
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn bg-gradient-success',
            cancelButton: 'btn bg-gradient-danger',
          }
        })
      }).finally(() => {
        this.isProcessing = false
      })
    }
  },
  watch: {
    email() {
      this.isSendLinkFormValid()
    },
  }
};
</script>

<style lang="less" scoped>
@import '@/assets/css/forgot.less';
</style>
<style lang="less" scoped>
@import '@/assets/css/miscPages.less';
</style>
<style>
.forgot-wrapper,
.forgot-wrapper .container {
  min-height: 75vh !important;
}
</style>