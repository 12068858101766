<template>
  <div>
    <p>Placeholder for Navigation</p>
  </div>
</template>
<script>
export default {
  name: "Navbar",
  data() {
    return {
    };
  },  
  computed: {
  },
  created() {
    this.minNav;
    this.changeTypeValue;
  },
  mounted() {
  },
};
</script>
