import { ref } from 'vue'
import { auth, } from '@/firebaseConfig'
// import { useRouter } from 'vue-router';

// refs
const user = ref(auth.currentUser)

// listen for auth changes outside of function
// so only 1 listener is ever attached

const getUser = () => {
  auth.onAuthStateChanged(_user => {
    // console.log('User state change. Current user is:', _user)
    if (_user) {
      user.value = _user
    }
  });
  return { user }
}

export default getUser