<template>
    <main class="text-start purchase-wrapper">
        <section class="pt-3 pb-sm-4 pb-3 px-sm-5 px-0">
            <div class="welcome-section container-fluid px-lg-0 px-2">
                <div>
                    <div class="welcome-title my-3">Purchase Credits</div>
                </div>
            </div>
        </section>
        <!-- billing overview and Information -->
        <section class="pb-5 px-sm-5 px-2">
            <div class="container-fluid Billing-overview container-wrapper  border-top-primary">
                <div class="row justify-content-between">
                    <div class="col-lg-6  mb-lg-4 mb-3">
                        <div class="section-label">USER SETTINGS</div>
                        <h1 class="section-heading">Bulk Credit Purchase</h1>
                        <div class="px-2">
                            <div class="credit-radio mt-4">
                                <div v-if="isPrePlansLoading" class="h5 text-center">
                                    Loading... <i class="fa fa-circle-o-notch fa-spin"></i>
                                </div>
                                <template v-else>
                                    <div class="form-check d-block" v-for="item, i in prePlans" :key="i">
                                        <input @click="selectedBuyPlan = item" class="form-check-input mt-2" type="radio"
                                            name="flexRadioDefault" :id="'flexRadioDefault1' + i"
                                            :checked="selectedBuyPlan?.plan_reference == item.plan_reference">
                                        <label class="form-check-label  " :for="'flexRadioDefault1' + i">
                                            <div class="text-darkblue d-inline-block" style="min-width: 160px;">{{
                                                formatCredits(item.plan_credits) }}&nbsp;Credits </div>
                                            <div class="text-black d-inline-block" style="min-width: 60px;">
                                                ${{ formatCredits(item.plan_amount) }} </div>
                                            <div class="d-inline-flex text-end" style="min-width:100px">
                                                <span class="mx-3 text-black d-sm-block d-none"> -</span>
                                                <span class="ms-md-auto d-inline-block text-pink">{{ item.percent_off
                                                }}</span>
                                            </div>
                                        </label>
                                    </div>
                                </template>
                            </div>
                            <button type="button"
                                :disabled="isPrePlansLoading || prePlans.filter((el) => el.plan_reference == selectedBuyPlan?.plan_reference).length < 1 || isPaymentProcessing"
                                class="addCreditBtn my-4 btn btn-sm text-white" @click="openBuyModal()">
                                Add Credits
                            </button>
                            <div class="form-group Billing-overview-input mb-3 pb-1 text-end border-bottom">
                                <!-- <input type="text"    class="form-control w-100 border-top-0 border-start-0 border-end-0 rounded-0 "> -->
                                <router-link :to="`/card?returnUrl=${$route.name}`" class="card-text">Update Credit Card
                                    Info</router-link>
                            </div>
                            <div class="row  mt-4 mb-2 align-items-center justify-content-between">
                                <div class="section-heading mt-0 col-lg-8"> CURRENT CREDIT BALANCE</div>
                                <div class="num-Text mt-0 col-lg-4 text-lg-end">
                                    <img class="groupImg" src="@/assets/img/phone.svg">
                                    {{ formatCredits(userInfo?.total_credits_balance)
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 ps-lg-5 mb-lg-4 mb-3">
                        <div class="section-label">&nbsp;</div>
                        <h1 class="section-heading mb-5 text-capitalize">Pay as you Go Plans</h1>
                        <div v-if="isPrePlansLoading" class="h5 text-center">
                            Loading... <i class="fa fa-circle-o-notch fa-spin"></i>
                        </div>
                        <template v-else>
                            <div class="elite-scrubber scrubber row g-0 mx-lg-0 mx-auto align-items-center"
                                v-for="items, i in postPlans" :key="i"
                                :class="i == 0 ? 'elite-scrubber' : i == 1 ? 'power-scrubber' : 'standard-scrubber'">
                                <div class="col-sm-3 col-12  ">
                                    <div class="scrubber-text h-100 px-lg-5 px-2">
                                        <div class="text-pink">{{ items.plan_name }}</div>
                                        <div class="text-black">{{ items.plan_subtitle }}</div>
                                        <!-- <div class="bottborder col-sm-3">
                                        <br>
                                    </div> -->
                                    </div>
                                </div>
                                <div class="col-sm-3 col-12 px-3 pricing">
                                    <div class="amount-text  h-100 ">${{ items.plan_amount }}</div>
                                    <div class="amount-label mt-2">{{ items.percent_off }}</div>
                                </div>
                                <div class="col-sm-6 col-12 border-end get-plan border-2 text-center ">
                                    <div class="label">{{ items.plan_credits_text }}</div>
                                    <div class="label text-sm text-capitalize">{{ items.plan_includes }}</div>
                                    <button class="addCreditBtn getplanbtn mt-1 btn btn-sm text-white mb-0"
                                        :class="userInfo?.user_plan?.plan_reference == items.plan_reference ? 'current-plan-btn' : ''"
                                        @click="selectedBuyPlan = items; openBuyModal();"
                                        :disabled="isPaymentProcessing || userInfo?.user_plan?.plan_reference == items.plan_reference">
                                        <span>
                                            {{ userInfo?.user_plan?.plan_reference == items.plan_reference ? 'Current Plan'
                                                :
                                                'Select Plan' }}
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </section>


        <section class="payment-confirm-modal">
            <button type="button" class="btn btn-primary d-none" data-bs-toggle="modal" id="openBuyStandardAndPowerModal"
                data-bs-target="#buyStandardAndPowerModal">
                modal
            </button>
            <div class="modal fade" id="buyStandardAndPowerModal" aria-labelledby="buyStandardAndPowerModal"
                aria-hidden="true">
                <div class="modal-dialog modal-xl ">
                    <div class="modal-content">
                        <div class="modal-header border-0">
                            <div class="modal-title " id="exampleModalLabel ">Payment Confirmation Alert
                            </div>
                            <div type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></div>
                        </div>
                        <div class="modal-body pt-0">
                            <section class="pb-4  px-lg-4 px-2 ">
                                <div class=" container-fluid  px-lg-0 mt-3  ">
                                    <div class="container-wrapper  notice-wrapper  py-2 px-3" style="min-height:unset;">
                                        <div class="row justify-content-between align-items-center">
                                            <div class="col-lg-12 col-12 ">
                                                <div class="textwelcome   d-flex align-items-center g-0 pt-4 pb-4">
                                                    <div class="col-md-6 text-start mb-md-0 mb-3">
                                                        Are you sure you want to buy {{ selectedBuyPlan?.plan_name + ' ' +
                                                            selectedBuyPlan?.plan_subtitle }} ?
                                                    </div>
                                                    <div class="col-md-6 mb-md-0 mb-3">
                                                        <div class="form-group text-end">
                                                            <label class="d-inline-block me-2">Select Amount:</label>
                                                            <select class="form-select w-auto d-inline-block"
                                                                style="min-width: 100px;" v-model="selectedAmount"
                                                                aria-label="Default select example">
                                                                <option selected value="">Select</option>
                                                                <template v-if="$route.query.plan == '79541679412216' || selectedBuyPlan?.plan_reference == '79541679412216'">
                                                                    <option value="83">$83</option>
                                                                    <option value="158">$158</option>
                                                                    <option value="744">$744</option>
                                                                    <option value="1400">$1400</option>
                                                                </template>
                                                                <template v-else>
                                                                    <option value="5">$5</option>
                                                                    <option value="10">$10</option>
                                                                    <option value="50">$50</option>
                                                                </template>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="  text-end mt-4">
                                    <button type="button" id="closeBuyStandardAndPowerModal"
                                        class="col-lg-2 addCreditBtn getplanbtn current-plan-btn me-3"
                                        data-bs-dismiss="modal" :disabled="isPaymentProcessing">Cancel</button>
                                    <button type="button" class=" col-lg-2 addCreditBtn getplanbtn"
                                        @click="buyStandardAndPower"
                                        :disabled="selectedAmount == '' || isPaymentProcessing">
                                        <span v-if="isPaymentProcessing">
                                            <i class="fa fa-circle-o-notch fa-spin"></i>
                                        </span>
                                        <span v-else>
                                            Confirm
                                        </span>
                                    </button>
                                </div>

                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="payment-confirm-modal">
            <button type="button" class="btn btn-primary d-none" data-bs-toggle="modal" id="openPaymentModal"
                data-bs-target="#paymentConfirmationModal">
                modal
            </button>
            <div class="modal fade" id="paymentConfirmationModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl ">
                    <div class="modal-content">
                        <div class="modal-header border-0">
                            <div class="modal-title " id="exampleModalLabel ">Payment Confirmation Alert
                            </div>
                            <div type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></div>
                        </div>
                        <div class="modal-body pt-0">
                            <section class="pb-4  px-lg-4 px-2 ">
                                <div class=" container-fluid  px-lg-0 mt-3  ">
                                    <div class="container-wrapper  notice-wrapper  py-2 px-3" style="min-height:unset;">
                                        <div class="row justify-content-between align-items-center">
                                            <div class="col-lg-12 col-12 ">
                                                <div class="textwelcome   d-flex align-items-center g-0 pt-4 pb-4">
                                                    <div class="col-md-6 text-start mb-md-0 mb-3">
                                                        Are you sure you want to buy {{ selectedBuyPlan?.plan_name + ' ' +
                                                            selectedBuyPlan?.plan_subtitle }} ?
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class=" text-end mt-4">
                                    <button type="button" id="closePaymentModal"
                                        class="col-lg-2 addCreditBtn getplanbtn current-plan-btn me-3"
                                        data-bs-dismiss="modal" :disabled="isPaymentProcessing">Cancel</button>
                                    <button type="button" class=" col-lg-2 addCreditBtn getplanbtn"
                                        @click="buyBulkCredits()" :disabled="isPaymentProcessing">
                                        <span v-if="isPaymentProcessing">
                                            <i class="fa fa-circle-o-notch fa-spin"></i>
                                        </span>
                                        <span v-else>
                                            Confirm
                                        </span>
                                    </button>
                                </div>

                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="payment-confirm-modal">
            <button type="button" class="btn btn-primary d-none" data-bs-toggle="modal" id="openAccountModal"
                data-bs-target="#accountModal">
                modal
            </button>
            <div class="modal fade" id="accountModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl ">
                    <div class="modal-content">
                        <div class="modal-header border-0">
                            <div class="modal-title " id="exampleModalLabel ">Account Confirmation Alert
                            </div>
                            <div type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></div>
                        </div>
                        <div class="modal-body pt-0">
                            <section class="pb-4  px-lg-4 px-2 ">
                                <div class=" container-fluid  px-lg-0 mt-3  ">
                                    <div class="container-wrapper  notice-wrapper  py-2 px-3" style="min-height:unset;">
                                        <div class="row justify-content-between align-items-center">
                                            <div class="col-lg-12 col-12 ">
                                                <div class="textwelcome   d-flex align-items-center g-0 pt-4 pb-4">
                                                    <div class="col-12 text-start mb-md-0 mb-3">
                                                        please activate your account first ?
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="  text-end mt-4">
                                    <button type="button" id="closeAccountModal"
                                        class="col-lg-2 addCreditBtn getplanbtn current-plan-btn me-3"
                                        data-bs-dismiss="modal" :disabled="isPlanProcessing">Cancel</button>
                                    <button type="button" class=" col-lg-2 addCreditBtn getplanbtn" @click="pausePlan()"
                                        :disabled="isPlanProcessing">
                                        <span v-if="isPlanProcessing">
                                            <i class="fa fa-circle-o-notch fa-spin"></i>
                                        </span>
                                        <span v-else>
                                            Confirm
                                        </span>
                                    </button>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </main>
</template>
<script>
import Api from "@/Api";

export default {
    name: "Purchase",

    props: ['userInformation'],
    emits: ['userInformation'],
    data() {
        return {
            postPlans: [],
            isPostPlansLoading: true,
            isPaymentProcessing: false,
            prepaidPlanSelectedPlanIndex: null,
            postPaidSelectedPlanIndex: null,
            isPrePlansLoading: true,
            prePlans: [],
            selectedBuyPlan: null,
            selectedAmount: '',
            isPlanProcessing: false,
            selectedPlan: null,
            payment_method_exists: false,
            payment_customer_exists: false,
            userInfo: null
        }
    },
    methods: {

        formatCredits(number) {
            if (number && Number.isInteger(number)) {
                return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else {
                return number
            }
        },
        async openBuyModal() {
            if (this.userInfo?.user_plan_status !== '' && this.userInfo?.user_plan_status == 'paused') {
                document.getElementById('openAccountModal').click()
                return;
            }
            this.payment_customer_exists = await Api.payment_customer_exists()
            this.payment_method_exists = await Api.payment_method_exists()

            this.userInfo = Api.getUserSession()
            if (!this.userInfo) {
                this.$router.push({ name: 'Signup' })
            } else if (this.payment_customer_exists) {
                if (!this.payment_method_exists) {
                    if (this.selectedBuyPlan.plan_reference == '79541679412215' || this.selectedBuyPlan.plan_reference == '79541679412216') {
                        document.querySelector("#openBuyStandardAndPowerModal").click();
                    } else {
                        this.$router.push({ name: 'Card', query: { returnUrl: this.$route.name, reference_code: this.selectedBuyPlan.plan_reference } })
                    }
                } else {
                    this.selectedAmount = ''
                    if (this.selectedBuyPlan.plan_reference == '79541679412215' || this.selectedBuyPlan.plan_reference == '79541679412216') {
                        document.querySelector("#openBuyStandardAndPowerModal").click();
                    } else {
                        document.querySelector("#openPaymentModal").click();
                    }
                }

            } else if (this.selectedBuyPlan.plan_reference == '79541679412215' || this.selectedBuyPlan.plan_reference == '79541679412216') {
                document.querySelector("#openBuyStandardAndPowerModal").click();
            } else {
                this.$router.push({ name: 'Card', query: { returnUrl: this.$route.name, reference_code: this.selectedBuyPlan.plan_reference } })
            }
        },
        pausePlan() {
            this.isPlanProcessing = true;
            let obj = {
                status: this.userInfo?.user_plan?.plan_status == 'active' ? 'paused' : 'active'
            }
            Api.updatePurchaseStatus(obj).then((res) => {
                // console.log(res)
                this.$swal({
                    title: 'Success',
                    text: res.data.message,
                    icon: 'success',
                    showCancelButton: false,
                    cancelButtonText: 'Cancel',
                    confirmButtonText: 'Close',
                    customClass: {
                        confirmButton: 'btn bg-gradient-success',
                        cancelButton: 'btn bg-gradient-danger',
                    }
                })
                Api.getUserInfo().then(async (res) => {
                    if (res?.data?.auth_token) {
                        await Api.saveUserSession(res?.data)
                        this.userInfo = await Api.getUserSession();
                    }
                }).catch((err) => {
                    console.log('Error: ', err)
                })
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                this.isPlanProcessing = false
                setTimeout(() => {
                    document.getElementById('closeAccountModal').click()


                }, 500);

            })
        },

        buyStandardAndPower() {
            if (!this.payment_customer_exists || !this.payment_method_exists) {
                document.getElementById('closeBuyStandardAndPowerModal').click()
                this.$router.push({ name: 'Card', query: { returnUrl: 'Purchase', reference_code: this.selectedBuyPlan?.plan_reference, selectedAmount: this.selectedAmount } })
                return
            }
            let obj = {
                reference_code: this.selectedBuyPlan.plan_reference,
                amount: this.selectedAmount,
                currency: "usd",
            }
            this.isPaymentProcessing = true
            Api.buyPlan(obj).then((res) => {
                this.$swal({
                    title: res.data.message,
                    text: 'Your payment was successfull',
                    icon: 'success',
                    showCancelButton: false,
                    cancelButtonText: 'Cancel',
                    confirmButtonText: 'Close',
                    customClass: {
                        confirmButton: 'btn bg-gradient-success',
                        cancelButton: 'btn bg-gradient-danger',
                    },
                    buttonsStyling: false,
                })
                Api.getUserInfo().then(async (res) => {
                    if (res?.data?.auth_token) {
                        await Api.saveUserSession(res?.data)
                        this.userInfo = await Api.getUserSession()
                    }
                }).catch((err) => {
                    console.log('Error: ', err)
                })
                this.isPaymentProcessing = false;
                setTimeout(() => {
                    document.getElementById('closePaymentModal').click()
                    document.getElementById('closeBuyStandardAndPowerModal').click()
                    this.$router.push({ name: 'AccountInfo' })
                }, 500);
            }).catch((err) => {
                this.isPaymentProcessing = false;
                console.log(err)
            })
        },
        buyBulkCredits() {
            if (this.selectedBuyPlan == null) {
                return false
            }
            let obj = {
                reference_code: this.selectedBuyPlan.plan_reference,
                currency: "usd",
            }
            console.log('obj', obj)
            this.isPaymentProcessing = true
            Api.buyPlan(obj).then((res) => {
                Api.getUserInfo().then(async (res) => {
                    if (res?.data?.auth_token) {
                        await Api.saveUserSession(res?.data)
                        this.userInfo = await Api.getUserSession()
                    }
                }).catch((err) => {
                    console.log('Error: ', err)
                })
                this.$swal({
                    title: res.data.message,
                    text: 'Your payment was successfull',
                    icon: 'success',
                    showCancelButton: false,
                    cancelButtonText: 'Cancel',
                    confirmButtonText: 'Close',
                    customClass: {
                        confirmButton: 'btn bg-gradient-success',
                        cancelButton: 'btn bg-gradient-danger',
                    },
                    buttonsStyling: false,
                })
                this.isPaymentProcessing = false;
                setTimeout(() => {
                    document.getElementById('closePaymentModal').click()
                    document.getElementById('closeBuyStandardAndPowerModal').click()
                    this.$router.push({ name: 'AccountInfo' })
                }, 500);
            }).catch((err) => {
                this.isPaymentProcessing = false;
                console.log('err', err)
            })
        }
    },

    mounted() {
        this.userInfo = Api.getUserSession()
        Api.getPlans().then((res) => {
            if (this.$route.query.plan) {
                this.selectedPlan = this.$route.query.plan;
                this.selectedBuyPlan = res.data.plans.find((el) => el.plan_reference == this.selectedPlan)
                this.openBuyModal()
            }
            this.postPlans = res.data.plans.filter((el) => el.plan_type == 'postpaid')
            this.prePlans = res.data.plans.filter((el) => el.plan_type == 'prepaid')
            this.isPostPlansLoading = false;
            this.isPrePlansLoading = false
        })
    }
}
</script>
<style lang="less" scoped>
@import '@/assets/css/admin.less';
</style>