import { storage } from '@/firebaseConfig'
import moment from 'moment/moment'
import { ref } from "vue"

/* const { user } = getUser() */

const useStorage = () => {
  const error = ref(null)
  const url = ref(null)
  const filePath = ref(null)
  const deleteError = ref(null);

  const uploadFile = async (file, path = undefined) => {
    /* filePath.value = `covers/${user.value.uid}/${file.name}` */
    console.log('filePathname', file.name)
    if(path) {
      filePath.value = `${path}/${moment().valueOf()}_${file.name}`
    } else {
      filePath.value = `files_scrubber/${moment().valueOf()}_${file.name}`
    }
    const storageRef = storage.ref(filePath.value)


    try {
      const res = await storageRef.put(file)
      url.value = await res.ref.getDownloadURL()
      console.log("File URL: ", url.value);
    } catch (err) {
      console.log('Upload Error: ', err.message)
      error.value = err
    }
  }

  const deleteFile = async (path) => {  
    // const storageRef = storage.ref()
    let pictureRef = storage.refFromURL(path)
    try {
      await pictureRef.delete().then(() => {
        // console.log('File deleted successfully');
        deleteError.value = ''
      }).catch((err) => {
        console.log('error while deleting file ', err)
        deleteError.value = 'error while deleting file'
      })
    } catch (error) {
      console.log('error while deleting file ', error)
      deleteError.value = 'error while deleting file'
    }
  }

  return { uploadFile, deleteFile, deleteError, url, filePath, error }
}

export default useStorage;