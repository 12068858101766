<template>
    <main class="text-start admin-wrapper main-content"
        @click="showUserList = false; filterUserInput = ''; showAdjustCreditsBox = false; showDeleteUserList = false; filterUserInput = ''">
        <section class="pt-3 pb-lg-4 pb-3 px-sm-5 px-0">
            <div class="welcome-section container-fluid px-0 row">
                <div class="col-md-6 col-12">
                    <div class="welcome-title my-3">Admin Page</div>
                </div>
                <div class="col-md-6 col-12 text-md-end">
                    <router-link :to="{ name: 'UploadExisting' }"><button type="button" class="btn addCreditBtn my-3">Upload
                            Existing Files</button></router-link>
                </div>
            </div>
        </section>
        <!-- account plan Information -->

        <section class="pb-5 px-sm-5 px-0 ">
            <div class="row g-0 justify-content-between">
                <div class="col-lg-6 max-width-col">
                    <div class="Account-plan container-wrapper h-100  border-top-primary">
                        <div>
                            <div class="section-label">USER SETTINGS</div>
                        </div>
                        <h1 class="section-heading">Select User Account </h1>
                        <div class=" container-fluid px-0 ">
                            <div class="mb-3 mt-4">
                                <div class="row">
                                    <div class="col-lg-6 col-12 admin-users" @click="$event.stopPropagation()">
                                        <input type="search" id="form2"
                                            @focus="showUserList = true; showDeleteUserList = false; filterUserInput = ''"
                                            class="form-control d-block shadow-none py-2 ps-4" placeholder="Select User"
                                            aria-label="Search" :value="selectedUser?.user ? selectedUser?.user : ''"
                                            readonly />
                                        <!-- </div> -->
                                        <div class="dropdown-content" v-show="showUserList">
                                            <div class="input-group search-form py-2 rounded-0 border">
                                                <span class="input-group-text border-0 px-3 py-1" id="basic-addon2">
                                                    <img src="@/assets/img/search.svg" class="" alt="">
                                                </span>
                                                <input type="search" id="form2" v-model="filterUserInput"
                                                    class="form-control border-0 shadow-none py-1 ps-2"
                                                    placeholder="Start typing account info" aria-label="Search" />
                                            </div>
                                            <div class="dropdown-item cursor-pointer" @click="selectOption(option, index);"
                                                v-for="(option, index) in  filteredUsers" :key="index">
                                                {{ option }}
                                            </div>
                                        </div>

                                    </div>
                                    <!-- <div class="col-lg-6 col-12">
                                        <template><Dropdown :options="[{ id: 1, name: 'Option 1' }, { id: 2, name: 'Option 2' }]"
                                            v-on:selected="validateSelection" v-on:filter="getDropdownValues"
                                            :disabled="false" name="zipcode" :maxItem="10"
                                            placeholder="Please select an option">
                                        </Dropdown></template>
                                    </div> -->
                                </div>
                            </div>

                            <div class="currentPlan  row g-0 align-items-center justify-content-between">
                                <div class="col-12 row g-0 px-3">
                                    <h3 class="col-md-6">Your Current Plan:</h3>
                                    <div class="currentPlanDesc col-md-6">
                                        <p>{{ getPlanName() }}</p>
                                    </div>
                                </div>
                                <!-- <div class="currentPlanAmount col-lg-3 col-4">
                                    <p>$ {{ userInfo?.user_plan?.plan_reference !== "79541679412217" ?
                                        formatCredits(userInfo?.user_plan?.purchase_amount) : 0 }} </p>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 max-width-col">
                    <div class="Account-plan container-wrapper h-100 border-top-primary">
                        <div>
                            <div class="section-label">&nbsp;</div>
                        </div>
                        <h1 class="section-heading">Create Account</h1>

                        <form role="form" @submit.prevent="openCreateUserModal()">
                            <div class=" col-10 ps-2 ">
                                <input type="email" placeholder="Email" v-model="createUserEmail"
                                    class="form-control fs-6 py-1 pt-3 pb-1 border-top-0  px-0 border-end-0 rounded-0  border-2 border-start-0">

                            </div>
                            <div class="col-10 ps-2 ">
                                <input type="text" placeholder=" Password" v-model="createUserPassword"
                                    class="form-control fs-6 py-1 pt-3 pb-1  border-top-0  px-0 border-end-0 rounded-0  border-2 border-start-0">

                            </div>
                            <div class="col-10 ps-2 ">
                                <input type="text" placeholder="Confirm  Password" v-model="createUserConfirmPassword"
                                    class="form-control fs-6 py-1 pt-3 pb-1  border-top-0  px-0 border-end-0 rounded-0  border-2 border-start-0">

                            </div>
                            <div class="col-10 ps-2 ">
                                <select
                                    class="form-select fs-6 py-1 pt-3 pb-1  border-top-0  px-0 border-end-0 rounded-0  border-2 border-start-0"
                                    v-model="createUserType" aria-label="Default select example">
                                    <option selected value="" disabled> Select Usertype</option>
                                    <option value="user">User</option>
                                    <option value="admin">Admin</option>
                                </select>
                            </div>


                            <div class="mt-4">
                                <button type="submit"
                                    :disabled="createUserEmail == '' || createUserPassword == '' || createUserConfirmPassword == '' || createUserType == '' || isAccountCreating"
                                    class="addCreditBtn btn btn-sm text-white">
                                    Create Account
                                </button>
                            </div>
                            <div class="error text-sm text-danger mb-2">{{ error }}</div>
                        </form>
                    </div>
                </div>
            </div>
        </section>

        <!-- <section class="pb-5 px-lg-5 px-2">
            <div class="container-fluid Billing-overview container-wrapper">
                <div class="User-Account">USER SETTINGS</div>
                <div class="row justify-content-between">
                    <div class="col-lg-6 pe-lg-5 ">
                        <h1 class="section-heading mb-4">Billing Information</h1>
                        <div class=" usage-summary-card">
                            <div class="d-flex header  border-bottom align-items-center">
                                <div class="col-8 ">Current Billing Information</div>

                            </div>
                            <div class="d-flex py-2  align-items-center ">
                                <div class="col-lg-3 col-4  text-start pe-2">
                                    NAME:
                                </div>
                                <div class="col-lg-9 col-8 ps-2 ">
                                    <span
                                        class="form-control fs-6 py-1 border-top-0 border-end-0 rounded-0  border-2 border-start-0">
                                        {{ userInfo?.user_name }}
                                    </span>
                                </div>
                            </div>
                            <div class="d-flex py-2  align-items-center ">
                                <div class="col-lg-3 col-4  text-start pe-2">
                                    CREDIT CARD:
                                </div>
                                <div class="col-lg-9 col-8 ps-2 ">
                                    <span
                                        class="form-control fs-6 py-1 border-top-0 border-end-0 rounded-0  border-2 border-start-0">
                                        {{ userInfo?.billing_info?.last_four !== undefined ? `XXXX XXXX XXXX
                                        ${userInfo?.billing_info?.last_four}` : '' }}
                                    </span>
                                </div>
                            </div>
                            <div class="d-flex py-2 align-items-center ">
                                <div class="col-lg-3 col-4  text-start pe-2">
                                    ADDRESS:
                                </div>
                                <div class="col-lg-9 col-8 ps-2 ">
                                    <span
                                        class="form-control fs-6 py-1 border-top-0 border-end-0 rounded-0  border-2 border-start-0 ">
                                        {{ userInfo?.billing_info?.customer_address !== undefined ?
                                            formattedAddress(userInfo?.billing_info?.customer_address) : '' }}
                                    </span>
                                </div>
                            </div>
                            <div>
                                <router-link :to="`updatecard?returnUrl=${$route.name}`">
                                    <button type="button" class="addCreditBtn mt-3 btn btn-sm text-white">Update
                                        Billing</button>
                                </router-link>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 ps-lg-5 mb-lg-4 my-3">
                        <h1 class="section-heading mb-5">Billing History</h1>
                        <div class="card rounded-3 border usage-summary-card border border-dark">
                            <div class="d-flex header border-bottom px-2 align-items-center">
                                <div class="col-8 ps-2 border-end border-2 title"></div>
                                <div class="col-4 ps-2">
                                    <select class="form-select border-0 p-0" v-model="paymentHistoryMonth"
                                        @change=" per_page = 5; getPurchase()" aria-label="Default select example">
                                        <option :value="item.value"
                                            v-for="               item, index                in                month               "
                                            :key="index">
                                            {{
                                                item.name
                                            }}
                                            {{ year }}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="d-flex py-2 px-2 align-items-center bg-light" v-if="isPaymentHistoryProcessing">
                                <div class="col-12 ps-2 text-center py-2 amount-text">
                                    Loading... <i class="fa fa-circle-o-notch fa-spin"></i>
                                </div>
                            </div>

                            <template v-else>
                                <template v-if="purchasesHistory?.purchases?.length">
                                    <div class="d-flex py-2 border-bottom border-2 px-2 align-items-center"
                                        v-for="               items, index                in                 purchasesHistory?.purchases               "
                                        :key="index">
                                        <div class="col-8 ps-2 ">{{ items.plan_name }} &nbsp;&&nbsp; {{
                                            formatDate(items.purchase_date) }}
                                        </div>
                                        <div class="col-4 ps-2 text-end pe-2 amount-text">
                                            ${{ items.purchase_amount }}
                                        </div>
                                    </div>
                                </template>

                                <template v-else>
                                    <div class="d-flex py-2 px-2 align-items-center bg-light">
                                        <div class="col-12 ps-2 text-center  amount-text">
                                            No Purchase History Found
                                        </div>
                                    </div>
                                </template>
                            </template>

                            <div class="d-flex py-2 px-2 align-items-center ">
                                <div class="col-12 ps-2 footer py-3 text-end pe-4">
                                    <span class="cursor-pointer" :disabled="isPaymentHistoryProcessing"
                                        @click=" per_page = per_page += 5; getPurchase()">Show More</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->

        <section class="pb-5 px-sm-5 px-0">
            <div class="container-fluid pause-account ">
                <div class="row">
                    <div class="col-lg-6 container-wrapper col-12 border-top-primary">
                        <div class="section-label">USER SETTINGS</div>
                        <h1 class="section-heading">Delete Account</h1>
                        <div class=" usage-summary-card mt-5">
                            Select account you would like to remove

                        </div>
                        <div class="col-lg-8 mt-2 col-12 admin-users" @click="$event.stopPropagation()">
                            <input type="search" id="form2"
                                @focus="showDeleteUserList = true; filterUserInput = ''; showUserList = false;"
                                class="form-control d-block shadow-none py-2 ps-4" placeholder="Select User"
                                aria-label="Search" :value="selectDeleteUser?.user ? selectDeleteUser?.user : ''"
                                readonly />
                            <div class="dropdown-content" v-show="showDeleteUserList">
                                <div class="input-group search-form py-2 rounded-0 border">
                                    <span class="input-group-text border-0 px-3 py-1" id="basic-addon2">
                                        <img src="@/assets/img/search.svg" class="" alt="">
                                    </span>
                                    <input type="search" id="form2" v-model="filterUserInput"
                                        class="form-control border-0 shadow-none py-1 ps-2"
                                        placeholder="Start typing account info" aria-label="Search" />
                                </div>
                                <div class="dropdown-item cursor-pointer" @click="setDeleteUser(option, index)"
                                    v-for="(option, index) in  filteredUsers" :key="index">
                                    {{ option }}
                                </div>
                            </div>

                        </div>
                        <div class="mt-4">
                            <button type="button" class="addCreditBtn btn btn-sm text-white" @click="deleteAccount()"
                                :disabled="selectDeleteUser == null || isAccountDeleting">
                                <span v-if="isAccountDeleting">
                                    <i class="fa fa-circle-o-notch fa-spin"></i>
                                </span>
                                <span v-else>DELETE ACCOUNT</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div>
            </div>


        </section>
        <section class="payment-confirm-modal">
            <button type="button" class="btn btn-primary d-none" data-bs-toggle="modal" id="openUserModal"
                data-bs-target="#createUserModal">
                modal
            </button>
            <div class="modal fade" id="createUserModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl ">
                    <div class="modal-content">
                        <div class="modal-header border-0">
                            <div class="modal-title " id="exampleModalLabel ">Account Creation Alert
                            </div>
                            <div type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></div>
                        </div>
                        <div class="modal-body pt-0">
                            <section class="pb-4  px-lg-4 px-2 ">
                                <div class=" container-fluid  px-lg-0 mt-3  ">
                                    <div class="container-wrapper  notice-wrapper  py-2 px-3" style="min-height:unset;">
                                        <div class="row justify-content-between align-items-center">
                                            <div class="col-lg-12 col-12 ">
                                                <div class="textwelcome   d-flex align-items-center g-0 pt-4 pb-4">
                                                    <div class="col-md-6 text-start mb-md-0 mb-3">
                                                        Are you sure you want to create account?
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class=" text-end mt-4">
                                    <button type="button" id="closeUserModal"
                                        class="col-lg-2 addCreditBtn getplanbtn current-plan-btn me-3"
                                        data-bs-dismiss="modal" :disabled="isAccountCreating">Cancel</button>
                                    <button type="button" class=" col-lg-2 addCreditBtn getplanbtn" @click="createUser()"
                                        :disabled="isAccountCreating">
                                        <span v-if="isAccountCreating">
                                            <i class="fa fa-circle-o-notch fa-spin"></i>
                                        </span>
                                        <span v-else>
                                            Confirm
                                        </span>
                                    </button>
                                </div>

                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="user-data-modal">
            <button type="button" class="btn btn-primary d-none" data-bs-toggle="modal" id="openUserDataModal"
                data-bs-target="#showUserDataModal">
                modal
            </button>
            <div class="modal fade" id="showUserDataModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl border-top-primary ">
                    <div class="modal-content rounded-0 bg-white">
                        <div class="modal-header border-0 pt-1">
                            <div class="modal-title " id="exampleModalLabel "> </div>
                            <div type="button" class="text-end" data-bs-dismiss="modal" aria-label="Close">
                                <img src="@/assets/img/removeFile.svg" class="">
                            </div>
                        </div>
                        <div class="modal-body pt-0 px-4">
                            <div class="section-label">USER SETTINGS</div>
                            <h1 class="section-heading mb-4">User Information</h1>
                            <section class="pb-4  ">
                                <div class="container-wrapper rounded-3 border border-dark p-sm-5 p-2"
                                    style="min-height:unset;">
                                    <div class="row justify-content-between mb-5">
                                        <div class="col-lg-6 col-12 ">
                                            <div class="currentPlan border-0 row g-0 align-items-center justify-content-between"
                                                style="min-height: unset;">
                                                <div class="col-12 row g-0 pe-3">
                                                    <h3 class="col-md-6">Your Current Plan:</h3>
                                                    <div class="currentPlanDesc col-md-6">
                                                        <p v-if="isSelectedUserLoading">Loading... <i
                                                                class="fa fa-circle-o-notch fa-spin"></i></p>
                                                        <p v-else>{{ getSelectedUserPlanName() }}</p>
                                                    </div>
                                                </div>
                                                <!-- <div class="currentPlanAmount col-lg-3 col-4">
                                                    <p v-if="isSelectedUserLoading"><i
                                                            class="fa fa-circle-o-notch fa-spin"></i></p>
                                                    <p v-else>$ {{ selectedUserInfo?.user_plan?.plan_reference !==
                                                        "79541679412217"
                                                        ?
                                                        formatCredits(selectedUserInfo?.user_plan?.purchase_amount) : 0 }}
                                                    </p>
                                                </div> -->
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-12 ps-lg-5 current-credits">
                                            <div class="d-lg-flex d-block align-items-center" style="flex-wrap: wrap;">
                                                <div class=" col-7">
                                                    <span class="title me-2">Current Credits:</span>
                                                    <span class="amount" v-if="isSelectedUserLoading"><i
                                                            class="fa fa-circle-o-notch fa-spin"></i></span>
                                                    <span v-else class="amount">{{
                                                        formatCredits(selectedUserInfo?.total_credits_balance)
                                                    }}</span>
                                                </div>
                                                <div class="col ms-auto position-relative">
                                                    <button :disabled="isCreditsAdjusting" type="button" class="d-block me-0 addCreditBtn getplanbtn"
                                                        @click="$event.stopPropagation(); showAdjustCreditsBox = !showAdjustCreditsBox">
                                                        Adjust Credits
                                                    </button>
                                                </div>

                                                <div class="col-12" @click="$event.stopPropagation()"
                                                    v-if="showAdjustCreditsBox">
                                                    <div class="adjustCreditsBox ms-lg-auto">
                                                        <div class="creditsWrapper">
                                                           <input type="number" class="form-control p-0" v-model="plan_credits">

                                                            <div class="col-12">
                                                                <button type="button" :disabled="plan_credits == 0"
                                                                    @click="adjustCredits()"
                                                                    class="d-block mx-auto addCreditBtn getplanbtn">
                                                                    <span v-if="isCreditsAdjusting">Loading... <i
                                                                            class="fa fa-circle-o-notch fa-spin"></i></span>
                                                                    <span v-else>Add</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div class="btnWrapper">
                                                            <button class="btn d-block mb-0 mt-2"
                                                                @click="plan_credits += 1"><i
                                                                    class="fa fa-plus"></i></button>
                                                            <button class="btn d-block mb-1 "
                                                                @click="plan_credits == 0 ? plan_credits = 0 : plan_credits -= 1"><i
                                                                    class="fa fa-minus"></i></button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row justify-content-between">

                                        <div class="col-lg-6 pe-lg-5 ">
                                            <div class=" usage-summary-card">
                                                <div class="d-flex header  border-bottom align-items-center">
                                                    <div class="col-8 ">Current Billing Information</div>

                                                </div>
                                                <div class="d-flex py-2 align-items-center ">
                                                    <div class="col-lg-3 col-4  text-start pe-2">
                                                        Email:
                                                    </div>
                                                    <div class="col-lg-9 col-8 ps-2 ">
                                                        <input v-model="selectedUserEmail" :disabled="isSelectedUserLoading"
                                                            class="form-control fs-6 py-1 border-top-0 border-end-0 rounded-0  border-2 border-start-0 " />
                                                    </div>
                                                </div>
                                                <div class="d-flex py-2 align-items-center ">
                                                    <div class="col-lg-3 col-4  text-start pe-2">
                                                        Password:
                                                    </div>
                                                    <div class="col-lg-9 col-8 ps-2 ">
                                                        <input type="password" v-model="selectedUserPassword"
                                                            placeholder="Leave Blank to Keep Unchanged"
                                                            :disabled="isSelectedUserLoading"
                                                            class="form-control fs-6 py-1 border-top-0 border-end-0 rounded-0  border-2 border-start-0 " />

                                                    </div>
                                                </div>
                                                <div class="d-flex py-2 align-items-center ">
                                                    <div class="col-lg-3 col-4  text-start pe-2">
                                                        UserType:
                                                    </div>
                                                    <div class="col-lg-9 col-8 ps-2 ">
                                                        <select :disabled="isSelectedUserLoading"
                                                            class="form-select fs-6 py-1 border-top-0  border-end-0 rounded-0  border-2 border-start-0"
                                                            v-model="selectedUserType" aria-label="Default select example">
                                                            <option selected value="" disabled> Select Usertype</option>
                                                            <option value="user">User</option>
                                                            <option value="admin">Admin</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div>
                                                    <button type="button"
                                                        :disabled="selectedUserEmail == '' || selectedUserType == ''"
                                                        @click="updatedSelectedUser()"
                                                        class="addCreditBtn mt-3 btn btn-sm text-white">
                                                        <template v-if="isUpdatingSelectedUser">
                                                            Loading... <i class="fa fa-circle-o-notch fa-spin"></i>
                                                        </template>
                                                        <span v-else>Update</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 ps-lg-5 mb-lg-4 my-3">
                                            <h1 class="title mb-3">Billing History</h1>
                                            <div class="card rounded-3 border usage-summary-card border border-dark">
                                                <div class="d-flex header border-bottom px-2 align-items-center">
                                                    <div class="col-8 ps-2 border-end border-2 title"></div>
                                                    <div class="col-4  py-1 ">
                                                        <select
                                                            class="form-select border-2 rounded-0 border-end-0 border-top-0 border-secondary border-bottom-0 border-start p-0 ps-2"
                                                            v-model="paymentHistoryMonth"
                                                            @change=" per_page = 5; getPurchase()"
                                                            aria-label="Default select example">
                                                            <option :value="item.value"
                                                                v-for="               item, index                in                month               "
                                                                :key="index">
                                                                {{
                                                                    item.name
                                                                }}
                                                                {{ year }}</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="d-flex py-2 px-2 align-items-center bg-light"
                                                    v-if="isPaymentHistoryProcessing">
                                                    <div class="col-12 ps-2 text-center py-2 amount-text">
                                                        Loading... <i class="fa fa-circle-o-notch fa-spin"></i>
                                                    </div>
                                                </div>

                                                <template v-else>
                                                    <template v-if="purchasesHistory?.purchases?.length">
                                                        <div class="d-flex py-2 border-bottom border-2 px-2 align-items-center"
                                                            v-for="               items, index                in                 purchasesHistory?.purchases               "
                                                            :key="index">
                                                            <div class="col-8 ps-2 ">{{ getPlanName(items) }} &nbsp;&&nbsp; {{
                                                                formatDate(items.purchase_date) }}
                                                            </div>
                                                            <div class="col-4 ps-2 text-end pe-2 amount-text">
                                                                ${{ items.purchase_amount }}
                                                            </div>
                                                        </div>
                                                    </template>

                                                    <template v-else>
                                                        <div class="d-flex py-2 px-2 align-items-center bg-light">
                                                            <div class="col-12 ps-2 text-center  amount-text">
                                                                No Purchase History Found
                                                            </div>
                                                        </div>
                                                    </template>
                                                </template>

                                                <div class="d-flex py-2 px-2 align-items-center ">
                                                    <div class="col-12 ps-2 footer py-3 text-end pe-4">
                                                        <span class="cursor-pointer" :disabled="isPaymentHistoryProcessing"
                                                            @click=" per_page = per_page += 5; getPurchase()">Show
                                                            More</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
import Api from "@/Api";
import moment from "moment";
import _ from 'lodash';

export default {
    name: "Admin",
    props: ['userInformation'],
    emits: ['userInformation'],
    data() {
        return {
            showUserList: false,
            payment_customer_exists: false,
            payment_method_exists: false,
            postPlans: [],
            isPaymentProcessing: false,
            prepaidPlanSelectedPlanIndex: null,
            postPaidSelectedPlanIndex: null,
            showBillingDetails: false,
            allPlans: [],
            users: {},
            filteredUsers: {},
            purchasesHistory: {},
            per_page: 5,
            year: moment().format('YYYY'),
            userInfo: null,
            selectedUserInfo: null,
            paymentHistoryMonth: moment().format('MM'),
            selectedBuyPlan: null,
            isPaymentHistoryProcessing: true,
            isPlanProcessing: false,
            isAccountCreating: false,
            selectedAmount: '',
            scrubs_amount: 0,
            createUserEmail: '',
            createUserPassword: '',
            createUserConfirmPassword: '',
            createUserType: '',
            is_scrub_enabled: false,
            selectedUser: null,
            error: '',
            showDeleteUserList: '',
            selectDeleteUser: null,
            isAccountDeleting: null,
            filterUserInput: '',
            plan_credits: 0,
            showAdjustCreditsBox: false,
            isCreditsAdjusting: false,
            month: [
                { value: '01', name: 'Jan' },
                { value: '02', name: 'Feb' },
                { value: '03', name: 'Mar' },
                { value: '04', name: 'Apr' },
                { value: '05', name: 'May' },
                { value: '06', name: 'Jun' },
                { value: '07', name: 'Jul' },
                { value: '08', name: 'Aug' },
                { value: '09', name: 'Sep' },
                { value: '10', name: 'Oct' },
                { value: '11', name: 'Nov' },
                { value: '12', name: 'Dec' },
            ],
            selectedUserId: '',
            selectedUserName: '',
            selectedUserEmail: '',
            selectedUserPassword: '',
            selectedUserType: '',
            isSelectedUserLoading: true,
            isUpdatingSelectedUser: false
        }
    },
    async mounted() {
        this.getAllUsers()
        this.userInfo = Api.getUserSession()
        Api.getUserInfo().then(async (res) => {
            this.userInfo = res?.data
            this.payment_customer_exists = Api.payment_customer_exists()
            this.payment_method_exists = Api.payment_method_exists()

            this.scrubs_amount = this.userInfo?.purchase_settings?.scrubs_amount ? this.userInfo?.purchase_settings?.scrubs_amount : 0
            this.is_scrub_enabled = this.userInfo?.purchase_settings?.is_scrub_enabled == 1 ? true : false
            Api.getPlans().then((res) => {
                this.allPlans = res.data.plans
                this.postPlans = res.data.plans.filter((el) => el.plan_type == 'postpaid')

            })
            this.selectedUser = JSON.parse(localStorage.getItem('lls-userinfo'))?.selectedUser
        })

    },
    methods: {
        adjustCredits() {
            this.isCreditsAdjusting = true;
            let payload = {
                action: 'adjust_credits',
                plan_credits: this.plan_credits,
                u: this.selectedUserId,
                plan_reference: process.env.VUE_APP_ADJUST_CREDIT_PLAN
            }
            Api.adjustCredits(payload).then((res) => {
                if (res?.data?.message) {
                    this.$swal({
                        title: 'Success',
                        text: res?.data?.message,
                        icon: 'success',
                        showCancelButton: false,
                        cancelButtonText: 'Cancel',
                        confirmButtonText: 'Close',
                        customClass: {
                            confirmButton: 'btn bg-gradient-success',
                            cancelButton: 'btn bg-gradient-danger',
                        }
                    })
                    this.isSelectedUserLoading = true
                    Api.getSelectedUserInfo().then((resp) => {
                        if (resp?.data) {
                            this.selectedUserInfo = resp.data
                        }
                    }).catch((err) => {
                        console.log("err :- ", err)
                    }).finally(() => {
                        this.isSelectedUserLoading = false
                    })
                }
            }).catch((err) => {
                console.log('Err : - ', err)
            }).finally(() => {
                this.isCreditsAdjusting = false
                this.plan_credits = 0
            })
        },
        formattedAddress(address) {
            if (address) {
                return `${address.line1}, ${address.line2}, ${address.city}, ${address.state}, ${address.country} - ${address.postal_code}`
            }
        },
        formatCredits(number) {
            if (!number) {
                return 0
            }
            if (number && Number.isInteger(number)) {
                return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else {
                return number
            }
        },
        openCreateUserModal() {
            this.error = ''
            if (this.createUserConfirmPassword !== this.createUserPassword) {
                this.error = 'Both field must have same password ';
                return
            }
            document.getElementById('openUserModal').click()
        },
        getAllUsers() {
            this.users = []
            this.filteredUsers = []
            Api.getAllUsers().then((res) => {
                // console.log('res.data.users', res.data)
                if (res.data.users) {
                    this.users = res.data.users
                    this.filteredUsers = res.data.users
                }
            }).catch((err) => {
                console.log('err', err)
            })
        },
        updatedSelectedUser() {
            this.isUpdatingSelectedUser = true;
            let payload = {
                u: this.selectedUserId,
                le: this.selectedUserEmail,
                lp: this.selectedUserPassword,
                ut: this.selectedUserType
            }
            Api.updateSelectedUserInfo(payload).then((res) => {
                this.$swal({
                    title: 'Success',
                    text: res?.data?.message,
                    icon: 'success',
                    showCancelButton: false,
                    cancelButtonText: 'Cancel',
                    confirmButtonText: 'Close',
                    customClass: {
                        confirmButton: 'btn bg-gradient-success',
                        cancelButton: 'btn bg-gradient-danger',
                    }
                })
            }).catch((err) => {
                console.log('Err :- ', err)
            }).finally(() => [
                this.isUpdatingSelectedUser = false
            ])
        },
        createUser() {
            this.error = '';
            if (this.createUserEmail == '' || this.createUserPassword == '' || this.createUserConfirmPassword == '' || this.createUserType == '') {
                this.error = 'All fileds are required';
                return;
            }
            this.isAccountCreating = true
            if (this.createUserConfirmPassword == this.createUserPassword) {
                let obj = {
                    le: this.createUserEmail,
                    lp: this.createUserPassword,
                    ut: this.createUserType,

                }
                Api.signUp(obj).then(() => {
                    this.getAllUsers()
                    this.createUserEmail = ''
                    this.createUserPassword = ''
                    this.createUserConfirmPassword = ''
                    this.createUserType = ''
                    this.$swal({
                        title: 'Success',
                        text: 'Account created successfully',
                        icon: 'success',
                        showCancelButton: false,
                        cancelButtonText: 'Cancel',
                        confirmButtonText: 'Close',
                        customClass: {
                            confirmButton: 'btn bg-gradient-success',
                            cancelButton: 'btn bg-gradient-danger',
                        }
                    })
                }).catch((err) => {
                    console.log(err)
                    this.error = 'Error while create account'
                }).finally(() => {
                    this.isAccountCreating = false
                    setTimeout(() => {
                        document.getElementById('closeUserModal').click();
                    }, 500)
                })
            } else {
                this.error = 'Both field must have same password ';
                this.isAccountCreating = false;
            }
        },

        removePaymentMethod() {
            Api.deleteUserCard().then(() => {
                if (this.userInfo) {
                    Api.getUserInfo().then(async (res) => {
                        if (res?.data?.auth_token) {
                            await Api.saveUserSession(res?.data)
                            this.userInfo = await Api.getUserSession();
                            this.payment_customer_exists = Api.payment_customer_exists()
                            this.payment_method_exists = Api.payment_method_exists()
                        }
                    }).catch((err) => {
                        console.log('Error: ', err)
                    })
                }

            }).catch((err) => {
                console.log(err)

            })
        },
        selectOption(email, userId) {
            this.selectedUserId = userId;
            document.getElementById('openUserDataModal').click()
            // Api.saveUserSession({ selectedUser: this.selectedUser })
            // Api.adminLogin({ u: userId }).then(async (res) => {
            //     await Api.saveUserSession({ token: res.data.user.token })
            //     let user = await Api.getUserInfo()
            //     await Api.saveUserSession(user.data)
            //     this.getPurchase()
            //     this.userInfo = Api.getUserSession();
            //     this.$emit('userInformation', this.userInfo)
            //     this.$swal({
            //         title: 'Success',
            //         text: `Switched to ${this.userInfo.user_login_email}`,
            //         icon: 'success',
            //         showCancelButton: false,
            //         cancelButtonText: 'Cancel',
            //         confirmButtonText: 'Close',
            //         customClass: {
            //             confirmButton: 'btn bg-gradient-success',
            //             cancelButton: 'btn bg-gradient-danger',
            //         }
            //     })
            // }).catch((err) => {
            //     console.log('err', err)
            // })
            this.selectedUserInfo = ''
            this.selectedUserEmail = ''
            this.selectedUserType = ''
            this.isSelectedUserLoading = true
            Api.adminLogin({ u: userId }).then(async (res) => {
                await Api.saveUserSession({ selectedUser_token: res.data.user.token })
                this.getPurchase()
                await Api.getSelectedUserInfo().then((resp) => {
                    if (resp?.data) {
                        this.selectedUserInfo = resp.data
                        this.selectedUserEmail = resp?.data?.user_login_email
                        this.selectedUserType = resp?.data?.usertype
                    }
                })
            }).catch((err) => {
                console.log("err :- ", err)
            }).finally(() => {
                this.isSelectedUserLoading = false
            })
            this.showUserList = false;
            document.getElementsByClassName('dropdown-content')[0].scrollTo(0, 0)
        },
        getPlanName(item) {
            if(item) {
                let plan = this.allPlans?.find((el) => el?.plan_reference == item?.plan_reference)
                if(plan) {
                return plan.plan_name_display
                } else {
                item.plan_name
                }
            } else {
                let plan = this.allPlans?.find((el) => el?.plan_reference == this.userInfo?.user_plan?.plan_reference)
                if (plan && plan.plan_reference !== "79541679412217") {
                return plan.plan_name_display
                } else {
                return 'No Selected Plan'
                }
            }
        },
        
        getSelectedUserPlanName() {
            let plan = this.allPlans?.find((el) => el?.plan_reference == this.selectedUserInfo?.user_plan?.plan_reference)
            if (plan && plan.plan_reference !== "79541679412217") {
                return plan.plan_name_display
            } else {
                return 'No Selected Plan'
            }
        },
        formatDate(date) {
            return moment(date).format('yyyy-MM-DD')
        },
        getPurchase() {
            this.isPaymentHistoryProcessing = true;
            this.purchasesHistory = {}
            let obj = {
                year: this.year,
                month: this.paymentHistoryMonth,
                per_page: this.per_page
            }

            Api.getSelectedUserPurchase(obj).then((res) => {
                this.purchasesHistory = res.data
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                this.isPaymentHistoryProcessing = false
            })
        },
        setDeleteUser(email, userId) {
            this.selectDeleteUser = {
                user: email,
                userId: userId
            }
            this.showDeleteUserList = false
        },
        deleteAccount() {
            this.isAccountDeleting = true;
            let obj = {
                userid: this.selectDeleteUser.userId,
                action: 'disabled'
            }
            Api.deleteUser(obj).then(() => {
                this.$swal({
                    title: 'Success',
                    text: 'User deleted successfully',
                    icon: 'success',
                    showCancelButton: false,
                    cancelButtonText: 'Cancel',
                    confirmButtonText: 'Close',
                    customClass: {
                        confirmButton: 'btn bg-gradient-success',
                        cancelButton: 'btn bg-gradient-danger',
                    }
                })
                this.getAllUsers()
            }).catch((err) => {
                console.log('err', err)
            }).finally(() => {
                this.selectDeleteUser = null;
                this.isAccountDeleting = false
            })
        }
    },
    watch: {
        filterUserInput() {
            if (this.filterUserInput !== '') {
                this.filteredUsers = _.pickBy(this.users, (value) => {
                    return _.startsWith(value, this.filterUserInput);
                });
            } else {
                this.filteredUsers = this.users
            }
        }
    }
}
</script>
<style lang="less" scoped>
@import '@/assets/css/admin.less';
</style>
<style>
.bg-grayed {
    background: rgba(253, 253, 253, 0.7);
    z-index: 99
}

.admin-users .dropdown-content {
    height: 170px;
    width: 95%;
    margin: 0 auto;
    overflow: hidden;
    overflow-y: scroll;
}

.admin-users .dropdown-content {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.admin-users .dropdown-content::-webkit-scrollbar {
    display: none;
}

.admin-users .dropdown-item {
    height: 34px;
    background: #F5F5F5;
    width: 100%;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    /* line-height: 60px; */
    line-height: 24px;
    color: #7C7C7C;
    border: 1px solid #D5D5D5;
    padding: 0.3rem 2rem;
}
</style>