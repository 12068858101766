<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header"></slot>
          </div>

          <div class="modal-body">
            <slot name="body">{{ message }}</slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <argon-button
                @click="$emit('close')"
                color="success"
              >OK</argon-button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";

export default {
  props: {
    show: Boolean,
    title: String,
    message: String,
  },
  components: {
    ArgonButton,
  },
}
</script>
