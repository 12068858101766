<template>
  <main class="mt-0 text-start main-content">
    <section class="pt-4 pb-sm-5 pb-3 px-sm-5 px-0">
      <div class="welcome-section container-fluid px-lg-0 px-0">
        <div class="welcome-title">My Account</div>
      </div>
    </section>
    <section class="pt-3 pb-sm-5 pb-3 px-sm-5 px-2">
      <div class="userSettings row justify-content-between border-top-primary g-0 container-wrapper">
        <div class="userInfo col-lg-6 ps-lg-0 pe-lg-4">
          <div class="">
            <div class="section-label">USER SETTINGS</div>
            <div class="section-heading mb-4">User Information</div>
            <div class="userInfoDetails col-lg-10">
              <form action="">
                <div class="form-group mb-0">
                  <div class=" px-0 form-control border-top-0 rounded-0 border-start-0 border-end-0 shadow-none">
                    {{ userInfo?.user_name }}
                  </div>
                </div>
                <div class="form-group mb-0">
                  <div class=" px-0 form-control border-top-0 rounded-0 border-start-0 border-end-0 shadow-none">
                    {{ userInfo?.user_login_email }}
                  </div>
                </div>
              </form>
            </div>

            <div class="">
              <div class="section-heading mb-4">Update Password</div>
              <form role="form" @submit.prevent="changePassword" class="text-start col-lg-6">
                <div class="mb-3">
                  <input type="password" class="form-control  shadow-none " placeholder="Enter password"
                    aria-label="Search" v-model="newPassword" />
                </div>
                <div class="text-center">
                  <button class="addCreditBtn mt-2 btn btn-sm text-white mb-3 col-12" :disabled="isPasswordUpdating">
                    <span v-if="isPasswordUpdating">Updating... <i class="fa fa-circle-o-notch fa-spin"></i></span>
                    <span v-else>Update</span>
                  </button>
                </div>
              </form>
            </div>
            <!-- <div class="d-block mt-5 text-end"><router-link to="/admin">See More</router-link></div> -->
          </div>
        </div>
        <div class="row col-lg-6 g-0 px-4 position-relative h-100">
          <!-- <div class="position-absolute w-100 h-100 bg-grayed"
            v-if="userInfo?.user_plan?.plan_reference !== '79541679412215'"></div> -->
          <div class="col-lg-6 px-lg-3   mb-lg-0 mb-5">
            <div class="pb-3 border-2 border-bottom">
              <div class="section-label">AUTO RECHARGE</div>
              <div class=" py-0 d-flex align-items-center">
                <div class="d-inline-block form-check form-switch px-0 py-1 my-0">
                  <input class="form-check-input" :class="{ 'form-check-input-disabled': !is_scrub_enabled }"
                    type="checkbox" role="switch" id="flexSwitchCheckChecked"
                    @change="is_scrub_enabled = $event.target.checked; updatePurchase()" :checked="is_scrub_enabled">
                </div>
              </div>
            </div>
            <div class="welcome-Text-sm mt-2 "> Selecting Auto Recharge will automatically subscribe you to
              the
              Power Scrubber subscription. Must have Auto Recharge active for CRM Integration. Minimum
              Auto
              Recharge amount is $5
            </div>
          </div>
          <div class="col-lg-6 position-relative col-12 mb-lg-0 my-5 set-auto-recharge text-center">
            <div class="position-absolute w-100 h-100 bg-grayed" v-if="!is_scrub_enabled"></div>
            <div class="section-label set-auto-recharge-label">Set Auto Recharge</div>
            <div class="mt-3 text-center">
              <select class="form-select d-inline-block  " v-model="scrubs_amount" @change=" updatePurchase()"
                aria-label=".form-select-sm example">
                <option value="5">$5.00</option>
                <option value="10">$10.00</option>
                <option value="20">$20.00</option>
                <option value="50">$50.00</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="pt-3 pb-sm-5 pb-3 px-sm-5 px-0">
      <div class="container-wrapper border-0 creditBalance">
        <!-- <div class="section-heading mb-4">Current Credit Balance</div> -->
        <div class="row userSettings justify-content-between">
          <div class="col-lg-6 max-width-col container-wrapper  mb-lg-4 mb-3 border-top-primary">
            <div class="section-label">&nbsp;</div>
            <div class="section-heading mb-4">Current Credit Balance</div>
            <!--<div class="billingInfo">
              <img src="@/assets/img/phoneIcon.svg">
              <div class="header col-lg-12 d-flex justify-content-between">Billing Information
                <span class="mb-2">
                  <span class="billing-info-label">{{ !showBillingDetails ? 'Show' : 'Hide' }}</span>
                  <img class="pb-1" id="eyeIcon" src="@/assets/img/hideShowEye.svg"
                    @click=" showBillingDetails = !showBillingDetails" v-if="!showBillingDetails">
                  <img class="pb-1" id="eyeIconHover" src="@/assets/img/showHideIconHover.svg"
                    @click=" showBillingDetails = !showBillingDetails" v-else>
                </span>
              </div> -->
            <!-- <div class="h5 my-3 text-center" v-if="isCardDeleting">
                Loading... <i class="fa fa-circle-o-notch fa-spin"></i>
              </div>
              <template v-else>
                <div class="body " id="billingInfoBody" v-show="showBillingDetails">
                  <div class="defaultCard" v-if="payment_method_exists">
                    <h5 class="mb-sm-0 mb-3">Default Card:</h5>
                    <div class="defaultCardDesc ">
                      <div>
                        <div class="d-sm-inline d-block"> {{ userInfo?.billing_info?.brand }} **** ****
                          **** {{ userInfo?.billing_info?.last_four ? userInfo?.billing_info?.last_four + ' ' : ' ' }}
                        </div>
                        <div class="d-sm-inline d-block">Exp:
                          {{ userInfo?.billing_info?.exp_month }}/{{ userInfo?.billing_info?.exp_year
                          }}
                        </div>
                      </div>
                    </div>
                    <a class="removePayment col-lg-12" v-if="payment_customer_exists && payment_method_exists"
                    href="javascript:void(0)" @click=" removePaymentMethod()"><img src="@/assets/img/minus.svg">
                    Remove Payment Method
                  </a>
                  </div>
                   <div class="addFundsWrapper">
                  <router-link :to="!payment_customer_exists || !payment_method_exists ? `card?returnUrl=${$route.name}` : 'purchase'">
                    <button class="add-funds col-lg-6">
                      $ Add Funds
                    </button>
                  </router-link>
                </div> <template v-else>
                  <div class="addNewPaymentWrapper" v-if="!payment_method_exists">
                    <router-link :to="`card?returnUrl=${$route.name}`" class="addNewPayment col-lg-12">
                      <img src="@/assets/img/plus.svg">Add a New Payment Method
                    </router-link>
                  </div>
                </template> 
                </div>
              </template> 
            </div>-->

            <div class="creditBalanceDesc">
              <!-- <div class="header mt-3 mb-4">Current Credit Balance</div> -->
              <div class="body mb-3">
                {{ userInfo?.user_plan_status == 'active' ? formatCredits(userInfo?.total_credits_balance) : 'Account Paused' }} &nbsp;&nbsp;
              </div>
              <div>
                <router-link v-if="userInfo?.user_plan_status == 'active'"
                  :to="!payment_customer_exists ? `card?returnUrl=${$route.name}` : !payment_method_exists ? `card?returnUrl=${$route.name}` : 'purchase'">
                  <button type="button" class="addCreditBtn mt-2 btn btn-sm text-white">Add Credits / Change Plan</button>
                </router-link>
                <button v-else type="button" :disabled="isPlanProcessing" class="addCreditBtn mt-2 btn btn-sm text-white"
                  @click=" pausePlan()">
                  <span v-if="isPlanProcessing">
                    <i class="fa fa-circle-o-notch fa-spin"></i>
                  </span>
                  <span v-else>Activate Account</span>
                </button>
              </div>
            </div>
            <div class="userWrapper mt-4 container-wrappe">
              <div class="currentPlan row g-0 align-items-center justify-content-between">
                <div class="col-12 row g-0 px-3">
                  <h3 class="col-md-6 ">Your Current Plan:</h3>
                  <div class="currentPlanDesc col-md-6 ">
                    <p>{{ getPlanName() }}</p>
                  </div>
                </div>
                <!-- <div class="currentPlanAmount col-lg-3 col-4">
                  <p>$ {{ userInfo?.user_plan?.plan_reference !== "79541679412217" ?
                    formatCredits(userInfo?.user_plan?.purchase_amount) : 0 }}</p>
                </div> -->
              </div>
            </div>
          </div>

          <div class="phoneLogs container-wrapper col-lg-6 max-width-col  mb-lg-4 mb-3 border-top-primary"
            v-if="userInfo?.user_plan_status && userInfo?.user_plan_status !== 'paused'">
            <div class="section-label">PHONE LOGS</div>
            <div class="section-heading mb-4">Check a Number</div>
            <p>Need to quickly check a new number? Or re-check a previously submitted number? Just enter the number
              below
              (numbers that have been previously checked will not use a credit)</p>
            <form role="form" @submit.prevent="handleSubmit" class="text-start col-lg-6">
              <div class="mb-3">
                <div class="input-group ms-lg-auto phone-form py-2 bg-white rounded-3 border">
                  <span class="input-group-text border-0 px-3 py-1" id="basic-addon2">
                    {{ dial_code }}
                  </span>
                  <input type="number" class="form-control border-0 shadow-none py-0 ps-4" placeholder="2342342345"
                    v-mask="'##########'" aria-label="Search" v-model="phone" />
                </div>
              </div>
              <div class="text-center">
                <button class="submit-btn-phone mb-3 col-12" @click=" Numberchecking()" :disabled="isNumberchecking">
                  <span v-if="isNumberchecking">Processing... <i class="fa fa-circle-o-notch fa-spin"></i></span>
                  <span v-else>Check</span>
                </button>
                <div class="my-3 fw-bold text-start" v-if="isNumberBlacklist !== null">
                  <span :class="isNumberBlacklist.blacklist == false ? 'text-success' : 'text-danger'">
                    {{ isNumberBlacklist.blacklist == false ? `DNC: ${isNumberBlacklist.blacklist}, Line Type:
                                        ${isNumberBlacklist.linetype}` : `DNC: ${isNumberBlacklist.blacklist}, Line Type:
                                        ${isNumberBlacklist.linetype}` }}
                  </span>
                </div>
              </div>
            </form>
            <!-- <div>Looking for a number you've already checked? <br> click <router-link to="/export">here</router-link>
            </div> -->
          </div>

          <div class="col-lg-6 px-0 mb-lg-4 mb-3">

          </div>
        </div>

        <!-- <div class="col-lg-6 ps-lg-4 mb-lg-4 mb-3">
          <h1 class="section-heading mt-0 mb-5">Pay as you Go Plans</h1>
          <div class="elite-scrubber scrubber row g-0 mx-lg-0 mx-auto align-items-center"
            v-for="      items, i       in       postPlans      " :key="i"
            :class="i == 0 ? 'elite-scrubber' : i == 1 ? 'power-scrubber' : 'standard-scrubber'">
            <div class="col-sm-4 col-12  ">
              <div class="scrubber-text h-100 px-lg-5 px-2">
                <div class="text-pink">{{ items.plan_name }}</div>
                <div class="text-black">SCRUBBER</div>
                <div class="bottborder col-sm-3">
                  <br>
                </div>
              </div>
            </div>
            <div class="col-sm-3 col-12 px-3 pricing">
              <div class="amount-text  h-100 ">${{ items.plan_amount }}</div>
              <div class="amount-label mt-2">{{ items.percent_off }}</div>
            </div>
            <div class="col-sm-5 col-12 border-end get-plan border-2 text-center ">
              <div class="label">{{ items.plan_credits_text }}</div>
              <button class="addCreditBtn getplanbtn  mt-1 btn btn-sm text-white mb-0"
                :class="userInfo?.user_plan?.plan_reference == items.plan_reference ? 'current-plan-btn' : ''"
                @click=" postPaidSelectedPlanIndex = i; buyPlan(items)"
                :disabled="isPaymentProcessing || userInfo?.user_plan?.plan_reference == items.plan_reference">
                <span
                  v-if="isPaymentProcessing && postPaidSelectedPlanIndex == i && items?.plan_reference == '79541679412214'">
                  <i class="fa fa-circle-o-notch fa-spin"></i>
                </span>
                <span v-else>
                  {{ userInfo?.user_plan?.plan_reference == items.plan_reference ? 'Current Plan' :
                    'Select Plan' }}
                </span>
              </button>
            </div>
          </div>
        </div> -->
      </div>
    </section>


    <section class="pb-5 px-sm-5 px-0">
      <div class="container-fluid Billing-overview container-wrapper border-top-primary">
        <div class="User-Account">USER SETTINGS</div>
        <div class="row justify-content-between ">
          <div class="col-lg-6 pe-lg-5 ">
            <h1 class="section-heading mb-4">Billing Information</h1>
            <div class=" usage-summary-card">
              <div class="d-flex header  border-bottom align-items-center">
                <div class="col-8 ">Current Billing Information</div>

              </div>
              <div class="d-flex py-2  align-items-center ">
                <div class="col-lg-3 col-4  text-start pe-2">
                  NAME:
                </div>
                <div class="col-lg-9 col-8 ps-2 ">
                  <span class="form-control fs-6 py-1 border-top-0 border-end-0 rounded-0  border-2 border-start-0">
                    {{ userInfo?.user_name }}
                  </span>
                </div>
              </div>
              <div class="d-flex py-2  align-items-center ">
                <div class="col-lg-3 col-4  text-start pe-2">
                  CREDIT CARD:
                </div>
                <div class="col-lg-9 col-8 ps-2 ">
                  <span class="form-control fs-6 py-1 border-top-0 border-end-0 rounded-0  border-2 border-start-0">
                    {{ userInfo?.billing_info?.last_four !== undefined ? `XXXX XXXX XXXX
                    ${userInfo?.billing_info?.last_four}` : '' }}
                  </span>
                </div>
              </div>
              <div class="d-flex py-2 align-items-center ">
                <div class="col-lg-3 col-4  text-start pe-2">
                  ADDRESS:
                </div>
                <div class="col-lg-9 col-8 ps-2 ">
                  <span class="form-control fs-6 py-1 border-top-0 border-end-0 rounded-0  border-2 border-start-0 ">
                    {{ userInfo?.billing_info?.customer_address !== undefined &&
                      Object.keys(userInfo?.billing_info?.customer_address).length ?
                      formattedAddress(userInfo?.billing_info?.customer_address) : '' }}
                  </span>
                </div>
              </div>
              <div class="">
                <router-link to="/card" class="addCreditBtn mt-5 btn btn-sm text-whit">
                  <img src="@/assets/img/plus.svg" class="mt-1 me-2">Add New Payment Method
                </router-link>
              </div>
              <!-- <div class="d-flex py-2 align-items-center ">
                                <div class="col-lg-3 col-4  text-start pe-2">
                                    SECURITY CODE:
                                </div>
                                <div class="col-lg-9 col-8 ps-2 ">
                                    <input type="text" value="XXXXX"
                                        class="form-control fs-6 py-1 border-top-0 border-end-0 rounded-0  border-2 border-start-0 bg-white" disabled>
                                </div>
                            </div> -->
              <div>
                <!-- <router-link :to="`card?returnUrl=${$route.name}`">
                  <button type="button" class="addCreditBtn mt-3 btn btn-sm text-white">Update
                    Billing</button>
                </router-link> -->
              </div>
            </div>
          </div>

          <div class="col-lg-6 ps-lg-5 mb-lg-4 my-3">
            <h1 class="section-heading mb-5">Billing History</h1>
            <div class="card rounded-3 border usage-summary-card border border-dark">
              <div class="d-flex header border-bottom px-2 align-items-center">
                <div class="col-md-8 col-7 ps-2 border-end border-2 title"></div>
                <div class="col-md-4 col-5 ps-2">
                  <select class="form-select border-0 p-0" v-model="paymentHistoryMonth"
                    @change=" per_page = 5; getPurchase()" aria-label="Default select example">
                    <option :value="item.value"
                      v-for="               item, index                in                month               "
                      :key="index">
                      {{
                        item.name
                      }}
                      {{ year }}</option>
                  </select>
                </div>
              </div>

              <div class="d-flex py-2 px-2 align-items-center bg-light" v-if="isPaymentHistoryProcessing">
                <div class="col-12 ps-2 text-center py-2 amount-text">
                  Loading... <i class="fa fa-circle-o-notch fa-spin"></i>
                </div>
              </div>

              <template v-else>
                <template v-if="purchasesHistory?.purchases?.length">
                  <div class="d-flex py-2 border-bottom border-2 px-2 align-items-center"
                    v-for="               items, index                in                 purchasesHistory?.purchases               "
                    :key="index">
                    <div class="col-8 ps-2 ">{{ getPlanName(items) }} &nbsp;| &nbsp; {{
                      formatDate(items.purchase_date) }}
                    </div>
                    <div class="col-4 ps-2 text-end pe-2 amount-text">
                      ${{ items.purchase_amount }}
                    </div>
                  </div>
                </template>

                <template v-else>
                  <div class="d-flex py-2 px-2 align-items-center bg-light">
                    <div class="col-12 ps-2 text-center  amount-text">
                      No Purchase History Found
                    </div>
                  </div>
                </template>
              </template>

              <div class="d-flex py-2 px-2 align-items-center ">
                <div class="col-12 ps-2 footer py-3 text-end pe-4">
                  <span class="cursor-pointer" :disabled="isPaymentHistoryProcessing"
                    @click=" per_page = per_page += 5; getPurchase()">Show More</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="pb-5 px-sm-5 px-0" id="pauseAccountSection" v-if="userInfo?.user_plan_status && userInfo?.user_plan_status !== ''">
      <div class="container-fluid pause-account  container-wrapper border-top-primary">
        <div>
          <div class="section-label">USER SETTINGS</div>
          <h1 class="section-heading">{{ userInfo?.user_plan_status == 'active' ? 'Pause' : 'Activate' }} Account</h1>
        </div>
        <p class="welcome-Text mt-4">Click below to {{ userInfo?.user_plan_status == 'active' ? 'pause' : 'activate' }} your account. While your account is paused, you will not have
          any functionality of the software (via API or app).</p>
        <button type="button" :disabled="isPlanProcessing" class="addCreditBtn mt-2 btn btn-sm text-white"
          @click=" pausePlan()">
          <span v-if="isPlanProcessing">
            <i class="fa fa-circle-o-notch fa-spin"></i>
          </span>
          <span v-else>{{ userInfo?.user_plan_status == 'active' ? 'Pause Now' : 'Activate' }}</span>
        </button>
      </div>
    </section>
    <section class="payment-confirm-modal">
      <button type="button" class="btn btn-primary d-none" data-bs-toggle="modal" id="openAccountModal"
        data-bs-target="#accountModal">
        modal
      </button>
      <div class="modal fade" id="accountModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl ">
          <div class="modal-content">
            <div class="modal-header border-0">
              <div class="modal-title " id="exampleModalLabel ">Account Confirmation Alert
              </div>
              <div type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></div>
            </div>
            <div class="modal-body pt-0">
              <section class="pb-4  px-lg-4 px-2 ">
                <div class=" container-fluid  px-lg-0 mt-3  ">
                  <div class="container-wrapper  notice-wrapper  py-2 px-3" style="min-height:unset;">
                    <div class="row justify-content-between align-items-center">
                      <div class="col-lg-12 col-12 ">
                        <div class="textwelcome   d-flex align-items-center g-0 pt-4 pb-4">
                          <div class="col-12 text-start mb-md-0 mb-3">
                            please activate your account first ?
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-end mt-4">
                  <button type="button" id="closeAccountModal"
                    class="col-lg-2 addCreditBtn getplanbtn current-plan-btn me-3" data-bs-dismiss="modal"
                    :disabled="isPlanProcessing || newPassword == ''">Cancel</button>
                  <button type="button" class=" col-lg-2 addCreditBtn getplanbtn" @click=" pausePlan()"
                    :disabled="isPlanProcessing">
                    <span v-if="isPlanProcessing">
                      <i class="fa fa-circle-o-notch fa-spin"></i>
                    </span>
                    <span v-else>
                      Confirm
                    </span>
                  </button>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import { ref } from "vue";
import Api from '@/Api'
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import getCountries from "@/composables/getCountries";
import getUser from "@/composables/getUser";
import moment from "moment";
// import ArgonInput from "@/components/ArgonInput.vue";
// import ArgonSwitch from "@/components/ArgonSwitch.vue";
// import ArgonButton from "@/components/ArgonButton.vue";
// import ArgonAlert from "@/components/ArgonAlert.vue";
const body = document.getElementsByTagName("body")[0];
export default {
  name: "AccountInfo",
  props: ['userInformation'],
  emits: ['userInformation'],
  components: {
    //   ArgonInput,
    //   ArgonSwitch,
    //   ArgonButton,
    // ArgonAlert,
  },
  data() {
    return {
      isNumberchecking: false,
      isNumberBlacklist: null,
      phone: null,
      dial_code: '+1',
      payment_customer_exists: false,
      payment_method_exists: false,
      showBillingDetails: false,
      isPaymentHistoryProcessing: false,
      isPaymentProcessing: false,
      allPlans: [],
      postPlans: [],
      isPlanProcessing: false,
      purchasesHistory: {},
      per_page: 5,
      year: moment().format('YYYY'),
      paymentHistoryMonth: moment().format('MM'),
      selectedAmount: '',
      scrubs_amount: 5,
      userInfo: null,
      is_scrub_enabled: false,
      selectedBuyPlan: null,
      isCardDeleting: false,
      isPasswordUpdating: false,
      newPassword: '',
      month: [
        { value: '01', name: 'Jan' },
        { value: '02', name: 'Feb' },
        { value: '03', name: 'Mar' },
        { value: '04', name: 'Apr' },
        { value: '05', name: 'May' },
        { value: '06', name: 'Jun' },
        { value: '07', name: 'Jul' },
        { value: '08', name: 'Aug' },
        { value: '09', name: 'Sep' },
        { value: '10', name: 'Oct' },
        { value: '11', name: 'Nov' },
        { value: '12', name: 'Dec' },
      ]
    }
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
  setup() {
    const phoneCheck = ref("");
    const { user } = getUser()
    const error = ref(null);
    const isProcessing = ref(false);
    const store = useStore();
    const router = useRouter();
    const { countryListAlpha } = getCountries()
    const signup = async () => {
      isProcessing.value = true;
      try {
        let signup = await store.dispatch("signup", {
          phoneCheck: body.querySelector("#phoneCheck").value,
        });
        console.log("signup", signup);
        isProcessing.value = false;
        router.push({ name: "Dashboard" });
      } catch (err) {
        isProcessing.value = false;
        const errorMessage = err.message.replace(
          "FirebaseError: Firebase: ",
          ""
        );
        error.value = err.message.replace("FirebaseError: Firebase:", "");
        if (errorMessage === "Error (auth/wrong-password).") {
          error.value = "Incorrect password";
        } else if (errorMessage === "Error (auth/user-not-found).") {
          error.value = "This email does not exist in the system.";
        }
      }
    };
    const clickSignIn = () => {
      document.querySelector('.submit-btn-phone').click();
    };
    const showHide = () => {
      var div1 = document.querySelector('.billingInfo .body'),
        showEye = document.getElementById('eyeIcon'),
        hideEye = document.getElementById('eyeIconHover');
      if (div1.style.display == 'none') {
        div1.style.display = 'block';
        showEye.style.display = 'none';
        hideEye.style.display = 'block';
      } else {
        div1.style.display = 'none';
        showEye.style.display = 'block';
        hideEye.style.display = 'none';
      }
    }
    return { signup, phoneCheck, error, clickSignIn, isProcessing, showHide, countryListAlpha, user };
  },
  methods: {
    changePassword() {
      if(this.newPassword.trim().length == 0) {
        return
      }
      this.isPasswordUpdating = true;
      Api.changePassword({ up: this.newPassword }).then((res) => {
        if (res?.data?.message) {
          this.$swal({
            title: 'Success',
            text: res?.data?.message ? res?.data?.message : 'Password Updated Successfully',
            icon: 'success',
            showCancelButton: false,
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn bg-gradient-success',
              cancelButton: 'btn bg-gradient-danger',
            }
          })
        }
        this.newPassword = '';
      }).catch((err) => {
        console.log('Err :- ', err)
      }).finally(() => {
        this.isPasswordUpdating = false;
      })
    },
    formatCredits(number) {
      if (number && Number.isInteger(number)) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return number
      }
    },

    getPlanName(item) {
      if (item) {
        let plan = this.allPlans?.find((el) => el?.plan_reference == item?.plan_reference)
        if (plan) {
          return plan.plan_name_display
        } else {
          item.plan_name
        }
      } else {
        let plan = this.allPlans?.find((el) => el?.plan_reference == this.userInfo?.user_plan?.plan_reference)
        if (plan && plan?.plan_reference !== "79541679412217") {
          return plan.plan_name_display
        } else {
          return 'No Selected Plan'
        }
      }
    },
    Numberchecking() {
      this.isNumberchecking = true;
      let obj = {
        number: this.dial_code + this.phone
      }
      console.log('obj', obj)
      Api.singleVerifyPhoneNumber(obj).then((res) => {
        // console.log(res)
        if (res.data) {
          this.isNumberBlacklist = res.data
        }
      }).catch((err) => {
        console.log(err)
      }).finally(() => {
        this.isNumberchecking = false
      })
    },
    async buyPlan(item) {
      if (this.userInfo?.user_plan?.plan_reference == item.plan_reference) {
        return 
      }
      this.payment_customer_exists = await Api.payment_customer_exists()
      this.userInfo = Api.getUserSession();
      if (!this.userInfo) {
        this.$router.push({ name: 'Signup' })
      } else if (this.userInfo?.user_plan_status !== '' && this.userInfo?.user_plan_status == 'paused') {
        document.getElementById('openAccountModal').click()
      } else if (this.payment_info_exists) {
        this.$router.push({ name: 'Purchase', query: { plan: item.plan_reference } })

      } else if (this.payment_customer_exists) {
        if (!this.payment_method_exists) {
          console.log('this.$router', this.$route)
          this.$router.push({ name: 'card', query: { returnUrl: this.$route.name, reference_code: item.plan_reference } })
        } else {
          this.$router.push({ name: 'Purchase', query: { plan: item.plan_reference } })
        }
      } else {
        this.$router.push({ name: 'card', query: { returnUrl: this.$route.name, reference_code: item.plan_reference } })
      }
    },
    formatDate(date) {
      return moment(date).format('yyyy-MM-DD')
    },

    removePaymentMethod() {
      this.isCardDeleting = true
      Api.deleteUserCard().then(() => {
        if (this.userInfo) {
          Api.getUserInfo().then(async (res) => {
            if (res?.data?.auth_token) {
              await Api.saveUserSession(res?.data)
              this.userInfo = await Api.getUserSession();
              this.$emit('userInformation', this.userInfo)
              this.payment_customer_exists = Api.payment_customer_exists()
              this.payment_method_exists = Api.payment_method_exists()
            }
          }).catch((err) => {
            console.log('Error: ', err)
          }).finally(() => {
            this.isCardDeleting = false
          })
        }
      }).catch((err) => {
        console.log(err)
        this.isCardDeleting = false

      })
    },
    formattedAddress(address) {
      if (address) {
        return `${address?.line1 ? address?.line1 + ',' : ''} ${address?.line2 ? address.line2 + ',' : ''} ${address?.city ? address?.city + ',' : ''} ${address?.state ? address.state + ',' : ''} ${address?.country ? address.country : ''} - ${address?.postal_code ? address.postal_code : ''}`
      } else {
        return ''
      }
    },
    updatePurchase() {
      if (this.is_scrub_enabled) {
        this.$router.push({ name: 'Purchase', query: { plan: '79541679412215' } })
        return
      }
      let obj = {
        is_scrub_enabled: this.is_scrub_enabled ? 1 : 0,
        scrubs_amount: this.scrubs_amount.toString()
      }
      Api.updatePurchaseSettings(obj).then(async (resp) => {
        this.$swal({
          title: 'Success',
          text: resp.data.message,
          icon: 'success',
          showCancelButton: false,
          cancelButtonText: 'Cancel',
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn bg-gradient-success',
            cancelButton: 'btn bg-gradient-danger',
          }
        })
        await Api.getUserInfo().then(async (res) => {
          if (res?.data?.auth_token) {
            await Api.saveUserSession(res?.data)

            this.userInfo = await Api.getUserSession();
            this.payment_customer_exists = Api.payment_customer_exists()
            this.payment_method_exists = Api.payment_method_exists()
            this.scrubs_amount = this.userInfo?.purchase_settings?.scrubs_amount ? this.userInfo?.purchase_settings?.scrubs_amount : 5
            this.is_scrub_enabled = this.userInfo?.purchase_settings?.is_scrub_enabled == 1 ? true : false
          }
        }).catch((err) => {
          console.log('Error: ', err)
        })

        this.userInfo = Api.getUserSession()
      }).catch((err) => {
        console.log(err)
      })
      console.log('obj', obj)
    },
    addCredits() {
      if (this.userInfo?.user_plan_status !== '' && this.userInfo?.user_plan_status == 'paused') {
        document.getElementById('openAccountModal').click()
      } else {
        this.$router.push({ name: 'Purchase' })
      }
    },
    pausePlan() {
      this.isPlanProcessing = true;
      let obj = {
        status: this.userInfo?.user_plan_status == 'active' ? 'paused' : 'active'
      }
      Api.updatePurchaseStatus(obj).then((res) => {
        // console.log(res)
        this.$swal({
          title: 'Success',
          text: res.data.message,
          icon: 'success',
          showCancelButton: false,
          cancelButtonText: 'Cancel',
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn bg-gradient-success',
            cancelButton: 'btn bg-gradient-danger',
          }
        })
        Api.getUserInfo().then(async (res) => {
          if (res?.data?.auth_token) {
            await Api.saveUserSession(res?.data)
            this.userInfo = await Api.getUserSession();
          }
        }).catch((err) => {
          console.log('Error: ', err)
        })
      }).catch((err) => {
        console.log(err)
      }).finally(() => {
        this.isPlanProcessing = false
        setTimeout(() => {
          document.getElementById('closeAccountModal').click()
        }, 500);

      })
    },
    getUserInfo() {
      this.userInfo = Api.getUserSession()
      if (this.userInfo) {
        Api.getUserInfo().then(async (res) => {
          if (res?.data?.auth_token) {
            await Api.saveUserSession(res?.data)
            this.userInfo = await Api.getUserSession();
            this.$emit('userInformation', this.userInfo)
            this.payment_customer_exists = Api.payment_customer_exists()
            this.payment_method_exists = Api.payment_method_exists()
          }
        }).catch((err) => {
          console.log('Error: ', err)
        })
      }
    },

    getPurchase() {
      this.isPaymentHistoryProcessing = true;
      this.purchasesHistory = {}
      let obj = {
        year: this.year,
        month: this.paymentHistoryMonth,
        per_page: this.per_page
      }
      Api.getPurchase(obj).then((res) => {
        this.purchasesHistory = res.data
        // console.log(res)
      }).catch((err) => {
        console.log(err)
      }).finally(() => {
        this.isPaymentHistoryProcessing = false
      })
    },
  },
  mounted() {

    this.getUserInfo()
    Api.getPlans().then((res) => {
      this.allPlans = res.data.plans
      this.postPlans = res.data.plans.filter((el) => el.plan_type == 'postpaid')
    })
    this.scrubs_amount = this.userInfo?.purchase_settings?.scrubs_amount ? this.userInfo?.purchase_settings?.scrubs_amount : 5
    this.is_scrub_enabled = this.userInfo?.purchase_settings?.is_scrub_enabled == 1 ? true : false
    this.getPurchase()
    if(this.$route?.query?.scrollTo == 'activate') {
      setTimeout(() => {
        document.getElementById('pauseAccountSection').scrollIntoView({ behavior: 'smooth' });
      }, 300);
    }
  }
};
</script>
<style lang="less" scoped>
@import '@/assets/css/accountInfo.less';
</style>

<style>
.bg-grayed {
  background: rgba(253, 253, 253, 0.7);
  z-index: 99
}
</style>