<template>
    <nav class="headerLogin navbar navbar-expand-lg px-lg-5 container-fluid shadow-none">
      <button class="navbar-toggler px-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        raia-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
        @click="isNavbarOpen = !isNavbarOpen">
        <img src="@/assets/img/hamburger-icon.svg" alt="">
      </button>
      <router-link to="/">
        <picture>
          <source class="logo ps-lg-2" media="(max-width:991px)" srcset="@/assets/img/mobile-logo.svg">
          <img class="logo" src="@/assets/img/logo.svg" alt="Landline Scrubber">
        </picture>
      </router-link>

      <div class="navbar-overlay" :class="isNavbarOpen ? 'd-block' : 'd-none'"></div>
      <div class="navbar-list navbar-collapse" :class="isNavbarOpen ? 'show' : ''" id="navbarSupportedContent">
        <div class="menu d-lg-none text-start">
          <div class="col-10 text-end menu-label">
            Menu
          </div>
          <div class="col-2 text-end">
            <img src="@/assets/img/close-nav.svg" class="cursor-pointer" alt="" @click="isNavbarOpen = !isNavbarOpen">
          </div>
        </div>
        <ul class="navbar-nav text-start ms-auto">
          <li class="nav-item pricing me-4">
            <a href="#pricing" @click="routeTo()" class="nav-link ">
              <div class="nav-link-border me-2"></div>
              <div class="nav-label">Pricing</div>
            </a>
          </li>
          <li class="nav-item how me-4">
            <a href="#how" @click="routeTo()" class="nav-link howLink ">
              <div class="nav-link-border me-2"></div>
              <div class="nav-label">How It Works</div>
            </a>
          </li>
          <li class="nav-item faq me-4">
            <router-link to="/faqs" class="nav-link faqLink ">
              <div class="nav-link-border me-2"></div>
              <div class="nav-label">FAQ</div>
            </router-link>
          </li>
          <template v-if="!user">
            <li class="nav-item login me-4 d-lg-block d-none">
              <router-link to="/login" class="nav-link loginLink ">Log In</router-link>
            </li>
            <li class="nav-item signUp me-4 pb-0 d-lg-flex align-items-center d-none">
              <router-link to="/signup" class="nav-link signUpLink ">Sign Up</router-link>
            </li>
            <li class="nav-item login me-4 d-lg-none d-block">
              <router-link to="/login" class="nav-link ">
                <div class="nav-link-border me-2"></div>
                <div class="nav-label">Log In</div>
              </router-link>
            </li>
            <li class="nav-item signUp me-4 pb-0 d-lg-none d-block">
              <router-link to="/signup" class="nav-link mt-2 ">
                <span class="nav-link-border me-2"></span>
                <div class="nav-label">Sign Up</div>
              </router-link>
            </li>
          </template>
        </ul>
      </div>

        <!-- <div class="dropdown">
          <a href="javascript:void(0)" class="dropdown-toggle" type="button" id="dropdownMenuButton1"
            data-bs-toggle="dropdown" aria-expanded="false">
            <img class="logo" src="@/assets/img/navbar-user.svg" alt="Landline Scrubber">
          </a>
          <ul class="dropdown-menu dropdown-menu-end mt-0" aria-labelledby="dropdownMenuButton1" style="
             margin-top: 1rem!important;">
           
            <li><router-link class="dropdown-item text-center p-2 text-uppercase" to="apidoc">Api</router-link></li>
            <li><router-link class="dropdown-item text-center p-2 text-uppercase" to="troubleshooting">Trouble Shooting</router-link></li>
            
          </ul>
        </div> -->
    </nav>
</template>

<script>
import Api from '@/Api';
import { auth } from '@/firebaseConfig';
export default {
  name: 'HeaderLogin',
  props: ['user'],
  data() {
    return {
      isNavbarOpen: false
    }
  },

  methods: {
    routeTo() {
      if (this.$route.name !== 'Welcome') {
        this.$router.push({ name: 'Welcome' })
      }
    },
    logout_user() {
      auth.signOut();
      Api.logout()
      this.$router.push({ name: 'Login' })
    }

  },
  mounted() {
    window.addEventListener("resize", () => {
      if (window.matchMedia("(min-width: 991px)").matches) {
        this.isNavbarOpen = false;
      } 
    });
  },
  watch: {
    
    $route() {
      this.isNavbarOpen = false;
    }
  }
}
</script>
<style>
.dropdown .dropdown-toggle:after {
  content: '' !important;
}
</style>
